// import { saveTodayBarDataHouseAndRoom, saveTodaySessions } from "../../actions/analyticsV2";
import {
  saveTodayBarDataHouseAndRoom,
  saveTodaySessions,
} from "../../redux/actions/analystic";
import store from "../../redux/source";
import { barDatas } from "./Room/action";
import axios from "axios";

export const durationAnalystic = [
  { value: "Daily" },
  { value: "Weekly" },
  { value: "Monthly" },
  { value: "Anually" },
];

const findTime = (time, id, room) => {
  let s = new Date(time.startTime).getHours();
  let e = new Date(time.endTime).getHours();

  if (s === e) {
    room[Math.floor(s / 3)].time += time.endTime - time.startTime;

    if (!room[Math.floor(s / 3)].devices.includes(id)) {
      room[Math.floor(s / 3)].devices.push(id);
    }
  } else {
    for (let i = s; i <= e; i++) {
      let slotNumber = Math.floor(i / 3);
      if (i !== e) {
        if (i === s) {
          let newStart = new Date(time.startTime);
          let endTime = new Date(time.startTime).setHours(i + 1, 0, 0, 0);
          room[slotNumber].time += endTime - newStart;

          if (!room[slotNumber].devices.includes(id)) {
            room[slotNumber].devices.push(id);
          }
        } else {
          let newStart = new Date(time.startTime).setHours(i, 0, 0, 0);
          let endTime = new Date(time.startTime).setHours(i + 1, 0, 0, 0);
          room[slotNumber].time += endTime - newStart;
          if (!room[slotNumber].devices.includes(id)) {
            room[slotNumber].devices.push(id);
          }
        }
      }
      if (i === e) {
        let newStart = new Date(time.startTime).setHours(e, 0, 0, 0);
        room[slotNumber].time += time.endTime - newStart;
        if (!room[slotNumber].devices.includes(id)) {
          room[slotNumber].devices.push(id);
        }
      }
    }
  }
};

const logs = async (data) => {
  return await axios
    .post(
      "https://analytics.alistetechnologies.com:443/analytic/v2/getDataForToday",
      {
        dSIDs: data,
        firstDate: new Date().setHours(0, 0, 0, 0),
      }
    )
    .then(function (res) {
      if (res.data.company) {
        const data = res.data.results.finalResult;

        return data;
      } else {
        return [];
      }

      // if (data.sessions !== undefined && data.sessions.length !== 0) {
      // resolve({ success: true, data: { ...data, deviceId: deviceId, switchId: swit }, roomName: roomName,switchName:switchName })
      // } else {
      // resolve({ success: false })
      // }
    })
    .catch(function (err) {
      return [];
      ////console.log(err, "error");
      // resolve({ success: false, data: {}, roomName: roomName })
    });
};

const snapShotlogs = async (data) => {
  let d = new Date();
  d.setDate(d.getDate() - 1);

  return await axios
    .post(
      "https://analytics.alistetechnologies.com:443/analytic/v2/getSnapShotData",
      {
        dSIDs: data,
        firstDate: d,
        lastDate: d,
      }
    )
    .then(function (res) {
      // console.log(res.data,"sessions")
      if (res.data.result) {
        const data = res.data.data;
        // console.log(data,"rrrr");
        const newData = [];
        for (let d of data) {
          newData.push(d.data[d.data.length - 1].snapshot);
        }
        return newData;
      } else {
        return [];
      }
    })
    .catch(function (err) {
      ////console.log(err, "error");
      return [];
    });
};
const colorfind = (i) => {
  let color = ["#177AD5", "#79D2DE", "#ED6665"];
  return color[i];
};

const pieData = (pieData, commandData) => {
  let i = 0;

  for (let c of Object.keys(commandData)) {
    if (c === "total") continue;
    if ((commandData[c] / commandData.total) * 100 >= 0.5) {
      i += 1;
      pieData.push({
        value: commandData[c],
        color: colorfind(i),
        text: `${((commandData[c] / commandData.total) * 100).toFixed(0)}`,
        commandName: c,
        focused: true,
      });
      //    pieData.push({value: commandData[c], color: colorfind(i), text: `${((commandData[c]/commandData.total)*100).toFixed(0)}% ${c}`})
    }
  }
};

export const fetchHouseDatav2 = async () => {
  let dSIDs = [];
  let deviceIdSwitchId = [];
  let pushData = {};
  let complete = {
    House: {
      0: {
        time: 0,
        devices: [],
        label: "12 AM",
      },
      1: {
        time: 0,
        devices: [],
        label: "3 AM",
      },
      2: {
        time: 0,
        devices: [],
        label: "6 AM",
      },
      3: {
        time: 0,
        devices: [],
        label: "9 AM",
      },
      4: {
        time: 0,
        devices: [],
        label: "12 PM",
      },
      5: {
        time: 0,
        devices: [],
        label: "3 PM",
      },
      6: {
        time: 0,
        devices: [],
        label: "6 PM",
      },
      7: {
        time: 0,
        devices: [],
        label: "9 PM",
      },
      // 8: {
      //     time: 0,
      //     devices: [],
      //     label:"12 AM"
      // },
    },
  };
  let totalDevices = {
    TotalDevicesHouse: 0,
  };
  let rooms = store.getState().rooms.list;

  //

  for (let room of rooms) {
    if (room.devices.length !== 0) {
      let devices = room.devices;
      for (let device of devices) {
        let switches = device.switches;
        for (let swit of switches) {
          if (swit.deviceType !== 7) {
            totalDevices.TotalDevicesHouse += 1;
            if (totalDevices[`TotalDevices${room.roomName}`] === undefined) {
              totalDevices[`TotalDevices${room.roomName}`] = 0;
            }
            totalDevices[`TotalDevices${room.roomName}`] += 1;
            if (
              totalDevices[
                `TotalDevices${device.deviceId}$${swit.switchId}`
              ] === undefined
            ) {
              totalDevices[
                `TotalDevices${device.deviceId}$${swit.switchId}`
              ] = 1;
            }
            deviceIdSwitchId.push({
              switchId: swit.switchId,
              deviceId: device.deviceId,
              roomName: room.roomName,
              switchName: swit.switchName,
              dSId: `${device.deviceId}$${swit.switchId}`,
            });
            pushData[`${device.deviceId}$${swit.switchId}`] = {
              switchId: swit.switchId,
              deviceId: device.deviceId,
              roomName: room.roomName,
              switchName: swit.switchName,
            };
            dSIDs.push(`${device.deviceId}$${swit.switchId}`);
          }
        }
      }
    }
  }

  let today = await logs(deviceIdSwitchId);
  let snapshot = await snapShotlogs(dSIDs);
  // console.log(JSON.stringify(snapshot,null,2),"snapshot");
  let newToday = [];

  let newPrevious = [];
  for (let dS of today) {
    let obj = { ...dS, ...pushData[dS.dSId] };
    newToday.push(obj);
  }
  for (let dS of snapshot) {
    let obj = { ...dS, ...pushData[dS.dSId] };
    newPrevious.push(obj);
  }
  let sess = [];
  let togglesCommand = [];
  let toggle = [];
  // today
  let Bill = {
    House: 0,
    TotalDevicesHouse: 0,
  };
  let totalonTime = {
    House: 0,
    TotalDevicesHouse: 0,
  };
  let wifiOnTime = {
    House: 0,
    TotalDevicesHouse: 0,
  };

  // yesterday
  let yesterdayWifiOnTime = {
    House: 0,
    TotalDevicesHouse: 0,
  };
  let yesterdayOnTime = {
    House: 0,
    TotalDevicesHouse: 0,
  };
  let yesterdayBill = {
    House: 0,
    TotalDevicesHouse: 0,
  };

  // difference
  let BillDifferenceYesterday = {
    House: 0,
    TotalDevicesHouse: 0,
  };
  let TimeDifferenceYesterday = {
    House: 0,
    TotalDevicesHouse: 0,
  };
  let WifiTimeDifferenceYesterday = {
    House: 0,
    TotalDevicesHouse: 0,
  };
  newToday.map((res) => {
    if (res.sessions !== undefined && res.sessions.length !== 0) {
      let s = res.sessions;
      for (let i of s) {
        sess.push({
          ...i,
          roomName: res.roomName,
          deviceId: res.deviceId,
          switchId: res.switchId,
        });
      }
    }
    if (res.commands !== undefined && res.commands.length !== 0) {
      for (let c of res.commands) {
        let obj = {
          ...c,
          roomName: res.roomName,
          deviceId: res.deviceId,
          switchId: res.switchId,
        };
        togglesCommand.push(obj);
      }
    }
    if (res.toggles !== undefined && Object.keys(res.toggles).length !== 0) {
      for (let c of Object.keys(res.toggles)) {
        if (c.split(" ")[0] === "app") {
          let obj = {
            [c]: res.toggles[c],
            roomName: res.roomName,
            deviceId: res.deviceId,
            switchId: res.switchId,
          };
          toggle.push(obj);
        }
      }
    }
    if (res.ontime !== undefined) {
      // console.log(res.bill,"bill");
      totalonTime.House += res.ontime;
      totalonTime.TotalDevicesHouse = 0;
      if (totalonTime[res.roomName] === undefined) {
        totalonTime[res.roomName] = 0;
      }
      if (totalonTime[`${res.deviceId}$${res.switchId}`] === undefined) {
        totalonTime[`${res.deviceId}$${res.switchId}`] = 0;
        totalonTime[`TotalDevices${res.roomName}`] = 0;
      }
      totalonTime[`${res.deviceId}$${res.switchId}`] += res.ontime;
      totalonTime[res.roomName] += res.ontime;
      // totalonTime[`TotalDevices${res.roomName}`] += 1
    }
    if (res.bill !== undefined) {
      // console.log(res.bill,"bill");
      Bill.House += res.bill;
      Bill.TotalDevicesHouse = 0;
      if (Bill[res.roomName] === undefined) {
        Bill[res.roomName] = 0;
      }
      if (Bill[`${res.deviceId}$${res.switchId}`] === undefined) {
        Bill[`${res.deviceId}$${res.switchId}`] = 0;
        Bill[`TotalDevices${res.roomName}`] = 0;
      }
      Bill[`${res.deviceId}$${res.switchId}`] += res.bill;
      Bill[res.roomName] += res.bill;
      // Bill[`TotalDevices${res.roomName}`] += 1
    }
    if (res.wifiOnTime !== undefined) {
      wifiOnTime.House += res.wifiOnTime;
      wifiOnTime.TotalDevicesHouse = 0;
      if (wifiOnTime[res.roomName] === undefined) {
        wifiOnTime[res.roomName] = 0;
        wifiOnTime[`TotalDevices${res.roomName}`] = 0;
      }
      if (wifiOnTime[`${res.deviceId}$${res.switchId}`] === undefined) {
        wifiOnTime[`${res.deviceId}$${res.switchId}`] = 0;
      }
      wifiOnTime[`${res.deviceId}$${res.switchId}`] += res.wifiOnTime;
      wifiOnTime[res.roomName] += res.wifiOnTime;
      wifiOnTime[`TotalDevices${res.roomName}`] = 0;
    }
    return undefined;
  });
  newPrevious.map((res) => {
    if (res.bill !== undefined) {
      yesterdayBill.House += res.bill;
      yesterdayBill.TotalDevicesHouse += 1;
      if (yesterdayBill[res.roomName] === undefined) {
        yesterdayBill[res.roomName] = 0;
      }
      if (yesterdayBill[`${res.deviceId}$${res.switchId}`] === undefined) {
        yesterdayBill[`${res.deviceId}$${res.switchId}`] = 0;
      }
      yesterdayBill[`${res.deviceId}$${res.switchId}`] += res.bill;
      yesterdayBill[res.roomName] += res.bill;
      yesterdayBill[`TotalDevices${res.roomName}`] += 1;
    }
    if (res.ontime !== undefined) {
      yesterdayOnTime.House += res.ontime;
      yesterdayOnTime.TotalDevicesHouse += 1;
      if (yesterdayOnTime[res.roomName] === undefined) {
        yesterdayOnTime[res.roomName] = 0;
        yesterdayOnTime[`TotalDevices${res.roomName}`] = 0;
      }
      if (yesterdayOnTime[`${res.deviceId}$${res.switchId}`] === undefined) {
        yesterdayOnTime[`${res.deviceId}$${res.switchId}`] = 0;
      }
      yesterdayOnTime[`${res.deviceId}$${res.switchId}`] += res.ontime;
      yesterdayOnTime[res.roomName] += res.ontime;
      yesterdayOnTime[`TotalDevices${res.roomName}`] += 1;
    }

    if (res.wifiOnTime !== undefined) {
      yesterdayWifiOnTime.House += res.wifiOnTime;
      yesterdayWifiOnTime.TotalDevicesHouse += 1;
      if (yesterdayWifiOnTime[res.roomName] === undefined) {
        yesterdayWifiOnTime[res.roomName] = 0;
        yesterdayWifiOnTime[`TotalDevices${res.roomName}`] = 0;
      }
      if (
        yesterdayWifiOnTime[`${res.deviceId}$${res.switchId}`] === undefined
      ) {
        yesterdayWifiOnTime[`${res.deviceId}$${res.switchId}`] = 0;
      }
      yesterdayWifiOnTime[`${res.deviceId}$${res.switchId}`] += res.wifiOnTime;
      yesterdayWifiOnTime[res.roomName] += res.wifiOnTime;
      yesterdayWifiOnTime[`TotalDevices${res.roomName}`] += 1;
    }
    return undefined;
  });

  store.dispatch(saveTodaySessions(sess));
  for (let d of Object.keys(Bill)) {
    BillDifferenceYesterday[d] = yesterdayBill[d] - Bill[d];
    TimeDifferenceYesterday[d] = yesterdayOnTime[d] - totalonTime[d];
    // console.log(yesterdayWifiOnTime[d]-wifiOnTime[d],yesterdayWifiOnTime[d],wifiOnTime[d]);
    WifiTimeDifferenceYesterday[d] = yesterdayWifiOnTime[d] - wifiOnTime[d];
  }
  // // console.log(JSON.stringify(BillDifferenceYesterday,null,2),JSON.stringify(TimeDifferenceYesterday,null,2),JSON.stringify(WifiTimeDifferenceYesterday,null,2),"fffff");
  for (let s of sess) {
    if (complete[s.roomName] === undefined) {
      complete = {
        ...complete,
        [s.roomName]: {
          0: {
            time: 0,
            devices: [],
            label: "12 AM",
          },
          1: {
            time: 0,
            devices: [],
            label: "3 AM",
          },
          2: {
            time: 0,
            devices: [],
            label: "6 AM",
          },
          3: {
            time: 0,
            devices: [],
            label: "9 AM",
          },
          4: {
            time: 0,
            devices: [],
            label: "12 PM",
          },
          5: {
            time: 0,
            devices: [],
            label: "3 PM",
          },
          6: {
            time: 0,
            devices: [],
            label: "6 PM",
          },
          7: {
            time: 0,
            devices: [],
            label: "9 PM",
          },
          // 8: {
          //     time: 0,
          //     devices: [],
          //     label:"12 AM"
          // },
        },
      };
    }

    findTime(s, `${s.deviceId}$${s.switchId}`, complete["House"]);
    findTime(s, `${s.deviceId}$${s.switchId}`, complete[s.roomName]);
  }
  // ////console.log(complete, "com");
  let barDataComplete = {};
  let totalTime = {
    House: 0,
  };
  for (let room of Object.keys(complete)) {
    if (barDataComplete[room] === undefined) {
      barDataComplete = {
        ...barDataComplete,
        [room]: [],
      };
      totalTime[room] = 0;
    }
    for (let i of Object.keys(complete[room])) {
      if (complete[room][i].time !== 0) {
        let timeh =
          complete[room][i].time / complete[room][i].devices.length / 3600000;
        barDataComplete[room].push({
          value: timeh,
          label: complete[room][i].label,
          frontColor: "#177AD5",
        });
        totalTime[room] += timeh;
      } else {
        barDataComplete[room].push({
          value: 0,
          label: complete[room][i].label,
        });
      }
    }
  }
  let mostUsed = { time: 0, roomName: "" };

  for (let room of Object.keys(totalTime)) {
    if (room !== "House") {
      if (totalTime[room] > mostUsed.time) {
        mostUsed.time = totalTime[room];
        mostUsed.roomName = room;
      }
    }
  }

  let commandObj = {
    total: 0,
    App: 0,
    Switch: 0,
    Voice: 0,
    Schedule: 0,
  };

  let includeCommande = [
    "app",
    "stack",
    "switch",
    "alexa",
    "google",
    "schedule",
  ];
  for (let e of togglesCommand) {
    // console.log(e.medium,"ggg");
    if (includeCommande.includes(e.medium.split(" ")[0])) {
      let val = e.medium.split(" ")[0];
      if (val === "app" || val === "stack") {
        commandObj.App += 1;
        commandObj.total += 1;
      } else if (val === "switch") {
        commandObj.Switch += 1;
        commandObj.total += 1;
      } else if (val === "alexa" || val === "google") {
        commandObj.Voice += 1;
        commandObj.total += 1;
      } else if (val === "schedule") {
        commandObj.Schedule += 1;
        commandObj.total += 1;
      }
    }
  }
  let pieDatas = [];
  pieData(pieDatas, commandObj);
  store.dispatch(
    saveTodayBarDataHouseAndRoom({
      HouseOnTime: totalTime["House"].toFixed(1),
      barData: barDataComplete,
      mostUsedRoom: mostUsed,
      totalRoomHouseTime: totalTime,
      pieDatas: pieDatas,
      command: togglesCommand,
      toggle: toggle,
      bill: Bill,
      BillDifferenceYesterday: BillDifferenceYesterday,
      TimeDifferenceYesterday: TimeDifferenceYesterday,
      wifiOnTime: wifiOnTime,
      WifiTimeDifferenceYesterday: WifiTimeDifferenceYesterday,
      totalonTime: totalonTime,
      totalDevices: totalDevices,
    })
  );
  return false;
  // return { time: totalTime["House"].toFixed(0), bar: barDataComplete, mostUsedRoom: mostUsed, totalRoomHouseTime: totalTime ,pieDatas:pieDatas}
};

const findNameOfSwitch = (id) => {
  let rooms = store.getState().rooms.list;

  for (let room of rooms) {
    if (room.devices.length !== 0) {
      let devices = room.devices;
      for (let device of devices) {
        let switches = device.switches;
        for (let swit of switches) {
          if (`${device.deviceId}$${swit.switchId}` === id) {
            ////console.log("come");
            return { ...swit, deviceId: device.deviceId };
          }
        }
      }
    }
  }
};

export const mostUsedAppliance = (session) => {
  let switchesTime = {};
  let slot = {
    0: {
      time: 0,
      devices: [],
      label: "12 AM",
    },
    1: {
      time: 0,
      devices: [],
      label: "3 AM",
    },
    2: {
      time: 0,
      devices: [],
      label: "6 AM",
    },
    3: {
      time: 0,
      devices: [],
      label: "9 AM",
    },
    4: {
      time: 0,
      devices: [],
      label: "12 PM",
    },
    5: {
      time: 0,
      devices: [],
      label: "3 PM",
    },
    6: {
      time: 0,
      devices: [],
      label: "6 PM",
    },
    7: {
      time: 0,
      devices: [],
      label: "9 PM",
    },
  };
  for (let s of session) {
    if (switchesTime[`${s.deviceId}$${s.switchId}`] === undefined) {
      switchesTime[`${s.deviceId}$${s.switchId}`] = 0;
    }
    switchesTime[`${s.deviceId}$${s.switchId}`] += s.endTime - s.startTime;
  }
  let max = 0;
  let id = "";
  let barData = [];
  for (let t of Object.keys(switchesTime)) {
    if (switchesTime[t] > max) {
      max = switchesTime[t];
      id = t;
    }
  }
  let sessionofMostUsedAppliance = [];
  for (let s of session) {
    if (`${s.deviceId}$${s.switchId}` === id) {
      sessionofMostUsedAppliance.push(s);
    }
  }
  let mostUsedApp = findNameOfSwitch(id);
  for (let s of sessionofMostUsedAppliance) {
    findTime(s, id, slot);
  }
  barDatas(barData, slot);
  ////console.log(JSON.stringify(mostUsedApp, null, 2),"ddddd");
  return { barData: barData, mostUsedApp: mostUsedApp };
};

export const makePieData = (command) => {
  let commandObj = {
    total: 0,
    App: 0,
    Switch: 0,
    Voice: 0,
    Schedule: 0,
  };

  let includeCommande = [
    "app",
    "stack",
    "switch",
    "alexa",
    "google",
    "schedule",
  ];
  for (let e of command) {
    if (includeCommande.includes(e.medium.split(" ")[0])) {
      let val = e.medium.split(" ")[0];
      if (val === "app" || val === "stack") {
        commandObj.App += 1;
        commandObj.total += 1;
      } else if (val === "switch") {
        commandObj.Switch += 1;
        commandObj.total += 1;
      } else if (val === "alexa" || val === "google") {
        commandObj.Voice += 1;
        commandObj.total += 1;
      } else if (val === "schedule") {
        commandObj.Schedule += 1;
        commandObj.total += 1;
      }
    }
  }
  let pieDatas = [];
  pieData(pieDatas, commandObj);
  return pieDatas;
};

export const timeFormate = (timeStamp) => {
  const milliseconds = Math.abs(timeStamp);
  const minutes = (milliseconds / 1000 / 60) % 60;
  const hours = (milliseconds / 1000 / 60 / 60) % 24;
  if (hours < 1) {
    return `${minutes.toFixed(0)}m`;
  } else {
    let v = hours.toFixed(1).split(".");
    if (v[1] === "0") {
      // console.log(hours.toFixed(1).split('.')[1],"split0")
      return `${hours.toFixed(0)}h`;
    } else {
      return `${hours.toFixed(1)}h`;
    }
  }
};

export const capitalize = (word) =>
  word.charAt(0).toUpperCase() + word.slice(1, word.length);
export const append0 = (number, length = 2) => {
  let numLength = String(number).length;
  return `${"0".repeat(Math.max(length - numLength, 0))}${number}`;
};
export const processedTime = (schedule_time) => {
  var time = new Date(schedule_time);
  var hours = time.getHours();
  var minutes = time.getMinutes();
  var suffix = hours >= 12 ? "PM" : "AM";
  hours = hours > 12 ? hours - 12 : hours;
  hours = hours === 0 ? 12 : hours;
  hours = append0(hours);
  minutes = append0(minutes);
  return `${hours}:${minutes} ${suffix}`;
};
