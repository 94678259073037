/* eslint-disable */

import React from "react";
import { connect } from "react-redux";
import logo from "../assets/ziggta/ziggta.jpeg";
import { loginZiggtaUser } from "../redux/actions/user";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=63f0bcdd700f686a5128b2f2"
    ).then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;
class ZiggtaLogins extends React.Component {
  static get Controller() {
    if (Controller) return Controller;
    Controller = ZiggtaLogins;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const txtName = document.getElementById("txtName");
    const txtPhone = document.getElementById("txtPhone");
    const txtDate = new Date();
    let allgood = true;
    const name = txtName.value;
    if (name.length < 3) {
      txtName.classList.add("af-class-text_error");
      allgood = false;
    } else {
      txtName.classList.remove("af-class-text_error");
    }
    const phone = txtPhone.value;
    if (isNaN(phone) || String(Number(phone)).length < 10) {
      txtPhone.classList.add("af-class-text_error");
      allgood = false;
    } else {
      txtPhone.classList.remove("af-class-text_error");
    }
    // const date = txtDate.value;
    // if (new Date(date) < new Date().setHours(0, 0, 0, 0)) {
    //   txtDate.classList.add("af-class-text_error");
    //   allgood = false;
    // } else {
    //   txtDate.classList.remove("af-class-text_error");
    // }
    if (allgood) {
      const response = await this.props.loginZiggtaUser(
        name,
        phone,
        new Date()
      );
    }
  };

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "63f0bcdd700f6815f428b2f3";
    htmlEl.dataset["wfSite"] = "63f0bcdd700f686a5128b2f2";
    this.props.loginZiggtaUser("Dummy", "+91123456789", new Date());
    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/konarks-fresh-site.webflow.css);
        `,
          }}
        />
        <span className="af-view">
          <div className="af-class-body">
            <div
              id="navbar"
              data-animation="default"
              data-collapse="medium"
              data-duration={400}
              data-easing="ease"
              data-easing2="ease"
              role="banner"
              className="af-class-navbar w-nav"
            >
              <div className="af-class-container-navigation-8">
                <div className="af-class-navigation-menu">
                  <a href="#" className="af-class-brand-4 w-nav-brand">
                    {/* <img
                      src="images/Asset-1.png"
                      sizes="(max-width: 479px) 100vw, 60px"
                      srcSet="images/Asset-1.png 500w, images/Asset-1.png 800w, images/Asset-1.png 1080w, images/Asset-1.png 1600w, images/Asset-1.png 1722w"
                      alt="Aliste Logo"
                      className="af-class-brand-logo"
                    /> */}
                  </a>
                  <nav
                    role="navigation"
                    className="af-class-nav-menu w-nav-menu"
                  >
                    <a href="#" className="af-class-nav-link-6 w-nav-link">
                      Home
                    </a>
                    <a href="#" className="af-class-nav-link-6 w-nav-link">
                      Our App
                    </a>
                    <a href="#" className="af-class-nav-link-6 w-nav-link">
                      FAQ
                    </a>
                    <a href="#" className="af-class-nav-link-6 w-nav-link">
                      Contact Us
                    </a>
                  </nav>
                </div>
                <img
                  src={logo}
                  loading="lazy"
                  alt
                  className="af-class-image-33"
                />
              </div>
            </div>
            <div
              id="Contact-form"
              className="af-class-section-39 af-class-wf-section"
            >
              <div className="af-class-container-10-copy-copy">
                <div className="w-layout-grid af-class-grid-copy">
                  <div
                    style={{
                      WebkitTransform:
                        "translate3d(-30px, 0px, 0px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(-30px, 0px, 0px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(-30px, 0px, 0px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(-30px, 0px, 0px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transformStyle: "preserve-3d",
                      opacity: 1,
                    }}
                    className="af-class-div-block-heading"
                  >
                    <h1 className="af-class-hero-heading-5-copy">
                      Make your home
                      <span className="af-class-underline-span">
                        <br />
                        <strong>smart</strong>
                      </span>
                      <strong> with Ziggta</strong>
                    </h1>
                    <p
                      data-w-id="4f49ffe1-fca3-d5d4-3769-829540d407f4"
                      className="af-class-header-paragraph-4-copy"
                    >
                      Automate your existing home in a matter <br />
                      of minutes at unbelievable prices!
                    </p>
                    <img
                      className="af-class-image-23"
                      src="images/Artboard-10_1Artboard-10.webp"
                      width={800}
                      alt
                      style={{
                        WebkitTransform:
                          "translate3d(30px, 0px, 0px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        MozTransform:
                          "translate3d(30px, 0px, 0px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        msTransform:
                          "translate3d(30px, 0px, 0px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        transform:
                          "translate3d(30px, 0px, 0px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        transformStyle: "preserve-3d",
                        opacity: 1,
                      }}
                      sizes="(max-width: 479px) 0px, (max-width: 991px) 100vw, 800px"
                      data-w-id="992016c1-7447-ecc1-aaeb-ec3ea78868d2"
                      loading="lazy"
                      srcSet="images/Artboard-10_1-p-500.png 500w, images/Artboard-10_1-p-800.png 800w, images/Artboard-10_1-p-1080.png 1080w, images/Artboard-10_1-p-1600.png 1600w, images/Artboard-10_1-p-2000.png 2000w, images/Artboard-10_1-p-2600.png 2600w, images/Artboard-10_1Artboard-10.webp 2911w"
                    />
                  </div>
                  <img
                    src="images/Asset-2345.png"
                    loading="lazy"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 89vw, (max-width: 991px) 92vw, (max-width: 1279px) 89vw, 1092px"
                    srcSet="images/Asset-2345.png 500w, images/Asset-2345.png 800w, images/Asset-2345.png 1080w, images/Asset-2345.png 1972w"
                    alt
                    className="af-class-image-34"
                  />
                  <div
                    id="w-node-fef770f2-f1c4-dba7-2cc5-58f087b55ce9-f428b2f3"
                    className="af-class-div-block-75"
                  >
                    <div className="af-class-form-block w-form">
                      <form>
                        <div className="w-layout-grid af-class-grid-form-moon">
                          <div>
                            <label
                              htmlFor="name"
                              className="af-class-field-label-moon"
                            >
                              Welcome to
                              <strong className="af-class-bold-text-23">
                                {" "}
                                Ziggta
                              </strong>
                            </label>
                            <div className="af-class-field-wrap-2">
                              <label
                                htmlFor="name"
                                className="af-class-field-label-moon-copy af-class-field-label"
                              >
                                The Smart Hotel of your dreams is just a few
                                clicks away
                              </label>
                              <div className="af-class-input-icon-wrapper">
                                <input
                                  id={"txtName"}
                                  type="text"
                                  className="af-class-text w-input"
                                  maxLength={256}
                                  name="name"
                                  data-name="name"
                                  placeholder="Name"
                                  required
                                  autoComplete="name"
                                />
                                <img
                                  alt="Name"
                                  loading="lazy"
                                  src="https://uploads-ssl.webflow.com/5f420aab94eec62a38c6940b/5f42fa3efc77437fcf35e199_Icon%20Name.svg"
                                  className="af-class-input-icon"
                                />
                              </div>
                            </div>
                          </div>
                          <div id="w-node-_4f49ffe1-fca3-d5d4-3769-829540d4080e-f428b2f3">
                            <div className="af-class-field-wrap-2">
                              <div className="af-class-input-icon-wrapper">
                                <input
                                  id={"txtPhone"}
                                  type="tel"
                                  className="af-class-text af-class-text-field w-input"
                                  maxLength={256}
                                  name="phone"
                                  data-name="phone"
                                  placeholder="Phone Number"
                                  required
                                  autoComplete="current-phone"
                                />
                                <img
                                  alt="Phone Number"
                                  loading="lazy"
                                  src="images/PhoneIcon.webp"
                                  className="af-class-input-icon"
                                />
                              </div>
                            </div>
                            {/* <div className="af-class-field-wrap-2">
                              <div className="af-class-input-icon-wrapper">
                                <div className="w-embed">
                                  <input
                                    id={"txtDate"}
                                    name="date"
                                    type="date"
                                    className="af-class-text"
                                    autoComplete="current-date"
                                    style={{ paddingRight: "10px" }}
                                  />
                                </div>
                                <img
                                  alt="Email"
                                  loading="lazy"
                                  src="https://uploads-ssl.webflow.com/5f420aab94eec62a38c6940b/5f42fa3e21f4e3f05b480852_Icon%20Mail.svg"
                                  className="af-class-input-icon"
                                />
                              </div>
                            </div> */}
                          </div>
                        </div>
                        <div className="af-class-button-wrap-centre">
                          <input
                            type="button"
                            onClick={this.handleSubmit}
                            defaultValue="Enter Now"
                            className="af-class-submit-button-moon"
                          />
                        </div>
                      </form>
                      {/* <div id="msgSuccess" className="af-class-success-message w-form-done">
                    <div className="af-class-div-block-74">
                    <img src="images/Asset-73_1Asset-73.webp" loading="lazy" alt="Done" className="af-class-image-30" />
                    <div id="txtSuccess" className="af-class-text-block-283">Thank you! We have received your request, someone will get in touch with you shortly</div>
                    </div>
                    </div>
                    <div  className="af-class-error_messege w-form-fail">
                    <div className="af-class-div-block-74-copy">
                    <div className="af-class-text-block-283-copy">Oops! Something went wrong while submitting the form.</div>
                    </div>
                    </div>  */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-div-block-13">
              <div className="af-class-footer-title-light-copy">
                Copyright © Ziggta since 2018. All rights reserved.
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default connect(() => ({}), { loginZiggtaUser })(ZiggtaLogins);

/* eslint-enable */
