import { LoginTypes } from "../../config/globals";
import { ThemeActions, UserActions } from "../actionTypes";

const initialState = {
	gradient: ['#677BF3', '#2C54DF'],
  primaryColor: '#5276f5',
}

const theme = (state = initialState, action) => {
	const {type, payload} = action;
	switch (type) {
		case UserActions.LOAD:
			if (payload.loginType === LoginTypes.hostler) {
				return {
					...state,
					primaryColor: '#FFE700',
					gradient: ['#FFE700', '#FFE700']
				}
			} else {
				return initialState;
			}
		case ThemeActions.LOAD:
			return {
				...state, 
				primaryColor: payload.primaryColor,
				gradient: payload.gradient,
			}
		default:
			return state;
	}
}

export default theme;
