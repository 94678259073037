import { DeviceTypeMap, DeviceTypes, StackTypes } from "../../config/typeMaps";
import { RoomActions, SocketUpdateActions, UserActions } from "../actionTypes";

const deriveIteratedAndNameFromList = (list) => {
  var rooms_dict = {};
  var name_dict = {};
  let novaTypeSwitchRemotes = [];
  for (let room of list) {
    let roomId = room._id;
    let roomName = room.roomName;
    rooms_dict[roomId] = [];
    name_dict[roomId] = roomName;

    if (room.stacks) {
      for (let stack of room.stacks) {
        stack.dimmables = [];
        let stackTypeIndex = Object.values(StackTypes).findIndex(
          (st) => st.id === stack.type
        );
        let state = { power: 0 };
        if (stackTypeIndex !== -1) {
          state = { ...Object.values(StackTypes)[stackTypeIndex].defaultState };
        }
        if (stack.type === StackTypes.sync.id) {
          for (const device of room.devices) {
            for (const appliance of device.switches) {
              if (
                stack.devices.some(
                  (d) =>
                    d.deviceId === device.deviceId &&
                    d.switchId === appliance.switchId
                )
              ) {
                if (Number(appliance.switchState) !== 0) {
                  state.power = 100;
                  state.intensity = 100;
                }
                if (appliance.dimmable === true) {
                  if (stack.dimmables === undefined) {
                    stack.dimmables = [];
                  }
                  stack.dimmables = stack.dimmables.concat({
                    deviceId: device.deviceId,
                    switchId: appliance.switchId,
                  });
                  stack.dimmable = true;
                }
                if (appliance.deviceType === DeviceTypeMap.FAN_REGULATOR) {
                  stack.isFanRegulator = true;
                  if (Number(appliance.switchState) !== 0) {
                    state.intensity = Number(appliance.switchState);
                  }
                }
              }
            }
          }
        }
        rooms_dict[roomId].push({
          ...stack,
          roomId,
          stackType: stack.type,
          type: DeviceTypes.STACKS,
          state,
        });
      }
    }

    for (let device of room.devices) {
      if (device) {
        let deviceId = device.deviceId;
        for (let item of device.switches) {
          if (
            item.deviceType === DeviceTypeMap.NA ||
            item.deviceType === DeviceTypeMap.SCENE ||
            item.deviceType === DeviceTypeMap.TWO_WAY
          ) {
            continue;
          }
          let switchState = Number(item.switchState);
          if (switchState > 100) {
            switchState = 100;
          }
          if (switchState < 1) {
            switchState = parseInt(switchState * 100, 10);
          }
          if (
            item.deviceType === DeviceTypeMap.NOVA &&
            item.typeMetaData &&
            item.typeMetaData.remoteId
          ) {
            novaTypeSwitchRemotes.push(item.typeMetaData.remoteId);
          }
          rooms_dict[roomId].push({
            ...item,
            roomId,
            deviceId,
            switchState,
            state: switchState,
            roomName,
            type: DeviceTypes.SYNC,
            name: item.switchName,
          });
        }
      }
    }

    if (room.novas) {
      for (let nova of room.novas) {
        if (nova) {
          for (let remote of nova.custom_remotes) {
            if (!novaTypeSwitchRemotes.includes(remote._id)) {
              rooms_dict[roomId].push({
                ...remote,
                roomId,
                deviceId: nova.deviceId,
                roomName,
                remoteType: remote.type,
                type: DeviceTypes.NOVA,
              });
            }
          }
        }
      }
    }
    if (rooms_dict[roomId].length === 1) {
      rooms_dict[roomId].push({ type: DeviceTypes.EMPTY_BUTTON });
    }
  }
  return { iterated: rooms_dict, names: name_dict };
};

const initialState = {
  list: [],
  iterated: {},
  names: {},
  loading: false,
};

const rooms = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RoomActions.LOAD:
      return {
        ...state,
        list: payload.rooms,
        ...deriveIteratedAndNameFromList(payload.rooms),
        loading: false,
      };

    case SocketUpdateActions.SYNC_STATES_SYNC:
      var dslist = Array.from(state.list);
      let [deviceId, states] = payload.split("-");
      let switchStates = {};
      states = states.slice(0, states.length - 1);
      states = states.split(":");
      for (let s of states) {
        let [switchId, switchState] = s.split(",");
        switchStates[switchId] = Number(switchState);
      }
      for (let room of dslist) {
        for (let device of room.devices) {
          if (device.deviceId === deviceId) {
            for (let app of device.switches) {
              app.previousState =
                Number(app.switchState) === 0
                  ? app.previousState
                  : Number(app.switchState);
              app.switchState = switchStates[app.switchId];
            }
            break;
          }
        }
      }

      return {
        ...state,
        list: dslist,
        ...deriveIteratedAndNameFromList(dslist),
      };

    case SocketUpdateActions.SYNC_STATE_UPDATE:
      var newState = Number(payload.state);
      let newList = Array.from(state.list);
      for (let room of newList) {
        for (let device of room.devices) {
          if (device.deviceId === payload.deviceId) {
            for (let item of device.switches) {
              if (Number(item.switchId) === Number(payload.switchId)) {
                item.previousState =
                  Number(item.switchState) === 0
                    ? item.previousState
                    : Number(item.switchState);
                item.switchState = isNaN(newState)
                  ? item.switchState
                  : Number(newState) * (Number(newState) > 1 ? 1 : 100);
              }
            }
          }
        }
      }
      return {
        ...state,
        list: newList,
        ...deriveIteratedAndNameFromList(newList),
      };

    case SocketUpdateActions.NOVA_POWER_UPDATE:
      console.log("updateing nova power", payload);
      var updateNovaPowerList = Array.from(state.list);
      for (let room of updateNovaPowerList) {
        room.novas = room.novas.map((n) => {
          return n.deviceId === payload.deviceId
            ? {
                ...n,
                custom_remotes: n.custom_remotes.map((remote) =>
                  remote._id === payload.remote_id
                    ? { ...remote, power_state: payload.power_state }
                    : remote
                ),
              }
            : n;
        });
      }
      return {
        ...state,
        list: updateNovaPowerList,
        ...deriveIteratedAndNameFromList(updateNovaPowerList),
      };

    case UserActions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default rooms;
