import React, { useEffect, useState } from 'react'
import ReactApexChart from "react-apexcharts";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { timeFormate } from './function';

function BarChart({ barData, HouseOnTime, TimeDifferenceYesterday }) {
  const [series, setSeries] = useState([])
  const [options, setOptions] = useState({})
  useEffect(() => {
    if (barData === undefined || barData.length === 0) return

    let seriesData = []

    let categories = []
    for (let i of barData) {
      seriesData.push(i.value)
      categories.push(i.label)
    }

    setSeries([{ name: 'Time', data: seriesData }])
    setOptions({
      colors: ['#357CEB'],
      chart: {
        stacked: true
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',

          borderRadius: 5.556,
          borderRadiusApplication: 'end',
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        opposite: true,
        stepSize: 1,
        title: {
          text: 'hour'
        },
        labels: {
          formatter: function (val) {
            return `${(val).toFixed(0)} `
          },
        },
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return `${(val).toFixed(2)} h`
          }
        }
      }

    })
  }, [barData])
  return (
    <div>
      <div
        className='hourHeaderContainer'
      // {opacity: opacitys ? 0 : 1}
      >
        <div className='hourNumber'>{HouseOnTime}</div>
        <div> </div>
        <div className='hourText'>hours</div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          // opacity: opacitys ? 0 : 1,
          alignItems: 'center',
        }}>
        <div
          className='hourHeaderSubText'
          style={{ marginRight: "7px" }}>
          Appliance ON time
        </div>
    
        {
          TimeDifferenceYesterday < 0 ? <ArrowDropUpIcon className=' upDownIcon upDownIconMargin' /> : <ArrowDropDownIcon className='upIcon upDownIconMargin' />
        }
        <div
          className={TimeDifferenceYesterday < 0 ? "upDownIcon" : "upIcon"}>
          {TimeDifferenceYesterday !== -Infinity &&
            !isNaN(TimeDifferenceYesterday) &&
            TimeDifferenceYesterday !== null
            ? timeFormate(TimeDifferenceYesterday)
            : 0}
        </div>
        {/* </div> */}
      </div>
      <div style={{ height: 'max-content' }}>
        <ReactApexChart
          series={series}
          options={options}
          type={'bar'}
          height={205}
        />
      </div>
    </div>
  )
}

export default BarChart