import axios from "axios";
import {
  LoginTypes,
  cloudUrl,
} from "../../config/globals";
import { TimActions } from "../actionTypes";
import store from "../source";
import { loadUser } from "./user";
import { notifyFailed, notifySuccess } from "../../config/notification";

export const timLoad = (data) => {
  return {
    type: TimActions.LOAD,
    payload: data,
  };
};
const isValidNumber = (number) => {
  let num = number;
  if (String(number).startsWith("+")) {
    // if the number starts with a plus accept it as is
    if (!String(number).startsWith("+91")) return false;
    num = String(number).replace("+91", "");
  }
  // the number does not start with + assume its an indian number and process accordingly
  num = String(num).replace(/\D/g, "");
  while (num.startsWith("0")) {
    num = num.slice(1, num.length);
  }
  return num.length === 10;
};
export const loginUserPhone = async (email, password) => {
  
  const num = isValidNumber(email);
  if (!num) {
    notifyFailed("Phone Number is not valid");
    return;
  }

  const cleanNumber = (num = "") => {
    try {
      let cn = String(num).replace(/\D/g, ""); // replace non digits
      if (cn.startsWith("0")) {
        // the number is starting with 0, remove it
        cn = cn.slice(1, cn.length);
      }
      if (cn.length > 10) {
        cn = cn.slice(cn.length - 10, cn.length);
      }
      return `+91${cn}`;
    } catch (_) {
      return num;
    }
  };
  const number = cleanNumber(email);
  const response = await axios
    .post(`${cloudUrl}/v2/auth/login`, {
      mobile: number,
      password,
      deviceToken: "",
    })
    .then(
      (result) => {
      
        if (result.status === 200) {
          const { data } = result.data;

          store.dispatch(timLoad(data));
          store.dispatch(
            loadUser({
              email: number,
              profile: data.profile,
              loginType: LoginTypes.Tim,
            })
          );
          return { success: true, message: "Sign in successful" };
        } else {
          return { success: false, message: "Authentication Error" };
        }
      },
      (error) => {
        console.log(error, "error");
        let message = error.message;
        if (error.response && error.response.data) {
          message = error.response.data.message;
        }
        return { success: false, message: message };
      }
    )
    .catch(() => {
      return { success: false, message: "Authentication Error" };
    });
  return response;
};

export const updateSelectedHouse = async (
  houseID,
  email,
  name,
  ownerNumber,
  loginType
) => {
  await axios
    .post(`${cloudUrl}/api/update/selectedHouse?user=${ownerNumber}`, {
      email: email,
      houseAccessCode: houseID,
    })
    .then((res) => {
      const payload = {
        email: email,
        profile: {
          email: email,
          name: name,
          first_name: name,
          last_name: "",
          selectedHouse: houseID,
        },
        loggedIn: true,
        loginType: loginType,
      };
      store.dispatch(loadUser(payload));
    })
    .catch((error) => {
      console.log(error);
      notifyFailed(error.response.data.message);
    });
};

export const shareHouse = async (details) => {
  return await axios
    .post(
      `${cloudUrl}/v2/house/add/userWithAccess?user=${
        details.ownerNumber
      }&time=${new Date().getTime()}`,
      details
    )
    .then(async (result) => {
      const { success, message } = result.data;
      if (success) {
        notifySuccess(message);
        await updateSelectedHouse(
          details.houseId,
          details.email,
          details.name,
          details.ownerNumber,
          details.loginType
        );
        return true;
      } else {
        notifyFailed(message);
        return false;
      }
    })
    .catch((error) => {
      notifyFailed(error.response.data.message);
      return false;
    });
};
export const signUpUser = async (detail, setloading) => {
  axios
    .post(`${cloudUrl}/v2/auth/directSignup`, {
      phone: detail.email,
      name: detail.name,
    })
    .then(async function (res) {
      if (res.data.success) {
        await shareHouse(detail);
      } else {
        if (res.data.message === "Number already registered") {
          await shareHouse(detail);
        } else {
          notifyFailed(res.data.message);
        }
      }
      setloading(false);
    })
    .catch(function (err) {
      setloading(false);
    });
};
