import React, { useEffect, useState } from "react";
import "../Analystic.css";
import moment from "moment";
// import Icon from 'react-native-vector-icons/AntDesign'
import { useSelector } from "react-redux";
import { appliancesTime } from "./action";
import { useNavigate } from "react-router-dom";
import { notifyFailed } from "../../../config/notification";
import { Toaster } from "react-hot-toast";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const style = {
  headerdivContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "10px",
    marginTop: "4px",
    alignItems: "baseline",
  },
  Bolddiv: {
    color: "#000",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "18px",
  },
  Lightdiv: {
    color: "#000",
    fontize: "11px",
    fontWeight: "400",
    lineHeight: "18px",
    marginLeft: "5px",
  },
  roomContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "10px 0",
  },
  hourIconContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  iconContainer: {
    justifyContent: "center",
    marginLeft: "10px",
  },
};
const Appliances = ({ applinaceName, id, switchId, deviceId }) => {
  const navigation = useNavigate();
  let sessions = useSelector((state) => state.todaySessions);
  const [data, setData] = useState({ onTime: 0, barData: [] });

  useEffect(() => {
    let ownSessions = [];
    ownSessions = sessions.filter(
      (s) => s.deviceId === deviceId && s.switchId === switchId
    );
    // console.log(JSON.stringify(ownSessions,null,2));
    if (ownSessions.length > 0) {
      let bar = appliancesTime(ownSessions);
      let applianceTotalOnTime = 0;
      for (let v of bar) {
        applianceTotalOnTime += v.value;
      }

      // console.log(bar,applianceTotalOnTime,"varrrrrrrr");
      setData({ onTime: applianceTotalOnTime, barData: bar });
    }
  }, [deviceId, sessions, switchId]);

  return (
    <div
      onClick={() => {
        if (data.onTime !== 0) {
          navigation("/analystic/room/appliance", {
            state: {
              barData: data.barData,
              applinceOnTime: data.onTime,
              switchId: switchId,
              deviceId: deviceId,
              applinaceName: applinaceName,
            },
          });
        } else {
          notifyFailed("There is no On-Time");
        }
      }}
    >
      <div style={style.roomContainer} key={id}>
        <div>
          <div style={style.Bolddiv}>{applinaceName}</div>
          {/* <div style={[style.Lightdiv,{color:"#898989"}]}>{appliances} appliances</div> */}
        </div>
        <div style={style.hourIconContainer}>
          <div>
            <div style={style.Bolddiv}>{data.onTime.toFixed(1)}h</div>
            <div style={{ ...style.Lightdiv, color: "#898989" }}>
              {Math.round((data.onTime / 24) * 100)}%
            </div>
          </div>
          <div style={style.iconContainer}>
            <ChevronRightIcon className="arrowIcon" />
          </div>
        </div>
      </div>
    </div>
  );
};
function AppliancesWiseBreakDown({ roomName }) {
  let rooms = useSelector((state) => state.rooms.list);

  return (
    <div className="boxContainer boxStyleAndroid activeTab">
      <Toaster />
      <div>
        <div className="date">{moment().format("MMM Do")}</div>
      </div>
      <div style={style.headerdivContainer}>
        <div style={style.Bolddiv}>Appliances wise</div>
        <div style={style.Lightdiv}> breakdown</div>
      </div>
      {rooms
        .filter((r) => {
          if (r.roomName === roomName) {
            return r.devices;
          }
          return false;
        })
        .map((r) => {
          return r.devices.map((d) => {
            return d.switches
              .filter((s) => s.deviceType !== 7)
              .map((s) => {
                return (
                  <Appliances
                    applinaceName={s.switchName}
                    id={s._id}
                    switchId={s.switchId}
                    deviceId={d.deviceId}
                  />
                );
              });
          });
        })}
    </div>
  );
}

export default AppliancesWiseBreakDown;
