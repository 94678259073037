import React, { useEffect, useState } from "react";
import "./Analystic.css";
import DemoGraph from "./DemoGraph";
import { useSelector } from "react-redux";
import { timeFormate } from "./function";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";
import { notifyFailed } from "../../config/notification";

function MostUsedRoom({ mostUsedRoom, barData, totalRoomHouseTime }) {
  let [data, setData] = useState([]);
  const [time, setTime] = useState(0);
  const navigation = useNavigate();
  let analyticData = useSelector((state) => state.barData);

  useEffect(() => {
    if (barData === undefined && Object.keys(barData).length !== 0) return;

    setData(barData[mostUsedRoom?.roomName]);
    if (
      mostUsedRoom.roomName !== undefined &&
      analyticData.TimeDifferenceYesterday[mostUsedRoom?.roomName] !== undefined
    ) {
      setTime(
        analyticData.TimeDifferenceYesterday[mostUsedRoom?.roomName] /
          analyticData.TimeDifferenceYesterday[
            `TotalDevices${mostUsedRoom?.roomName}`
          ]
      );
    }
  }, [analyticData.TimeDifferenceYesterday, barData, mostUsedRoom.roomName]);
  return (
    <div
      className="boxContainer activeTab boxStyleAndroid smallBox"
      onClick={() => {
        if (
          totalRoomHouseTime[mostUsedRoom?.roomName] !== undefined &&
          totalRoomHouseTime[mostUsedRoom?.roomName] > 0
        ) {
          navigation("/analystic/room", {
            state: {
              barData: data,
              roomOnTime: totalRoomHouseTime[mostUsedRoom?.roomName],
              roomName: mostUsedRoom?.roomName,
            },
          });
        } else {
          notifyFailed("There is no On-Time");
        }
      }}
    >
      <div className="boxContainerTitle">Most used room</div>
      <div className="boxContainerSubTitle">{mostUsedRoom?.roomName}</div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: 20,
          marginTop: 3,
        }}
      >
        {time < 0 ? (
          <ArrowDropUpIcon className="upDownIcon upDownIconMargin" />
        ) : (
          <ArrowDropDownIcon className="upIcon upDownIconMargin" />
        )}
        <div className={`${time < 0 ? "upDownIcon" : "upIcon"}`}>
          {time !== -Infinity && !isNaN(time) && time !== null
            ? timeFormate(time)
            : 0}
        </div>
      </div>
      <DemoGraph barData={data} />
    </div>
  );
}

export default MostUsedRoom;
