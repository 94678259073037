import React, { useEffect, useState } from "react";

import "./Analystic.css";
import moment from "moment";
import { useSelector } from "react-redux";
import { makePieData } from "./function";
import PieChart from "./PieChart";

const style = {
  headerdivContainer: {
    flexDirection: "row",
    marginBottom: "10px",
    marginTop: "4px",
  },
  Bolddiv: {
    color: "#000",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "18px",
  },
  Lightdiv: {
    color: "#000",
    fontize: "11px",
    fontWeight: "400",
    lineHeight: "18px",
  },
  roomContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "10px 0px",
  },
  hourIconContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  iconContainer: {
    justifyContent: "center",
    marginLeft: "10px",
  },
};
export default function CustomDonut({ name, switchId, deviceId }) {
  let analyticData = useSelector((state) => state.barData);
  let [pieData, setPieData] = useState([]);

  useEffect(() => {
    if (
      Object.keys(analyticData).length === 0 ||
      analyticData.command.length === 0
    )
      return;
    let res = [];
    if (name === "House") {
      res = makePieData(analyticData.command);
    } else if (name === "appliance") {
      let ownCommand = analyticData.command.filter(
        (c) => c.deviceId === deviceId && c.switchId === switchId
      );
      res = makePieData(ownCommand);
    } else {
      let ownCommand = analyticData.command.filter((c) => c.roomName === name);
      res = makePieData(ownCommand);
    }
    setPieData(res);
  }, [analyticData, deviceId, name, switchId]);

  return (
    <>
      {pieData.length !== 0 && (
        <div className="boxContainer activeTab boxStyleAndroid">
          <div>
            <div className="date">{moment().format("MMM Do")}</div>
          </div>
          <div style={style.headerdivContainer}>
            <div style={style.Bolddiv}>Smart home</div>
            <div style={style.Lightdiv}> usage split</div>
          </div>

          <div style={{ padding: 12, alignItems: "center" }}>
            <PieChart data={pieData} size="220px" />
          </div>
        </div>
      )}
    </>
  );
}
