import React, { useEffect ,useState} from 'react'
import './Analystic.css'
import moment from 'moment'
import { useSelector } from 'react-redux'

import { notifyFailed } from '../../config/notification'
import { useNavigate } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


const style = {
    headerdivContainer: {
        display:'flex',
        flexDirection: "row",
        marginBottom:"10px",
        marginTop:"4px",
        alignItems: "baseline",
    },
    Bolddiv: {
        color: "#000",
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "18px",
    },
    Lightdiv: {
        color: "#000",
        fontSize: "11px",
        fontWeight: "400",
        lineHeight: "18px",
        marginLeft:'5px'
    },
    roomContainer: {
        display:'flex',
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop:"20px",
    },
    hourIconContainer:{
        display:'flex',
        alignItems:'center',
        flexDirection:"row",
        justifyContent:"space-between"
    },
    iconContainer:{
        justifyContent:"center",
        marginLeft:"10px",
    }
}
const Room = ({roomName,id,devices,totalRoomHouseTime,barData,index}) => {
    const [appliances,setAppliances] =useState(0)
     const navigation = useNavigate();
    
    useEffect(()=>{
        let app=0
        if(devices.length===0){
            app=0
        }else{
            for(let d of devices){
                for(let s of d.switches){
                    if(s.deviceType!==7){
                        app+=1
                    }
                }
            }
        }
        setAppliances(app)
    },[devices])
   
    return (
        <div
          onClick={()=>{
            if(totalRoomHouseTime[roomName]!==undefined && totalRoomHouseTime[roomName]>0){
                 navigation('/analystic/room',{state:{barData:barData[roomName],roomOnTime:totalRoomHouseTime[roomName],roomName:roomName}})
            }else{
                notifyFailed("There is no On-Time")
            }
          }}
        >
        <div style={{...style.roomContainer,marginTop:index===0?"10px":"20px"}} key={id}>
            <div>
                <div style={style.Bolddiv}>{roomName}</div>
                <div style={{...style.Lightdiv,color:"#898989"}}>{appliances} appliances</div>
            </div>
            <div style={style.hourIconContainer}>
                <div>
                    <div style={style.Bolddiv}>{(Object.keys(totalRoomHouseTime).length!==0?Math.floor(totalRoomHouseTime[roomName]===undefined?0:totalRoomHouseTime[roomName]):0)}h</div>
                    <div 
                      style={{...style.Lightdiv,color:"#898989"}}
                    >
                        {Math.round(((Object.keys(totalRoomHouseTime).length!==0?Math.floor(totalRoomHouseTime[roomName]===undefined?0:totalRoomHouseTime[roomName]):0)/24)*100)}%
                    </div>
                </div>
                <div style={style.iconContainer}>
                    
                    <ChevronRightIcon
                        className='arrowIcon'
                    />
                </div>
            </div>
        </div>
        </div>
    )
}
function RoomBreakDown({totalRoomHouseTime,barData}) {
     
    let rooms = useSelector(state=>state.rooms.list)
    return (
        <div 
          className='boxContainer boxStyleAndroid activeTab'
        >
            <div>
                <div className='date'>{moment().format("MMM Do")}</div>
            </div>
            <div style={style.headerdivContainer}>
                <div style={style.Bolddiv}>Room wise</div>
                <div style={style.Lightdiv}>  breakdown</div>
            </div>
            {
                rooms.map((r,index)=>{
                    return(
                        <Room roomName={r.roomName} id={r._id} devices={r.devices} totalRoomHouseTime={totalRoomHouseTime} barData={barData} index={index}/>
                    )
                })
            }
          
        </div>
    )
}

export default RoomBreakDown