import React from "react";
import { Col } from "react-bootstrap";
import { offButtonStyle } from "../../config/style";

const LongUpDownController = ({
  upImage,
  downImage,
  label,
  onUpClicked,
  onDownClicked,
}) => (
  <Col
    style={{
      height: "20vh",
      width: "14vw",
      backgroundColor: "white",
      borderRadius: "14vw",
      ...offButtonStyle,
      padding: "3.5vw",
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "column",
    }}
  >
    <div onClick={onUpClicked}>
      <img src={upImage} style={{ height: "7vw", width: "7vw" }} alt={'up'} />
    </div>
    <span>{label}</span>
    <div onClick={onDownClicked}>
      <img src={downImage} style={{ height: "7vw", width: "7vw" }} alt={'down'} />
    </div>
  </Col>
);

export default LongUpDownController;

