import React, { Component } from "react";
import { connect } from "react-redux";
import withRouter from "../components/withRouter";
import { loginOutletUser } from "../redux/actions/user";

import QrLoginView from "../views/QrLoginView";

class OutletLogin extends Component {
  handleSubmit = (details) => {
    this.props.loginOutletUser({
      outletId: this.props.params.shopId,
      ...details,
    });
  };
  render() {
    return <QrLoginView onSubmit={this.handleSubmit} />;
  }
}

const mapStateToProps = () => ({});
export default connect(mapStateToProps, { loginOutletUser })(withRouter(OutletLogin))
