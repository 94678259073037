/* eslint-disable */

import React from "react";
import { createScope, map, transformProxies } from "./helpers";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=64351a03b2bd465fdfb1ff47"
    ).then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class QrLoginView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require("../controllers/QrLoginController");
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == "MODULE_NOT_FOUND") {
        Controller = QrLoginView;

        return Controller;
      }

      throw e;
    }
  }

  onSubmit = (event) => {
    event.preventDefault();
    const txtName = document.getElementById('txtName');
    const txtPhone = document.getElementById('txtPhone');
    const txtEmail = document.getElementById('txtEmail');
    const txtCity = document.getElementById('txtCity');
    const name = txtName.value;
    const phone = txtPhone.value;
    const email = txtEmail.value;
    const city = txtCity.value;
    // add validation to all fields here, if everything passes call the submit props with details
  
    this.props.onSubmit({name, phone, email, city});
  }
  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "64351a03b2bd464091b1ff41";
    htmlEl.dataset["wfSite"] = "64351a03b2bd465fdfb1ff47";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  
  }

  render() {
    const proxies =
      QrLoginView.Controller !== QrLoginView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/aliste-faf72be6c4b357a259c229ecab95a0cf.webflow.css);


          body {
          -webkit-font-smoothing: antialiased;
          -moz-font-smoothing: antialiased;
          -o-font-smoothing: antialiased;
          }



          @media screen and (min-device-width:767px) {
          .scan__cursor-wrapper {
            mix-blend-mode: difference;
          }
          }
        `,
          }}
        />
        <span className="af-view">
          <div className="af-class-body">
            <a
              id="btnWhatsApp"
              href="#"
              className="af-class-link-block w-inline-block"
            >
              <img
                src="/images/WhatsApp_logo.svg"
                loading="lazy"
                width={50}
                height={50}
                alt="Whatsapp"
                id="btnWhatsapp"
                className="af-class-image-31"
              />
            </a>
            <div
              id="navbar"
              data-animation="default"
              data-collapse="medium"
              data-duration={400}
              data-easing="ease"
              data-easing2="ease"
              role="banner"
              className="af-class-navbar w-nav"
            >
              <div className="af-class-container-navigation-8">
                <div className="af-class-navigation-menu">
                  <a
                    href="index.html"
                    aria-current="page"
                    className="af-class-brand-4 w-nav-brand w--current"
                  >
                    <img
                      src="/images/Aliste_Logo_1.webp"
                      alt="Aliste Logo"
                      className="af-class-brand-logo"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              id="Contact-Us"
              className="af-class-section-39 af-class-wf-section"
            >
              <div className="af-class-container-10-copy-copy">
                <div className="w-layout-grid af-class-grid-copy">
                  <div
                    style={{
                      WebkitTransform:
                        "translate3d(-30px, 0px, 0px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(-30px, 0px, 0px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(-30px, 0px, 0px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(-30px, 0px, 0px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transformStyle: "preserve-3d",
                      opacity: 0,
                    }}
                    className="af-class-div-block-heading-2"
                  >
                    <h1 className="af-class-hero-heading-5-copy-2">
                      Make your home
                      <span className="af-class-underline-span">
                        <br />
                        <strong>smart</strong>
                      </span>
                      <strong> with Aliste</strong>
                    </h1>
                    <p
                      data-w-id="1ee67e38-a3b8-5cc6-e671-0279935a3baf"
                      className="af-class-header-paragraph-4-copy-2"
                    >
                      Automate your existing home in a matter <br />
                      of minutes at unbelievable prices!
                    </p>
                    <img
                      src="https://uploads-ssl.webflow.com/63f0bcdd700f686a5128b2f2/63f0c78bb50e554977e6dc40_Artboard%2010.webp"
                      loading="lazy"
                      width={800}
                      alt
                      className="af-class-image-32"
                    />
                  </div>
                  <img
                    src="/images/Still-Creative-1.png"
                    loading="lazy"
                    width={503}
                    id="w-node-_1ee67e38-a3b8-5cc6-e671-0279935a3bb4-91b1ff41"
                    srcSet="/images/Still-Creative-1-p-500.png 500w, /images/Still-Creative-1-p-800.png 800w, /images/Still-Creative-1-p-1080.png 1080w, /images/Still-Creative-1-p-1600.png 1600w, /images/Still-Creative-1-p-2000.png 2000w, /images/Still-Creative-1-p-2600.png 2600w, /images/Still-Creative-1-p-3200.png 3200w"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 89vw, 100vw"
                    alt
                    className="af-class-image-33"
                  />
                  <div
                    id="w-node-_1ee67e38-a3b8-5cc6-e671-0279935a3bb5-91b1ff41"
                    className="af-class-div-block-76"
                  >
                    <div className="af-class-form-block-2 w-form">
                      <form
                        id="frmContact"
                        name="wf-form-Contact-Form"
                        data-name="Contact Form"
                      >
                        <div className="w-layout-grid af-class-grid-form-moon-2">
                          <div>
                            <label
                              htmlFor="name"
                              className="af-class-field-label-moon-2"
                            >
                              <strong className="af-class-bold-text-24">
                                Fill in your
                              </strong>{" "}
                              details
                              <strong className="af-class-bold-text-22" />
                            </label>
                            <div className="af-class-field-wrap-2">
                              <label
                                htmlFor="name"
                                className="af-class-field-label-moon-copy af-class-field-label"
                              >
                                You will be redirected to our webapp and you'll
                                be able to control the appliances here
                              </label>
                              <div className="af-class-input-icon-wrapper">
                                <input
                                  type="text"
                                  className="af-class-text-2 w-input"
                                  maxLength={256}
                                  name="Name"
                                  data-name="Name"
                                  placeholder="Name"
                                  id="txtName"
                                  required
                                />
                                <img
                                  alt="Name"
                                  loading="lazy"
                                  src="https://uploads-ssl.webflow.com/5f420aab94eec62a38c6940b/5f42fa3efc77437fcf35e199_Icon%20Name.svg"
                                  className="af-class-input-icon-2"
                                />
                              </div>
                            </div>
                            <div className="af-class-field-wrap-2">
                              <div className="af-class-input-icon-wrapper">
                                <input
                                  type="text"
                                  className="af-class-text-2 w-input"
                                  maxLength={256}
                                  name="City"
                                  data-name="City"
                                  placeholder="City"
                                  id="txtCity"
                                  required
                                />
                                <img
                                  alt="City"
                                  loading="lazy"
                                  src="https://uploads-ssl.webflow.com/63f0bcdd700f686a5128b2f2/63f0bd07ee66194572e2d6c2_Asset%205%40300x.webp"
                                  className="af-class-input-icon-2"
                                />
                              </div>
                            </div>
                          </div>
                          <div id="w-node-_1ee67e38-a3b8-5cc6-e671-0279935a3bca-91b1ff41">
                            <div className="af-class-field-wrap-2">
                              <div className="af-class-input-icon-wrapper">
                                <input
                                  type="tel"
                                  className="af-class-text-2 af-class-text-field w-input"
                                  maxLength={256}
                                  name="Phone"
                                  data-name="Phone"
                                  placeholder="Phone Number"
                                  id="txtPhone"
                                  required
                                />
                                <img
                                  alt="Phone Number"
                                  loading="lazy"
                                  src="https://uploads-ssl.webflow.com/63f0bcdd700f686a5128b2f2/63f0bd07ee66196dede2d6c4_Asset%206%40300x.webp"
                                  className="af-class-input-icon-2"
                                />
                              </div>
                            </div>
                            <div className="af-class-field-wrap-2">
                              <div className="af-class-input-icon-wrapper">
                                <input
                                  type="email"
                                  className="af-class-text-2 w-input"
                                  maxLength={256}
                                  name="Your-Email"
                                  data-name="Your Email"
                                  placeholder="Email"
                                  id="txtEmail"
                                />
                                <img
                                  alt="Email"
                                  loading="lazy"
                                  src="https://uploads-ssl.webflow.com/5f420aab94eec62a38c6940b/5f42fa3e21f4e3f05b480852_Icon%20Mail.svg"
                                  className="af-class-input-icon-2"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="af-class-button-wrap-centre">
                          <input
                            type="submit"
                            id="btnSubmit"
                            onClick={this.onSubmit}
                            defaultValue="Request a call back"
                            data-wait
                            className="af-class-submit-button-moon-2 w-button"
                          />
                        </div>
                      </form>
                      <div
                        id="msgSuccess"
                        className="af-class-success-message w-form-done"
                      >
                        <div className="af-class-div-block-74">
                          <img
                            src="https://uploads-ssl.webflow.com/63f0bcdd700f686a5128b2f2/63f0bd07ee66192bfce2d6c6_Asset%2073.webp"
                            loading="lazy"
                            alt="Done"
                            className="af-class-image-30"
                          />
                          <div
                            id="txtSuccess"
                            className="af-class-text-block-283"
                          >
                            Thank you! We have received your request, someone
                            will get in touch with you shortly
                          </div>
                        </div>
                      </div>
                      <div
                        id="msgError"
                        className="af-class-error_messege w-form-fail"
                      >
                        <div className="af-class-div-block-74-copy">
                          <div className="af-class-text-block-283-copy">
                            Oops! Something went wrong while submitting the
                            form.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-html-embed w-embed w-script" />
            <section className="af-class-feature-section-8">
              <div className="af-class-feature-container-3">
                <header className="af-class-title-wrapper-smart-home-2">
                  <div className="af-class-price-title">
                    UNPARALLELED SOLUTION
                  </div>
                  <h2 className="af-class-pricing-header-5">
                    What is an Aliste{" "}
                    <strong className="af-class-bold-text-19 af-class-gradient-text">
                      smart home{" "}
                    </strong>
                    🏡
                  </h2>
                  <p
                    data-w-id="c029e54f-f768-135b-7fbd-8ca9000cf26b"
                    className="af-class-pricing-paragraph-4"
                  >
                    An Aliste Smart Home is designed to elevate the comfort of
                    your space &amp; redefine the way you live your daily life!
                  </p>
                </header>
                <div
                  data-w-id="c029e54f-f768-135b-7fbd-8ca9000cf26d"
                  className="w-layout-grid af-class-feature-grid-3-copy"
                >
                  <a
                    id="w-node-c029e54f-f768-135b-7fbd-8ca9000cf26e-91b1ff41"
                    href="#"
                    className="af-class-feature-card-8 w-inline-block"
                  >
                    <div className="w-row">
                      <div className="af-class-column-6 w-col w-col-6 w-col-small-6 w-col-tiny-6">
                        <img
                          src="/images/Aliste-App-2_1Aliste-App-2.webp"
                          loading="lazy"
                          sizes="(max-width: 479px) 100vw, (max-width: 767px) 43vw, (max-width: 1279px) 44vw, 536.7578125px"
                          srcSet="/images/Aliste-App-2_1-p-500.png 500w, /images/Aliste-App-2_1-p-800.png 800w, /images/Aliste-App-2_1-p-1080.png 1080w, /images/Aliste-App-2_1Aliste-App-2.webp 1400w"
                          alt="Home automation mobile app"
                          className="af-class-image-27"
                        />
                      </div>
                      <div className="af-class-column-6 w-col w-col-6 w-col-small-6 w-col-tiny-6">
                        <h3 className="af-class-feature-title-8">
                          App Control
                        </h3>
                        <p className="af-class-feature-paragraph-8">
                          We put your home in the palm of your hands with our
                          highly lauded mobile app.
                        </p>
                      </div>
                    </div>
                  </a>
                  <a
                    id="w-node-c029e54f-f768-135b-7fbd-8ca9000cf277-91b1ff41"
                    href="#"
                    className="af-class-feature-card-8 w-inline-block"
                  >
                    <div className="af-class-columns-2 w-row">
                      <div className="af-class-column-6 w-col w-col-6 w-col-small-6 w-col-tiny-6">
                        <img
                          src="/images/Voice_Assistant.webp"
                          loading="lazy"
                          alt="Voice assistant for home control"
                          className="af-class-image-27-copy"
                        />
                      </div>
                      <div className="af-class-column-6 w-col w-col-6 w-col-small-6 w-col-tiny-6">
                        <h4 className="af-class-feature-title-8">
                          Voice Control
                        </h4>
                        <p className="af-class-feature-paragraph-8">
                          Compatible with all leading voice assistants like
                          Alexa, Google Home &amp; Apple Siri
                        </p>
                      </div>
                    </div>
                  </a>
                  <a
                    href="#"
                    className="af-class-feature-card-8 w-inline-block"
                  >
                    <div className="w-row">
                      <div className="af-class-column-6 w-col w-col-6 w-col-small-6 w-col-tiny-6">
                        <h4 className="af-class-feature-title-8-copy-2">
                          Schedules
                        </h4>
                        <p className="af-class-feature-paragraph-8-copy">
                          Get time-based appliance control with scheduling &amp;
                          timer functionalities.
                        </p>
                      </div>
                      <div className="af-class-column-6 w-col w-col-6 w-col-small-6 w-col-tiny-6">
                        <img
                          src="/images/Asset-32.webp"
                          loading="lazy"
                          sizes="(max-width: 479px) 100vw, (max-width: 767px) 43vw, (max-width: 1279px) 44vw, 536.7578125px"
                          srcSet="/images/Asset-32-p-500.webp 500w, /images/Asset-32-p-800.webp 800w, /images/Asset-32.webp 800w"
                          alt="Smart home scheduling feature"
                          className="af-class-image-28"
                        />
                      </div>
                    </div>
                  </a>
                  <a
                    href="#"
                    className="af-class-feature-card-8 w-inline-block"
                  >
                    <div className="w-row">
                      <div className="af-class-column-6 w-col w-col-6 w-col-small-6 w-col-tiny-6">
                        <h4 className="af-class-feature-title-8-copy">
                          Analytics
                        </h4>
                        <p className="af-class-feature-paragraph-8-copy-2">
                          Gain insight into consumption &amp; save on your bills
                          with analytics &amp; bill prediction.
                        </p>
                      </div>
                      <div className="af-class-column-6 w-col w-col-6 w-col-small-6 w-col-tiny-6">
                        <img
                          src="/images/Asset-3_1Asset-3.webp"
                          loading="lazy"
                          sizes="(max-width: 479px) 100vw, (max-width: 991px) 39vw, 330px"
                          srcSet="/images/Asset-3_1-p-500.png 500w, /images/Asset-3_1-p-800.png 800w, /images/Asset-3_1-p-1080.png 1080w, /images/Asset-3_1-p-1600.png 1600w, /images/Asset-3_1-p-2000.png 2000w"
                          alt="Analytics of a smart home"
                          className="af-class-image-28"
                        />
                      </div>
                    </div>
                  </a>
                </div>
                <div className="af-class-container-13 w-container">
                  <a
                    href="#navbar"
                    className="af-class-button-sign-up-2 w-button"
                  >
                    Contact us
                    <strong className="af-class-bold-text-17"> now</strong>
                  </a>
                </div>
              </div>
            </section>
            <section
              id="devices"
              className="af-class-section-38 af-class-wf-section"
            >
              <div className="af-class-title-wrapper-devices">
                <div className="af-class-price-title">
                  TRULY DISRUPTIVE TECH
                </div>
                <h3 className="af-class-pricing-header-5">
                  Our devices that{" "}
                  <strong className="af-class-bold-text-7 af-class-gradient-text">
                    make it happen{" "}
                  </strong>
                  ⚡️
                </h3>
                <p
                  data-w-id="9cf10fee-db3c-109f-5f2b-74d05c49239a"
                  className="af-class-pricing-paragraph-4"
                >
                  With an inventive mindset, we have created the coolest smart
                  home tech that is convenient to use and affordable for our
                  users.
                </p>
              </div>
              <div className="af-class-container-small">
                <div className="af-class-interaction-wrapper-2">
                  <div
                    data-w-id="9cf10fee-db3c-109f-5f2b-74d05c49239e"
                    className="w-layout-grid af-class-feature-grid-5-copy"
                  >
                    <a
                      href="#"
                      className="af-class-feature-card-8-copy w-inline-block"
                    >
                      <img
                        src="/images/Aliste_Sync.webp"
                        loading="lazy"
                        sizes="(max-width: 767px) 206.19140625px, (max-width: 991px) 27vw, 249.6484375px"
                        srcSet="/images/Aliste_Sync-p-500.webp 500w, /images/Aliste_Sync.webp 565w"
                        alt="Home Automation Device by Aliste"
                        className="af-class-ir-image"
                      />
                      <h3 className="af-class-feature-title-8-copy-copy">
                        Modular Switch Automation
                      </h3>
                      <p className="af-class-feature-paragraph-8-copy-copy">
                        Allows Smart control of all existing switch-based
                        appliances like Lights, Fans, Geysers &amp; Sockets
                      </p>
                      <p className="af-class-feature-paragraph-8-copy-copy">
                        Fits inside your home's existing switchboard seamlessly
                      </p>
                      <p className="af-class-feature-paragraph-8-copy-copy-4">
                        No rewiring required : Hassle-free installation
                      </p>
                    </a>
                    <a
                      href="#"
                      className="af-class-feature-card-8-copy w-inline-block"
                    >
                      <img
                        src="/images/IR_Blaster.webp"
                        loading="lazy"
                        sizes="(max-width: 767px) 206.19140625px, (max-width: 991px) 27vw, 249.6484375px"
                        srcSet="/images/IR_Blaster-p-500.webp 500w, /images/IR_Blaster.webp 565w"
                        alt="Home Automation Device by Aliste"
                        className="af-class-ir-image"
                      />
                      <h3 className="af-class-feature-title-8-copy-copy-2">
                        Universal Remote Automation
                      </h3>
                      <p className="af-class-feature-paragraph-8-copy-copy-3">
                        Smart Control of all standard remote-based appliances
                        like TVs, A.C. s &amp; Sound systems
                      </p>
                      <p className="af-class-feature-paragraph-8-copy-copy">
                        One device automates all appliances in a single room
                      </p>
                      <p className="af-class-feature-paragraph-8-copy-copy">
                        Capable of controlling 30,000+ different remotes
                      </p>
                    </a>
                  </div>
                  <div className="af-class-container-13 w-container">
                    <a
                      href="#navbar"
                      className="af-class-button-sign-up-2 w-button"
                    >
                      Contact us
                      <strong className="af-class-bold-text-17"> now</strong>
                    </a>
                  </div>
                </div>
              </div>
            </section>
            <section className="af-class-video-section-4">
              <div className="af-class-video-container-2">
                <div className="af-class-title-wrapper-shark-tank-2">
                  <div className="af-class-price-title">
                    SEEM&nbsp;FAMILIAR? WE&nbsp;PROBABLY&nbsp;ARE
                  </div>
                  <h2 className="af-class-pricing-header-5 af-class-clipped">
                    As seen &amp; loved on{" "}
                    <strong className="af-class-bold-text-18 af-class-clipped af-class-gradient-text">
                      Shark Tank India{" "}
                    </strong>{" "}
                    <strong className="af-class-shark">🦈</strong>
                  </h2>
                  <div className="w-embed">
                    <style
                      dangerouslySetInnerHTML={{
                        __html:
                          " \n/*Gradient text styling */\n.af-view .af-class-gradient-text {\n\tbackground-size:200%;\n\tanimation: animate 4s linear infinite \n}\n/* Gradient animation */\n@keyframes animate {\n\t0% {\n  \tbackground-position: 200%;\n  }\n  100% {\n  \tbackground-position: 0%;\n  }\n}\n",
                      }}
                    />
                  </div>
                  <p
                    data-w-id="b4946d6d-7a51-e5c1-7121-5bcf5205c1d6"
                    className="af-class-pricing-paragraph-4"
                  >
                    Featured on season 1 of Shark Tank India! Our team &amp;
                    solutions were showered with tons of appreciation by the
                    audience &amp; sharks.
                  </p>
                </div>
                <div className="af-class-video-wrapper-2">
                  <div className="af-class-thumbnail-wrapper-2">
                    <div
                      loading="lazy"
                      style={{ paddingTop: "56.17021276595745%" }}
                      className="w-embed-youtubevideo af-class-youtube"
                    >
                      <iframe
                        src="https://www.youtube-nocookie.com/embed/n5Xvfjpzqc4?rel=0&controls=1&autoplay=0&mute=0&start=1"
                        frameBorder={0}
                        style={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          width: "100%",
                          height: "100%",
                          pointerEvents: "auto",
                        }}
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        title="Aliste के 6 Co-Founders Sharks के साथ बनाना चाहते हैं हर घर को Smart Home |Shark Tank India|Pitches"
                      />
                    </div>
                  </div>
                  <div className="af-class-thumbnail-wrapper-2-copy">
                    <img
                      src="/images/Shark_Tank_India.webp"
                      loading="lazy"
                      data-w-id="b4946d6d-7a51-e5c1-7121-5bcf5205c1dc"
                      sizes="(max-width: 479px) 100vw, (max-width: 1279px) 94vw, 1000px"
                      alt="Home automation solutions showcase on Shark Tank India."
                      srcSet="/images/Shark_Tank_India-p-500.webp 500w, /images/Shark_Tank_India-p-800.webp 800w, /images/Shark_Tank_India-p-1080.webp 1080w, /images/Shark_Tank_India-p-1600.webp 1600w, /images/Shark_Tank_India.webp 1992w"
                      className="af-class-thumbinail-image"
                    />
                    <a
                      href="#"
                      data-w-id="b4946d6d-7a51-e5c1-7121-5bcf5205c1dd"
                      className="af-class-video-lightbox-link-2 w-inline-block w-lightbox"
                    >
                      <img
                        src="https://uploads-ssl.webflow.com/60bac52fa85cd8650b1f78f1/60bacb65707f8fdefe1e8757_Play%20Icon.svg"
                        loading="lazy"
                        alt="Play video"
                        className="af-class-play-icon"
                      />
                    </a>
                  </div>
                  <h3 className="af-class-video-title">
                    Check out our Shark Tank Feature!
                  </h3>
                </div>
                <div className="af-class-container-13 w-container">
                  <a
                    href="#navbar"
                    className="af-class-button-sign-up-2 w-button"
                  >
                    Contact us
                    <strong className="af-class-bold-text-17"> now</strong>
                  </a>
                </div>
              </div>
            </section>
            <div className="af-class-section-37 af-class-contact-us af-class-wf-section" />
            <div className="af-class-div-block-13">
              <div className="af-class-container-large">
                <img
                  src="/images/Aliste_Logo_2Aliste_Logo.webp"
                  loading="lazy"
                  alt
                  className="af-class-footer-logo"
                />
                <div className="af-class-text-block-34">
                  Elevate the comfort of your home. Aliste Helps you make your
                  home smart without making you burn a hole through your
                  pocket.Our aim is to redefine the way people live by imparting
                  luxury and maximising convenience through our products. Our
                  Smart Home product line and mobile applications have been
                  lauded by our users and industry - experts. Join us in making
                  the Indian home, a more secure &amp; comfortable place to
                  live.
                </div>
                <div className="w-layout-grid af-class-aaaaaa">
                  <div className="w-layout-grid af-class-footer-grid">
                    <div
                      id="w-node-cc26770a-4e6f-8d0f-5b3f-32ee2674461b-26744611"
                      className="af-class-footer-column"
                    >
                      <div className="af-class-footer-title-light">
                        Buy now and get the best smart home solution
                      </div>
                      <a
                        href="#navbar"
                        className="af-class-button-sign-up-2-copy-copy af-class-subred w-button"
                      >
                        Subscribe for{" "}
                        <strong className="af-class-bold-text-17">
                          &nbsp;₹1/day
                        </strong>
                      </a>
                      <div className="w-layout-grid af-class-grid-socials">
                        <a
                          id="btnSMFacebook"
                          href="https://www.facebook.com/alistetechnologies/"
                          className="af-class-social-link w-inline-block"
                        >
                          <img
                            src="/images/Asset-4.svg"
                            loading="lazy"
                            id="btnSMFacebook"
                            alt="Facebook"
                            className="af-class-sm-icon"
                          />
                        </a>
                        <a
                          id="btnSMInstagram"
                          href="https://www.instagram.com/alistetechnologies/"
                          className="af-class-social-link w-inline-block"
                        >
                          <img
                            src="/images/Instagram.svg"
                            loading="lazy"
                            id="btnSMInstagram"
                            alt="Instagram"
                            className="af-class-sm-icon"
                          />
                        </a>
                        <a
                          id="btnSMLinkedIn"
                          href="https://www.linkedin.com/company/alistetechnologies"
                          className="af-class-social-link w-node-cc26770a-4e6f-8d0f-5b3f-32ee26744627-26744611 w-inline-block"
                        >
                          <img
                            src="/images/LinkedIn.svg"
                            loading="lazy"
                            id="btnSMLinkedIn"
                            alt="Linkedin"
                            className="af-class-sm-icon"
                          />
                        </a>
                        <a
                          id="btnSMWhatsApp"
                          href="https://wa.me/+919664861809"
                          className="af-class-social-link w-node-cc26770a-4e6f-8d0f-5b3f-32ee26744629-26744611 w-inline-block"
                        >
                          <img
                            src="/images/Asset-3.svg"
                            loading="lazy"
                            id="btnSMWhatsApp"
                            height="Auto"
                            alt="WhatsApp"
                            className="af-class-sm-icon"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="w-layout-grid af-class-footer-grid-copy">
                    <div
                      id="w-node-cc26770a-4e6f-8d0f-5b3f-32ee2674462c-26744611"
                      className="af-class-footer-column"
                    >
                      <div className="af-class-footer-title-light">Company</div>
                      <a href="#" className="af-class-footer-text-link">
                        Privacy Policy
                      </a>
                      <a href="#" className="af-class-footer-text-link">
                        User Agreement
                      </a>
                      <a
                        href="#Contact-Us"
                        className="af-class-footer-text-link"
                      >
                        Terms of Use
                      </a>
                    </div>
                    <div className="af-class-footer-column">
                      <div className="af-class-footer-title-light">Product</div>
                      <a href="#" className="af-class-footer-text-link">
                        Our App
                      </a>
                      <a
                        href="https://www.alistetechnologies.com/#devices"
                        className="af-class-footer-text-link"
                      >
                        Our Devices
                      </a>
                      <a
                        href="https://www.alistetechnologies.com/#pricing"
                        className="af-class-footer-text-link"
                      >
                        Our Pricing
                      </a>
                    </div>
                    <div className="af-class-footer-column">
                      <div className="af-class-footer-title-light">
                        Marketing
                      </div>
                      <a
                        href="https://www.alistetechnologies.com/#clients"
                        className="af-class-footer-text-link"
                      >
                        Our Clients
                      </a>
                      <a
                        href="https://www.alistetechnologies.com/#investor"
                        className="af-class-footer-text-link"
                      >
                        Our Investors
                      </a>
                      <a
                        href="https://www.alistetechnologies.com/#FAQ"
                        className="af-class-footer-text-link"
                      >
                        Our FAQ
                      </a>
                    </div>
                  </div>
                </div>
                <div className="af-class-footer-title-light-copy">
                  Copyright © Aliste since 2018. All rights reserved.
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default QrLoginView;

/* eslint-enable */
