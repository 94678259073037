import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../Analystic.css";
import LogRow from "./LogRow";
import { capitalize, processedTime } from "../function";
let commandNotInclude = ["wifi Disconnect", "statusUpdate"];
function CommandList({ deviceId, switchId }) {
  let analyticData = useSelector((state) => state.barData);
  let [commandCall, setCommandCall] = useState([]);

  useEffect(() => {
    if (analyticData.length !== 0) {
      let res = analyticData.command.filter(
        (c) =>
          c.deviceId === deviceId &&
          c.switchId === switchId &&
          !commandNotInclude.includes(c.medium)
      );
      setCommandCall(res);
    }
  }, [analyticData, deviceId, switchId]);
  return (
    <div className="boxContainer  activeTab boxStyleAndroid">
      <div>
        <div className="logsHeader">Today Logs</div>
      </div>
      {commandCall.length > 0 && (
        <LogRow medium={"Medium"} time={"Time"} state={"State"} header={true} />
      )}
      {commandCall.length > 0 &&
        commandCall.map((command) => {
          let state = Number(command.state);
          if (state <= 1) {
            state = state * 100;
          } else if (state >= 100) {
            state = 100;
          }
          let val = command.medium.split("(");
          return (
            <LogRow
              medium={capitalize(val[0])}
              state={state === 0 ? "Off" : state === 100 ? "On" : `${state}%`}
              time={processedTime(command.timestamp).toLowerCase()}
              header={false}
            />
          );
        })}
    </div>
  );
}

export default CommandList;
