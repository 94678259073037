export const DeviceTypes = {
  SYNC: 'sync',
  CURTAIN: 'curtain',
  IRBLASTER: 'irblaster',
  LOCK: 'lock',
  NVR: 'nvr',
  IPCAMERA: 'ipcamera',
  NOVA: 'nova',
  MOTION_SENSOR: 'motion_sensor',
  RGB: 'rgb',
  LOCK_HUB: 'lockhub',
  WAVE_SENSOR: 'wavesensor',
  FILLER: 'filler',
  EMPTY_BUTTON: 'empty_button',
  TTLOCK: 'tt_lock',
  TTGATEWAY: 'tt_gateway',
  WIZ: 'wiz',
  STACKS: 'stacks',
  HOMEBRIDGE: 'homebridge',
  RGBWWW: 'rgbwww',
  TUYA_CAMERA: 'tuyaCamera',
  NOVA_SYNC: 'nova_sync',
  HONEYWELL_THERMOSTAT: 'hwts',
};



export const DeviceTypeMap = {
  FAN: 0,
  AC: 1,
  BULB: 2,
  CFL: 3,
  SOCKET: 4,
  GEYSER: 5,
  TUBELIGHT: 6,
  NA: 7,
  TWO_WAY: 8,
  SCENE: 9,
  NOVA: 10,
  MOTION_SENSOR: 11,
  TV: 12,
  SPEAKER: 13,
  FOUNTAIN: 14,
  BEDSIDE_LAMP: 15,
  CHANDELIER: 16,
  STRIP_LIGHT: 17,
  STUDY_LAMP: 18,
  LAMPS: 19,
  FAN_REGULATOR: 20,
};

export const DeviceTypeImageMap = {
  [DeviceTypeMap.AC]: {
    on: require('../assets/devicetypes/ac_on.png'),
    off: require('../assets/devicetypes/ac_off.png'),
  },
  [DeviceTypeMap.FAN_REGULATOR]: {
    on: require('../assets/devicetypes/fan_on.png'),
    off: require('../assets/devicetypes/fan_off.png'),
  },
  [DeviceTypeMap.FAN]: {
    on: require('../assets/devicetypes/fan_on.png'),
    off: require('../assets/devicetypes/fan_off.png'),
  },
  [DeviceTypeMap.BULB]: {
    on: require('../assets/devicetypes/bulb_on.png'),
    off: require('../assets/devicetypes/bulb_off.png'),
  },
  [DeviceTypeMap.CFL]: {
    on: require('../assets/devicetypes/cfl_on.png'),
    off: require('../assets/devicetypes/cfl_off.png'),
  },
  [DeviceTypeMap.SOCKET]: {
    on: require('../assets/devicetypes/socket_on.png'),
    off: require('../assets/devicetypes/socket_off.png'),
  },
  [DeviceTypeMap.GEYSER]: {
    on: require('../assets/devicetypes/geyser_on.png'),
    off: require('../assets/devicetypes/geyser_off.png'),
  },
  [DeviceTypeMap.TUBELIGHT]: {
    on: require('../assets/devicetypes/tube_on.png'),
    off: require('../assets/devicetypes/tube_off.png'),
  },
  [DeviceTypeMap.TV]: {
    on: require('../assets/devicetypes/tv_on.png'),
    off: require('../assets/devicetypes/tv_off.png'),
  },
  [DeviceTypeMap.SPEAKER]: {
    on: require('../assets/devicetypes/speaker_on.png'),
    off: require('../assets/devicetypes/speaker_off.png'),
  },
  [DeviceTypeMap.FOUNTAIN]: {
    on: require('../assets/devicetypes/fountain_on.png'),
    off: require('../assets/devicetypes/fountain_off.png'),
  },
  [DeviceTypeMap.BEDSIDE_LAMP]: {
    on: require('../assets/devicetypes/bedside_lamp_on.png'),
    off: require('../assets/devicetypes/bedside_lamp_off.png'),
  },
  [DeviceTypeMap.CHANDELIER]: {
    on: require('../assets/devicetypes/chandelier_on.png'),
    off: require('../assets/devicetypes/chandelier_off.png'),
  },
  [DeviceTypeMap.STRIP_LIGHT]: {
    on: require('../assets/devicetypes/strip_on.png'),
    off: require('../assets/devicetypes/strip_off.png'),
  },
  [DeviceTypeMap.STUDY_LAMP]: {
    on: require('../assets/devicetypes/study_lamp_on.png'),
    off: require('../assets/devicetypes/study_lamp_off.png'),
  },
  [DeviceTypeMap.LAMPS]: {
    on: require('../assets/devicetypes/lamps_on.png'),
    off: require('../assets/devicetypes/lamps_off.png'),
  },
}
export const HiddenDeviceType = [
  DeviceTypeMap.NA,
  DeviceTypeMap.MOTION_SENSOR,
  DeviceTypeMap.NOVA,
  DeviceTypeMap.TWO_WAY,
];

export const StackTypes = {
  sync: {
    id: 0,
    label: 'Sync',
    deviceType: DeviceTypes.SYNC,
    defaultState: {
      power: 0,
      intensity: 100
    },
    dimmable: false,
    isFanRegulator: false,
  },
  curtain: {
    id: 1,
    label: 'Curtains',
    deviceType: DeviceTypes.CURTAIN,
    defaultState: {
      openState: 0,
      closeState: 0
    },
  },
  rgb: {
    id: 2,
    label: 'RGB',
    deviceType: DeviceTypes.RGB,
    defaultState: {
      red: 255,
      green: 255,
      blue: 255,
      power: 0,
      intensity: 100
    },
  },
};