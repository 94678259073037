import "../styles/timLogin.css";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import logo from "../assets/ziggta/ziggta.jpeg";
import { Button, Modal } from "react-bootstrap";
import { signUpUser } from "../redux/actions/tim";
import { HouseIds, LoginTypes, OwnerNumber } from "../config/globals";
import { Toaster } from "react-hot-toast";
import { loginZiggtaUser } from "../redux/actions/user";
// import Strings from "../../globals/strings";

const ZiggtaLogin = () => {
  const [registered, setRegistered] = useState(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // dispatch(loadUser({
    //   email: 'Dummy',

    //   profile: {
    //     email: 'Dummy',
    //     name: 'Dummy',
    //     first_name: 'Dummy',
    //     last_name: '',
    //     selectedHouse: HouseIds.ziggtaHouse,
    //   },
    //   loggedIn: true,
    //   loginType: LoginTypes.Ziggta,
    // }))
    loginZiggtaUser("Dummy", "+91123456789", new Date());
  }, []);
  const isValidNumber = (number) => {
    let num = number;
    if (String(number).startsWith("+")) {
      // if the number starts with a plus accept it as is
      if (!String(number).startsWith("+91")) return false;
      num = String(number).replace("+91", "");
    }
    // the number does not start with + assume its an indian number and process accordingly
    num = String(num).replace(/\D/g, "");
    while (num.startsWith("0")) {
      num = num.slice(1, num.length);
    }
    return num.length === 10;
  };

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    let roomId = [];
    let detail = {
      name: data.get("name"),
      phoneNumber: data.get("phoneNumber"),
      date: new Date(),
    };
    const num = isValidNumber(detail.phoneNumber);
    if (!num) {
      return;
    }

    const cleanNumber = (num = "") => {
      try {
        let cn = String(num).replace(/\D/g, ""); // replace non digits
        if (cn.startsWith("0")) {
          // the number is starting with 0, remove it
          cn = cn.slice(1, cn.length);
        }
        if (cn.length > 10) {
          cn = cn.slice(cn.length - 10, cn.length);
        }
        return `+91${cn}`;
      } catch (_) {
        return num;
      }
    };
    detail.phoneNumber = cleanNumber(detail.phoneNumber);

    let d = {
      email: detail.phoneNumber,
      name: detail.name,
      houseId: HouseIds.ziggtaHouse,
      isMaster: false,
      owner: false,
      roomIds: roomId,
      timed: true,
      validtill: detail.date,
      ownerNumber: OwnerNumber.ziggta,
      loginType: LoginTypes.Ziggta,
    };
    signUpUser(d, setLoading);
  };

  return (
    <div style={{ backgroundColor: "rgb(249, 249, 251)", height: "100vh" }}>
      {registered ? (
        <div className="main-login-container">
          <div className="login-container">
            <Modal.Body>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={logo}
                  width="20%"
                  style={{ marginBottom: "2%" }}
                  alt={"Aliste Logo"}
                />
              </div>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Mobile Number"
                  name="phoneNumber"
                  required
                />
              </Form.Group>

              <Button
                variant="primary"
                className="mr-2"
                onClick={() =>
                  loginZiggtaUser("Dummy", "+91123456789", new Date())
                }
                disabled={loading}
              >
                Login
              </Button>
            </Modal.Body>
          </div>
          <h2>Or</h2>
          <div className="login-container">
            <Button onClick={() => setRegistered(false)}>Sign Up</Button>
          </div>
        </div>
      ) : (
        <Modal
          show={true}
          style={{ backgroundColor: "rgb(249, 249, 251)" }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={logo}
                width="25%"
                style={{ marginBottom: "2%" }}
                alt={"Aliste Logo"}
              />
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Guest Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  name="name"
                  required
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Guest Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Mobile Number"
                  name="phoneNumber"
                  required
                />
              </Form.Group>

              <Button variant="primary" type="submit" disabled={loading}>
                Sign Up
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      )}

      <Toaster />
    </div>
  );
};

export default ZiggtaLogin;
