import React, {useEffect, useState} from 'react';
import DemoGraph from './DemoGraph';
import {useSelector} from 'react-redux';
import './Analystic.css'
import { mostUsedAppliance, timeFormate } from './function';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { notifyFailed } from '../../config/notification';
import { useNavigate } from 'react-router-dom';

function MostUsedAppliance({name}) {
  let [data, setData] = useState({barData: [], mostUsedApp: {}});
  const [time, setTime] = useState(0);
  const navigation = useNavigate();

  let analyticData = useSelector(state => state.barData);
  let sessions = useSelector(state => state.todaySessions);

  useEffect(() => {
    if (sessions.length === 0) return;
    let res = [];
    if (name === 'House') {
      res = mostUsedAppliance(sessions);
      if (
        res.mostUsedApp !== undefined &&
        analyticData.TimeDifferenceYesterday[
          `${res.mostUsedApp.deviceId}$${res.mostUsedApp.switchId}`
        ] !== undefined
      ) {
        setTime(
          analyticData.TimeDifferenceYesterday[
            `${res.mostUsedApp.deviceId}$${res.mostUsedApp.switchId}`
          ],
        );
      }
    } else {
      let roomSessions = sessions.filter(s => s.roomName === name);
      res = mostUsedAppliance(roomSessions);
      if (
        res.mostUsedApp !== undefined &&
        analyticData.TimeDifferenceYesterday[
          `${res.mostUsedApp.deviceId}$${res.mostUsedApp.switchId}`
        ] !== undefined
      ) {
        setTime(
          analyticData.TimeDifferenceYesterday[
            `${res.mostUsedApp.deviceId}$${res.mostUsedApp.switchId}`
          ],
        );
      }
    }
    let applianceTotalOnTime = 0;
    for (let v of res.barData) {
      applianceTotalOnTime += v.value;
    }
    setData({...res, onTime: applianceTotalOnTime});
  }, [sessions]);
  return (

      <div
        onClick={() => {
        if (data.onTime !== 0) {
          navigation('/analystic/room/appliance',{state:{
            barData: data.barData,
            applinceOnTime: data.onTime,
            switchId: data.mostUsedApp?.switchId,
            deviceId: data.mostUsedApp?.deviceId,
            applinaceName: data.mostUsedApp?.switchName,
          }});
        } else {
          notifyFailed('There is no On-Time');
        }
      }}
        className='boxContainer activeTab boxStyleAndroid smallBox'
        >
        <div className='boxContainerTitle'>
          Most used appliance
        </div>
        <div className='boxContainerSubTitle'>
          {data.mostUsedApp?.switchName}
        </div>
        <div
          style={{
            display:'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: "20px",
            marginTop: "3px",
          }}>
          <div
            style={{display:'flex', flexDirection: 'row', alignItems: 'center', height: "20px"}}>
     
             {
                        time > 0 ? <ArrowDropUpIcon className='upIcon upDownIconMargin' /> : <ArrowDropDownIcon className='upDownIcon upDownIconMargin' />
                    }
          </div>
          <div
            className={`${time > 0 ? "upIcon ": "upDownIcon"}`}
           >
            {time !== 0 && time !== -Infinity && !isNaN(time) && time !== null
              ? timeFormate(time)
              : 0}
          </div>
        </div>
        <DemoGraph barData={data.barData} />
      </div>

  );
}

export default MostUsedAppliance;
