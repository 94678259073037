import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./screens/Home";
import IndexView from "./views/IndexView";
import { useSelector } from "react-redux";
import OutletLogin from "./screens/OutletLogin";
import TimLogin from "./screens/TimLogin";
import ZiggtaLogin from "./screens/ZiggtaLogin";
import ZiggtaLogins from "./views/ZiggtaLogins";
import HostlerLogin from "./views/HostlerLogin";
import DummyLogin from "./views/DummyLogin";
import Hostler from "./screens/Hostler";
import Tahia from "./views/Tahia";
import HotelLogin from "./views/HotelLogin";
import OneDayLogin from "./views/OneDayLogin";
import VistaraLogin from "./views/VistaraLogin";

import Analystic from "./components/Analystic/Analystic";
import RoomAnalyticsV2 from "./components/Analystic/Room/RoomAnalyticsV2";
import ApplianceAnalysticV2 from "./components/Analystic/Appliances/ApplianceAnalysticV2";
import PermanentLogin from "./views/PermanentLogin";
import OneDayLoginRoom from "./views/OneDayLoginRoom";
import LoginWithCheckOutDate from "./views/LoginWithCheckOutDate";

function App() {
  const loggedIn = useSelector((state) => state.user.loggedIn);

  return (
    <div>
      {loggedIn && !window.location.pathname.includes("/analystic")? (
        <Home />
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/tmr" element={<IndexView />} />
            {
              loggedIn && <Route path="/" element={<Home />}/>
            }
            <Route path="/analystic" element={<Analystic/>}/>
            <Route path="/analystic/room" element={<RoomAnalyticsV2/>}/>
            <Route path="/analystic/room/appliance" element={<ApplianceAnalysticV2/>}/>
            <Route path="/outlets/:shopId" element={<OutletLogin />} />
            <Route path="/tim_login" element={<TimLogin />} />
            <Route path='/zz' element={<ZiggtaLogin />}/>
            <Route path="/ziggta" element={<ZiggtaLogins/>}/>
            <Route path="/hosteller" element={<HostlerLogin/>}/>
            <Route path="/hostellers" element={<HostlerLogin/>}/>
            <Route path="/demo" element={<DummyLogin/>}/>
            <Route path="/tahia" element={<Tahia/>}/>
            <Route path="/sdk/:houseId/:roomId" element={<Hostler/>}/>
            <Route path="/hotel/:houseId/:roomId" element={<HotelLogin/>}/>
            <Route path="/login/:houseId" element={<OneDayLogin/>}/>
            <Route path="/login/:houseId/:roomId" element={<OneDayLoginRoom/>}/>
            <Route path="/vistara/:houseId" element={<VistaraLogin/>}/>
            <Route path="/loginCheckOut/:houseId" element={<LoginWithCheckOutDate/>}/>
            <Route path="/loginHouse" element={<PermanentLogin/>}/>
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
