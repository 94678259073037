import "../styles/timLogin.css";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import logo from "../assets/Tim/logo.jpeg";
import { Button, Modal } from "react-bootstrap";
import { loginUserPhone, signUpUser } from "../redux/actions/tim";
import axios from "axios";
import { HouseIds, LoginTypes, OwnerNumber, cloudUrl } from "../config/globals";
import { notifyFailed } from "../config/notification";
import { Toaster } from "react-hot-toast";
// import Strings from "../../globals/strings";

const TimLogin = (props) => {
  const [username, setUsername] = useState("");
  const [registered, setRegistered] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rooms, setRooms] = useState("");
  const [roomData, setRoomData] = useState([]);
  const [originalRoomData, setOriginalRoomData] = useState([]);
  useEffect(() => {
    axios
      .get(
        `${cloudUrl}/api/fetch/house2/${HouseIds.timHouse}/${
          OwnerNumber.tim
        }?user=${"Tim-Login"}`
      )
      .then(async function (res) {
        if (res.status === 200) {
          let skipRoom = ["Outside", "GF common", "FF common"];
          // obj = { rooms: res.data.rooms, house: res.data }
          let roomx = [];
          for (let r of res.data.rooms.sort((a, b) =>
            a.roomName > b.roomName ? 1 : -1
          )) {
            if (!skipRoom.includes(r.roomName)) {
              roomx.push(r);
            }
          }
          setRoomData(roomx);
          setOriginalRoomData(res.data.rooms);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  const isValidNumber = (number) => {
    let num = number;
    if (String(number).startsWith("+")) {
      // if the number starts with a plus accept it as is
      if (!String(number).startsWith("+91")) return false;
      num = String(number).replace("+91", "");
    }
    // the number does not start with + assume its an indian number and process accordingly
    num = String(num).replace(/\D/g, "");
    while (num.startsWith("0")) {
      num = num.slice(1, num.length);
    }
    return num.length === 10;
  };
  const handleSubmitLogin = async () => {
    setLoading(true);
    const num = isValidNumber(username);
    if (!num) {
      notifyFailed("Phone Number is not valid");
      setLoading(false);
      return;
    }

    const cleanNumber = (num = "") => {
      try {
        let cn = String(num).replace(/\D/g, ""); // replace non digits
        if (cn.startsWith("0")) {
          // the number is starting with 0, remove it
          cn = cn.slice(1, cn.length);
        }
        if (cn.length > 10) {
          cn = cn.slice(cn.length - 10, cn.length);
        }
        return `+91${cn}`;
      } catch (_) {
        return num;
      }
    };

    const response = await loginUserPhone(cleanNumber(username), username);
    if (!response.success) {
      notifyFailed(response.message);
    }
    setLoading(false);
  };

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    if (rooms === "") {
      notifyFailed("Room Not Selected");
      setLoading(false);
      return;
    }
    const data = new FormData(event.currentTarget);
    let roomId = [];

    for (let r of originalRoomData) {
      let find = false;

      if (rooms === r._id) {
        find = true;
      }

      if (!find) {
        roomId.push(r._id);
      }
    }

    let detail = {
      name: data.get("name"),
      phoneNumber: data.get("phoneNumber"),
      date: data.get("date"),
    };
    const num = isValidNumber(detail.phoneNumber);
    if (!num) {
      return;
    }

    const cleanNumber = (num = "") => {
      try {
        let cn = String(num).replace(/\D/g, ""); // replace non digits
        if (cn.startsWith("0")) {
          // the number is starting with 0, remove it
          cn = cn.slice(1, cn.length);
        }
        if (cn.length > 10) {
          cn = cn.slice(cn.length - 10, cn.length);
        }
        return `+91${cn}`;
      } catch (_) {
        return num;
      }
    };
    detail.phoneNumber = cleanNumber(detail.phoneNumber);
    if (roomId.length === 0) {
      notifyFailed("Room Not Selected");
    }
    let d = {
      email: detail.phoneNumber,
      name: detail.name,
      houseId: HouseIds.timHouse,
      isMaster: false,
      owner: false,
      roomIds: roomId,
      timed: true,
      validtill: detail.date,
      ownerNumber: OwnerNumber.tim,
      loginType: LoginTypes.Tim,
    };
    signUpUser(d, setLoading);
  };

  return (
    // <div style={{ backgroundColor: "black", height: "100vh" }}>
    //   <Container fluid>
    //     <Row>
    //       <Col className="mobileHide"></Col>
    //       <Col>
    //         <div className="login-form">
    //           <Form onSubmit={handleSubmit}>
    //             <div style={{ textAlign: "center", marginBottom: "5%" }}>
    //               <img src={logo} width="20%" style={{ marginBottom: "2%" }} alt={'Aliste Logo'} />
    //               <h3>{"TIM LEISURE SERVICES"}</h3>
    //             </div>
    //             {/* <div
    //               style={{
    //                 textAlign: "center",
    //                 marginBottom: "10%",
    //                 fontSize: "15px",
    //               }}
    //             >
    //               Centralized Testing
    //               <br />
    //               <div style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
    //                 Seamless, Smart, Efficient
    //               </div>
    //             </div> */}
    //             <Form.Group className="input-wrapper mt-6" controlId="username" >
    //               <input
    //                 size="lg"
    //                 className="input"
    //                 onChange={(e) => setUsername(e.target.value)}
    //                 type="username"
    //                 placeholder="Username"
    //               />
    //             </Form.Group>
    //             <Form.Group
    //               className="input-wrapper"
    //               controlId="formBasicPassword"
    //             >
    //               <input
    //                 size="lg"
    //                 className="input"
    //                 onChange={(e) => {
    //                   setPassword(e.target.value);
    //                 }}
    //                 type="password"
    //                 placeholder="Password"
    //                 onKeyUp={(e) => {
    //                   if (e.keyCode === 13) {
    //                     handleSubmit();
    //                   }
    //                 }}
    //               />
    //             </Form.Group>
    //           </Form>
    //           <button onClick={handleSubmit} className="button">
    //             LOGIN
    //           </button>
    //           {message && <div style={{ color: "red" }}>{message}</div>}
    //         </div>
    //       </Col>
    //     </Row>
    //   </Container>
    // </div>
    <div style={{ backgroundColor: "rgb(249, 249, 251)", height: "100vh" }}>
      {registered ? (
        <div className="main-login-container">
          <div className="login-container">
            <Modal.Body>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={logo}
                  width="20%"
                  style={{ marginBottom: "2%", borderRadius: "12px" }}
                  alt={"Aliste Logo"}
                />
              </div>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Mobile Number"
                  name="phoneNumber"
                  required
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Group>

              <Button
                variant="primary"
                className="mr-2"
                onClick={handleSubmitLogin}
                disabled={loading}
              >
                Login
              </Button>
            </Modal.Body>
          </div>
          <h2>Or</h2>
          <div className="login-container">
            <Button onClick={() => setRegistered(false)}>Sign Up</Button>
          </div>
        </div>
      ) : (
        <Modal
          show={true}
          style={{ backgroundColor: "rgb(249, 249, 251)" }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={logo}
                width="25%"
                style={{ marginBottom: "2%", borderRadius: "12px" }}
                alt={"Aliste Logo"}
              />
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Guest Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  name="name"
                  required
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Guest Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Mobile Number"
                  name="phoneNumber"
                  required
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>CheckOut Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Date"
                  name="date"
                  required
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Room Select</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  required
                  onChange={(e) => setRooms(e.target.value)}
                >
                  <option>Select Room</option>
                  {roomData.map((r) => {
                    return <option value={r._id}>{r.roomName}</option>;
                  })}
                </Form.Select>
                {/* <Multiselect
                    displayValue="roomName"
                    selectedValues={rooms}
                    onRemove={setRooms}
                    onSelect={setRooms}
                    options={roomData}
                    style={{
                      multiselectContainer: {
                        marginTop: "3px",
                        backgroundColor: "#ffffff"
                      },
                    }}
                  /> */}
              </Form.Group>
              <Button variant="primary" type="submit" disabled={loading}>
                Sign Up
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      )}

      <Toaster />
    </div>
  );
};

export default TimLogin;
