import { HouseActions, SelectedRoomAction, UserActions } from "../actionTypes";

const initialState = {
	iterated: [],
	details: {},
	_id: '',
	houseName: '',
	loading: false,
}

const house = (state = initialState, action) => {
	const {type, payload} = action;

	switch (type) {
		case HouseActions.LOAD:
			return {
				...state,
				...payload,
				loading: false,
			}
		case UserActions.LOGOUT:
			return initialState;
		default:
		return state;
	}
}
export const selectedRoomReducer = (state={},action)=>{
   const {type,payload} = action
   switch(type){
	  case SelectedRoomAction.LOAD:
		return payload
	  default :
	    return state
   }
}

export default house;