
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { CustomRemoteButtons } from "../../config/nova";
import { getButtonsFromRemote } from "../../util/nova";
import Header from "../Header";
import RemoteButton from "./RemoteButton";

class CustomRemote extends Component {
  render() {
    const { closeModal, remote, powerClicked, sendCode } = this.props;
    const { custom_button_groups } = getButtonsFromRemote(remote);
    return (
      <div>
        <ReactModal
          isOpen={this.props.showModal}
          style={{
            overlay: {
              width: "100%",
              padding: "2vh 0px 0px 0px",
              margin: "0px",
              backgroundColor: "black",
            },
            content: {
              position: "relative",
              inset: "0px",
              margin: "0px",
              padding: "0px",
              height: "100%",
              minWidth: "100%",
              backgroundColor: "#f2f2f2",
              borderRadius: "8vw 8vw 0 0",
              overflow: "scroll",
              overflowX: 'hidden',
            },
          }}
        >
          <div
            style={{
              padding: "15px 15px 5px",
              position: "sticky",
              top: 0,
              left: 0,
              backgroundColor: "white",
            }}
          >
            <Header
              onDone={closeModal}
              onBack={closeModal}
              title={remote.name}
            />
          </div>
          <div style={{ backgroundColor: "#f2f2f2", paddingTop: "2vh" }}>
            <Row
              style={{
                justifyContent: "space-around",
                margin: "2vh 0vw",
              }}
              className={"g-0"}
            >
              <Col>
                <center>
                  <RemoteButton
                    onPress={() => {
                      powerClicked();
                    }}
                    image={CustomRemoteButtons["Power On"].image}
                    name={CustomRemoteButtons["Power On"].label}
                    tintColor={CustomRemoteButtons["Power On"].tintColor}
                  />
                </center>
              </Col>
							<Col>
                <center>
                  <RemoteButton
                    onPress={() => {
                      powerClicked();
                    }}
                    image={CustomRemoteButtons["Power Off"].image}
                    name={CustomRemoteButtons["Power Off"].label}
                    tintColor={CustomRemoteButtons["Power Off"].tintColor}
                  />
                </center>
              </Col>
            </Row>
            {custom_button_groups.map((group, index) => (
              <Row
                style={{
                  justifyContent: "space-around",
                  margin: "2vh 0vw",
                }}
                className={"g-0"}
              >
                {group.map((button) => (
                  <Col>
                    <center>
                      <RemoteButton
                        image={button.image}
                        key={button._id}
                        onPress={() => {
                          sendCode(button);
                        }}
                        name={button.name}
                        tintColor={button.tintColor}
                      />
                    </center>
                  </Col>
                ))}
              </Row>
            ))}
          </div>
        </ReactModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  primaryColor: state.theme.primaryColor,
});
export default connect(mapStateToProps)(CustomRemote);
