import {createStore, combineReducers, applyMiddleware} from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import user from './reducers/user';
import rooms from './reducers/rooms';
import house, { selectedRoomReducer } from './reducers/house';
import theme from './reducers/theme';
import connectedDevices from './reducers/connectedDevices';
import outlet from './reducers/outlet'
import { saveTodayBarDataHouseAndRoomReducer, saveTodaySessionsReducer } from './reducers/analystic';
const persistConfig = {
	key: 'taj_webapp_aliste',
	storage,
}

const persistedReducer = persistReducer(persistConfig, combineReducers({
	user,
	outlet,
	rooms,
	house,
	theme,
	connectedDevices,
	selectedRoom:selectedRoomReducer,
	todaySessions:saveTodaySessionsReducer,
    barData:saveTodayBarDataHouseAndRoomReducer,
}))
const store = createStore(
	persistedReducer,
	applyMiddleware(thunk)
)
export const persistor = persistStore(store);
export default store;