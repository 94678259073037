import { styled } from '@mui/material/styles';
import { Tabs } from '@mui/material';

const StyledTabs = styled((props) =>{
	
	return(
	<Tabs
		{...props}
		TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
	/>
)})({
	'& .MuiTabs-indicator': {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: 'transparent',
	},
	'& .MuiTabs-indicatorSpan': {
		maxWidth: 50,
		width: '100%',
		height: 12,
		backgroundColor: '#5276f5',
	},
});

export default StyledTabs;