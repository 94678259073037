export const RoomActions = {
	LOAD: 'ROOM_ACTIONS_LOAD',
}

export const HouseActions = {
	LOAD: 'HOUSE_ACTIONS_LOAD',
}

export const OutletActions = {
	LOAD: 'OUTLET_ACTIONS_LOAD',
}
export const TimActions = {
	LOAD: 'TIM_ACTIONS_LOAD',
}

export const ConnectedDeviceActions = {
	UPDATE_ALL: 'CONNECTED_DEVICE_ACTIONS_UPDATE_ALL',
	SYNC_CONNECTED: 'CONNECTED_DEVICE_ACTIONS_SYNC_CONNECTED',
	SYNC_DISCONNECTED: 'CONNECTED_DEVICE_ACTIONS_SYNC_DISCONNECTED',
	MQTT_SYNC_CONNECTED: 'CONNECTED_MQTT_DEVICE_ACTIONS_SYNC_CONNECTED',
	MQTT_SYNC_DISCONNECTED: 'CONNECTED_MQTT_DEVICE_ACTIONS_SYNC_DISCONNECTED',
	NOVA_CONNECTED: 'CONNECTED_DEVICE_ACTIONS_NOVA_CONNECTED',
	NOVA_DISCONNECTED: 'CONNECTED_DEVICE_ACTIONS_NOVA_DISCONNECTED',
}

export const SocketUpdateActions = {
	SYNC_STATES_SYNC: 'sync_states_sync',
	SYNC_STATE_UPDATE: 'sync_state_update',
	NOVA_POWER_UPDATE: 'nova_power_update',
};

export const UserActions = {
	LOAD: 'USER_ACTIONS_LOAD',
	LOAD_LINK:"USER_ACTIONS_LINK",
	LOGOUT: 'USER_ACTIONS_LOGOUT',
	UPDATE_LOGIN_TYPE: 'USER_ACTIONS_UPDATE_LOGIN_TYPE',
}

export const SelectedRoomAction =  {
	LOAD:"LOAD_SELECTED_ROOM",
}

export const ThemeActions = {
	LOAD: 'THEME_ACTIONS_LOAD'
}
export const analytic = {
	SAVE_TODAY_SESSIONS:"SAVE_TODAY_SESSIONS",
	SAVE_TODAY_BAR_DATA_HOUSE_ROOM:"SAVE_TODAY_BAR_DATA_HOUSE_ROOM"
  }
