export const channels = [
  {
    type: 'General Entertainment',
    name: '& TV',
    url: '/wiki/%26_TV',
    id: 1,
    image: require('../assets/channels/1_&_TV.jpg'),
  },
  {
    type: 'General Entertainment',
    name: 'Abzy Cool',
    url: '/wiki/Abzy_Cool',
    id: 2,
    image: require('../assets/channels/2_Abzy_Cool.jpg'),
  },
  {
    type: 'General Entertainment',
    name: 'Azaad',
    url: '/wiki/Azaad_(TV_channel)',
    id: 3,
    image: require('../assets/channels/3_Azaad.png'),
  },
  {
    type: 'General Entertainment',
    name: 'Big Magic',
    url: '/wiki/Big_Magic',
    id: 4,
    image: require('../assets/channels/4_Big_Magic.png'),
  },
  {
    type: 'General Entertainment',
    name: 'Colors Rishtey',
    url: '/wiki/Colors_Rishtey',
    id: 5,
    image: require('../assets/channels/5_Colors_Rishtey.png'),
  },
  {
    type: 'General Entertainment',
    name: 'Colors TV',
    url: '/wiki/Colors_TV',
    id: 6,
    image: require('../assets/channels/6_Colors_TV.jpg'),
  },
  {
    type: 'General Entertainment',
    name: 'Dangal TV',
    url: '/wiki/Dangal_TV',
    id: 7,
    image: require('../assets/channels/7_Dangal_TV.png'),
  },
  {
    type: 'General Entertainment',
    name: 'DD Bharati',
    url: '/wiki/DD_Bharati',
    id: 8,
    image: require('../assets/channels/8_DD_Bharati.png'),
  },
  {
    type: 'General Entertainment',
    name: 'DD National',
    url: '/wiki/DD_National',
    id: 9,
    image: require('../assets/channels/9_DD_National.png'),
  },
  {
    type: 'General Entertainment',
    name: 'DD Retro',
    url: '/wiki/DD_Retro',
    id: 10,
    image: require('../assets/channels/10_DD_Retro.png'),
  },
  {
    type: 'General Entertainment',
    name: 'Firangi',
    url: '/wiki/Firangi_(TV_channel)',
    id: 11,
    image: require('../assets/channels/11_Firangi.png'),
  },
  {
    type: 'General Entertainment',
    name: 'Investigation Discovery',
    url: '/wiki/Investigation_Discovery_(Indian_TV_Channel)',
    id: 12,
    image: require('../assets/channels/12_Investigation_Discovery.png'),
  },
  {
    type: 'General Entertainment',
    name: 'Ishara TV',
    url: '/wiki/Ishara_TV',
    id: 13,
    image: require('../assets/channels/13_Ishara_TV.png'),
  },
  {
    type: 'General Entertainment',
    name: 'Pasand TV',
    url: '/wiki/Pasand_(TV_channel)',
    id: 14,
    image: require('../assets/channels/14_Pasand_TV.png'),
  },
  {
    type: 'General Entertainment',
    name: 'Shemaroo TV',
    url: '/wiki/Shemaroo_TV',
    id: 15,
    image: require('../assets/channels/15_Shemaroo_TV.png'),
  },
  {
    type: 'General Entertainment',
    name: 'Sony Entertainment Television',
    url: '/wiki/Sony_Entertainment_Television',
    id: 16,
    image: require('../assets/channels/16_Sony_Entertainment_Television.png'),
  },
  {
    type: 'General Entertainment',
    name: 'Sony Pal',
    url: '/wiki/Sony_Pal',
    id: 17,
    image: require('../assets/channels/17_Sony_Pal.png'),
  },
  {
    type: 'General Entertainment',
    name: 'Sony Sab',
    url: '/wiki/Sony_Sab',
    id: 18,
    image: require('../assets/channels/18_Sony_Sab.png'),
  },
  {
    type: 'General Entertainment',
    name: 'Star Bharat',
    url: '/wiki/Star_Bharat',
    id: 19,
    image: require('../assets/channels/19_Star_Bharat.jpg'),
  },
  {
    type: 'General Entertainment',
    name: 'Star Plus',
    url: '/wiki/Star_Plus',
    id: 20,
    image: require('../assets/channels/20_Star_Plus.png'),
  },
  {
    type: 'General Entertainment',
    name: 'Star Utsav',
    url: '/wiki/Star_Utsav',
    id: 21,
    image: require('../assets/channels/21_Star_Utsav.png'),
  },
  {
    type: 'General Entertainment',
    name: 'The Q',
    url: '/wiki/The_Q_(TV_channel)',
    id: 22,
    image: require('../assets/channels/22_The_Q.png'),
  },
  {
    type: 'General Entertainment',
    name: 'Zee Anmol',
    url: '/wiki/Zee_Anmol',
    id: 23,
    image: require('../assets/channels/23_Zee_Anmol.png'),
  },
  {
    type: 'General Entertainment',
    name: 'Zee TV',
    url: '/wiki/Zee_TV',
    id: 24,
    image: require('../assets/channels/24_Zee_TV.png'),
  },
  {
    type: 'General Entertainment',
    name: 'Sun Hindi',
    url: '/w/index.php?title=Sun_Hindi&action=edit&redlink=1',
    id: 25,
    image: require('../assets/channels/25_Sun_Hindi.png'),
  },
  {
    type: 'General Entertainment',
    name: '9X',
    url: '/wiki/9X_(TV_channel)',
    id: 26,
    image: require('../assets/channels/26_9X.png'),
  },
  {
    type: 'General Entertainment',
    name: 'DD Metro',
    url: '/wiki/DD_Metro',
    id: 27,
    image: require('../assets/channels/27_DD_Metro.jpg'),
  },
  {
    type: 'General Entertainment',
    name: 'Discovery Jeet',
    url: '/wiki/Discovery_Jeet',
    id: 28,
    image: require('../assets/channels/28_Discovery_Jeet.png'),
  },
  {
    type: 'General Entertainment',
    name: 'Imagine TV',
    url: '/wiki/Imagine_TV',
    id: 29,
    image: require('../assets/channels/29_Imagine_TV.png'),
  },
  {
    type: 'General Entertainment',
    name: 'Life OK',
    url: '/wiki/Life_OK',
    id: 30,
    image: require('../assets/channels/30_Life_OK.png'),
  },
  {
    type: 'General Entertainment',
    name: 'Metro Gold',
    url: '/wiki/Metro_Gold',
    id: 31,
    image: require('../assets/channels/31_Metro_Gold.jpg'),
  },
  {
    type: 'General Entertainment',
    name: 'Real',
    url: '/wiki/Real_(TV_channel)',
    id: 32,
    image: require('../assets/channels/32_Real.png'),
  },
  {
    type: 'General Entertainment',
    name: 'Sahara One',
    url: '/wiki/Sahara_One',
    id: 33,
    image: require('../assets/channels/33_Sahara_One.png'),
  },
  {
    type: 'General Entertainment',
    name: 'Star One',
    url: '/wiki/Star_One_(Indian_TV_channel)',
    id: 34,
    image: require('../assets/channels/34_Star_One.png'),
  },
  {
    type: 'General Entertainment',
    name: 'Zee Next',
    url: '/wiki/Zee_Next',
    id: 35,
    image: require('../assets/channels/35_Zee_Next.jpg'),
  },
  {
    type: 'General Entertainment',
    name: 'Zee Smile',
    url: '/wiki/Zee_Smile',
    id: 36,
    image: require('../assets/channels/36_Zee_Smile.jpg'),
  },
  {
    type: 'General Entertainment',
    name: 'Zindagi',
    url: '/wiki/Zindagi_(TV_channel)',
    id: 37,
    image: require('../assets/channels/37_Zindagi.png'),
  },
  {
    type: 'Movies',
    name: '& Pictures',
    url: '/wiki/%26_Pictures',
    id: 38,
    image: require('../assets/channels/38_&_Pictures.jpg'),
  },
  {
    type: 'Movies',
    name: 'Abzy Movies',
    url: '/wiki/Abzy_Movies',
    id: 39,
    image: require('../assets/channels/39_Abzy_Movies.png'),
  },
  {
    type: 'Movies',
    name: 'B4U Kadak',
    url: '/wiki/B4U_Kadak_(TV_channel)',
    id: 40,
    image: require('../assets/channels/40_B4U_Kadak.jpg'),
  },
  {
    type: 'Movies',
    name: 'B4U Movies',
    url: '/wiki/B4U_Movies',
    id: 41,
    image: require('../assets/channels/41_B4U_Movies.jpg'),
  },
  {
    type: 'Movies',
    name: 'Bflix Movies',
    url: '/wiki/Bflix_Movies',
    id: 42,
    image: require('../assets/channels/42_Bflix_Movies.png'),
  },
  {
    type: 'Movies',
    name: 'Cinema TV India',
    url: '/wiki/Cinema_TV_India',
    id: 43,
    image: require('../assets/channels/43_Cinema_TV_India.png'),
  },
  {
    type: 'Movies',
    name: 'Colors Cineplex',
    url: '/wiki/Colors_Cineplex',
    id: 44,
    image: require('../assets/channels/44_Colors_Cineplex.png'),
  },
  {
    type: 'Movies',
    name: 'Colors Cineplex Bollywood',
    url: '/wiki/Colors_Cineplex_Bollywood',
    id: 45,
    image: require('../assets/channels/45_Colors_Cineplex_Bollywood.png'),
  },
  {
    type: 'Movies',
    name: 'Dhinchaak',
    url: '/wiki/Dhinchaak_TV',
    id: 46,
    image: require('../assets/channels/46_Dhinchaak.png'),
  },
  {
    type: 'Movies',
    name: 'Dhinchaak 2',
    url: '/wiki/Dhinchaak_2',
    id: 47,
    image: require('../assets/channels/47_Dhinchaak_2.jpg'),
  },
  {
    type: 'Movies',
    name: 'Box Cinema',
    url: '/wiki/Box_Cinema',
    id: 48,
    image: require('../assets/channels/48_Box_Cinema.jpg'),
  },
  {
    type: 'Movies',
    name: 'Enterr 10 Movies',
    url: '/wiki/Enterr_10_Movies',
    id: 49,
    image: require('../assets/channels/49_Enterr_10_Movies.png'),
  },
  {
    type: 'Movies',
    name: 'Filmy',
    url: '/wiki/Filmy',
    id: 50,
    image: require('../assets/channels/50_Filmy.png'),
  },
  {
    type: 'Movies',
    name: 'Maha Movie',
    url: '/wiki/Maha_Movie',
    id: 51,
    image: require('../assets/channels/51_Maha_Movie.png'),
  },
  {
    type: 'Movies',
    name: 'Manoranjan TV',
    url: '/wiki/Manoranjan_TV',
    id: 52,
    image: require('../assets/channels/52_Manoranjan_TV.png'),
  },
  {
    type: 'Movies',
    name: 'Movie Plus',
    url: '/wiki/Movie_Plus_(TV_channel)',
    id: 53,
    image: require('../assets/channels/53_Movie_Plus.png'),
  },
  {
    type: 'Movies',
    name: 'Rishtey Cineplex',
    url: '/wiki/Rishtey_Cineplex',
    id: 54,
    image: require('../assets/channels/54_Rishtey_Cineplex.jpeg'),
  },
  {
    type: 'Movies',
    name: 'Sony Max',
    url: '/wiki/Sony_Max',
    id: 55,
    image: require('../assets/channels/55_Sony_Max.png'),
  },
  {
    type: 'Movies',
    name: 'Sony Max 2',
    url: '/wiki/Sony_Max_2',
    id: 56,
    image: require('../assets/channels/56_Sony_Max_2.png'),
  },
  {
    type: 'Movies',
    name: 'Sony Wah',
    url: '/wiki/Sony_Wah',
    id: 57,
    image: require('../assets/channels/57_Sony_Wah.png'),
  },
  {
    type: 'Movies',
    name: 'Star Gold',
    url: '/wiki/Star_Gold',
    id: 58,
    image: require('../assets/channels/58_Star_Gold.png'),
  },
  {
    type: 'Movies',
    name: 'Star Gold 2',
    url: '/wiki/Star_Gold_2',
    id: 59,
    image: require('../assets/channels/59_Star_Gold_2.png'),
  },
  {
    type: 'Movies',
    name: 'Star Gold Select',
    url: '/wiki/Star_Gold_Select',
    id: 60,
    image: require('../assets/channels/60_Star_Gold_Select.png'),
  },
  {
    type: 'Movies',
    name: 'Star Utsav Movies',
    url: '/wiki/Star_Utsav_Movies',
    id: 61,
    image: require('../assets/channels/61_Star_Utsav_Movies.jpg'),
  },
  {
    type: 'Movies',
    name: 'WOW Cinema One',
    url: '/wiki/WOW_Cinema_One',
    id: 62,
    image: require('../assets/channels/62_WOW_Cinema_One.png'),
  },
  {
    type: 'Movies',
    name: 'Zee Action',
    url: '/wiki/Zee_Action',
    id: 63,
    image: require('../assets/channels/63_Zee_Action.png'),
  },
  {
    type: 'Movies',
    name: 'Zee Anmol Cinema',
    url: '/wiki/Zee_Anmol_Cinema',
    id: 64,
    image: require('../assets/channels/64_Zee_Anmol_Cinema.png'),
  },
  {
    type: 'Movies',
    name: 'Zee Bollywood',
    url: '/wiki/Zee_Bollywood',
    id: 65,
    image: require('../assets/channels/65_Zee_Bollywood.png'),
  },
  {
    type: 'Movies',
    name: 'Zee Cinema',
    url: '/wiki/Zee_Cinema',
    id: 66,
    image: require('../assets/channels/66_Zee_Cinema.png'),
  },
  {
    type: 'Movies',
    name: 'Zee Classic',
    url: '/wiki/Zee_Classic_(TV_Channel)',
    id: 67,
    image: require('../assets/channels/67_Zee_Classic.png'),
  },
  {
    type: 'Movies',
    name: 'Housefull Action',
    url: '/wiki/Housefull_Action',
    id: 68,
    image: require('../assets/channels/68_Housefull_Action.png'),
  },
  {
    type: 'Movies',
    name: 'Housefull Movies',
    url: '/wiki/Housefull_Movies_(TV_channel)',
    id: 69,
    image: require('../assets/channels/69_Housefull_Movies.jpg'),
  },
  {
    type: 'Movies',
    name: 'Movies OK',
    url: '/wiki/Movies_OK',
    id: 70,
    image: require('../assets/channels/70_Movies_OK.png'),
  },
  {
    type: 'Movies',
    name: 'RT Movies',
    url: '/wiki/RT_Movies',
    id: 71,
    image: require('../assets/channels/71_RT_Movies.png'),
  },
  {
    type: 'Music',
    name: '9x Jalwa',
    url: '/wiki/9x_Jalwa',
    id: 72,
    image: require('../assets/channels/72_9x_Jalwa.png'),
  },
  {
    type: 'Music',
    name: '9XM',
    url: '/wiki/9XM',
    id: 73,
    image: require('../assets/channels/73_9XM.jpg'),
  },
  {
    type: 'Music',
    name: 'B4U Music',
    url: '/wiki/B4U_Music',
    id: 74,
    image: require('../assets/channels/74_B4U_Music.png'),
  },
  {
    type: 'Music',
    name: 'Mastiii',
    url: '/wiki/Mastiii',
    id: 75,
    image: require('../assets/channels/75_Mastiii.jpg'),
  },
  {
    type: 'Music',
    name: 'MTV Beats',
    url: '/wiki/MTV_Beats',
    id: 76,
    image: require('../assets/channels/76_MTV_Beats.png'),
  },
  {
    type: 'Music',
    name: 'Music India',
    url: '/wiki/Music_India',
    id: 77,
    image: require('../assets/channels/77_Music_India.png'),
  },
  {
    type: 'Music',
    name: 'ShowBox',
    url: '/wiki/ShowBox',
    id: 78,
    image: require('../assets/channels/78_ShowBox.png'),
  },
  {
    type: 'Music',
    name: 'Vh1',
    url: '/wiki/Vh1',
    id: 79,
    image: require('../assets/channels/79_Vh1.png'),
  },
  {
    type: 'Music',
    name: 'Zoom',
    url: '/wiki/Zoom_(Indian_TV_channel)',
    id: 80,
    image: require('../assets/channels/80_Zoom.jpg'),
  },
  {
    type: 'Music',
    name: 'Imagine Showbiz',
    url: '/wiki/Imagine_Showbiz',
    id: 81,
    image: require('../assets/channels/81_Imagine_Showbiz.png'),
  },
  {
    type: 'Music',
    name: 'Manoranjan Music',
    url: '/wiki/Manoranjan_Music',
    id: 82,
    image: require('../assets/channels/82_Manoranjan_Music.png'),
  },
  {
    type: 'Music',
    name: 'Sony Mix',
    url: '/wiki/Sony_Mix',
    id: 83,
    image: require('../assets/channels/83_Sony_Mix.png'),
  },
  {
    type: 'Music',
    name: 'Sony Rox HD',
    url: '/wiki/Sony_Rox_HD',
    id: 84,
    image: require('../assets/channels/84_Sony_Rox_HD.png'),
  },
  {
    type: 'Youth',
    name: 'E24',
    url: '/wiki/E24_(TV_channel)',
    id: 85,
    image: require('../assets/channels/85_E24.png'),
  },
  {
    type: 'Youth',
    name: 'MTV',
    url: '/wiki/MTV_(Indian_TV_channel)',
    id: 86,
    image: require('../assets/channels/86_MTV.png'),
  },
  {
    type: 'Youth',
    name: 'Zing',
    url: '/wiki/Zing_(TV_channel)',
    id: 87,
    image: require('../assets/channels/87_Zing.png'),
  },
  {
    type: 'Youth',
    name: 'Bindass',
    url: '/wiki/Bindass',
    id: 88,
    image: require('../assets/channels/88_Bindass.png'),
  },
  {
    type: 'Youth',
    name: 'Channel V',
    url: '/wiki/Channel_V_India',
    id: 89,
    image: require('../assets/channels/89_Channel_V.jpg'),
  },
  {
    type: 'Youth',
    name: 'MTV Indies',
    url: '/wiki/MTV_Indies',
    id: 90,
    image: require('../assets/channels/90_MTV_Indies.png'),
  },
  {
    type: 'Youth',
    name: 'UTV Stars',
    url: '/wiki/UTV_Stars',
    id: 91,
    image: require('../assets/channels/91_UTV_Stars.png'),
  },
  {
    type: 'Youth',
    name: 'Zee ETC Bollywood',
    url: '/wiki/Zee_ETC_Bollywood',
    id: 92,
    image: require('../assets/channels/92_Zee_ETC_Bollywood.png'),
  },
  {
    type: 'Kids',
    name: 'Cartoon Network',
    url: '/wiki/Cartoon_Network_(India)',
    id: 93,
    image: require('../assets/channels/93_Cartoon_Network.png'),
  },
  {
    type: 'Kids',
    name: 'Discovery Kids',
    url: '/wiki/Discovery_Kids_(India)',
    id: 94,
    image: require('../assets/channels/94_Discovery_Kids.png'),
  },
  {
    type: 'Kids',
    name: 'Disney Channel',
    url: '/wiki/Disney_Channel_(India)',
    id: 95,
    image: require('../assets/channels/95_Disney_Channel.png'),
  },
  {
    type: 'Kids',
    name: 'Disney Junior',
    url: '/wiki/Disney_Junior_(India)',
    id: 96,
    image: require('../assets/channels/96_Disney_Junior.png'),
  },
  {
    type: 'Kids',
    name: 'ETV Bal Bharat',
    url: '/wiki/ETV_Bal_Bharat',
    id: 97,
    image: require('../assets/channels/97_ETV_Bal_Bharat.jpg'),
  },
  {
    type: 'Kids',
    name: 'Hungama TV',
    url: '/wiki/Hungama_TV',
    id: 98,
    image: require('../assets/channels/98_Hungama_TV.png'),
  },
  {
    type: 'Kids',
    name: 'Maha Cartoon TV',
    url: '/wiki/Maha_Cartoon_TV',
    id: 99,
    image: require('../assets/channels/99_Maha_Cartoon_TV.jpg'),
  },
  {
    type: 'Kids',
    name: 'Marvel HQ (India)',
    url: '/wiki/Marvel_HQ_(India)',
    id: 100,
    image: require('../assets/channels/100_Marvel_HQ_(India).png'),
  },
  {
    type: 'Kids',
    name: 'Nick Jr.',
    url: '/wiki/Nick_Jr_India',
    id: 101,
    image: require('../assets/channels/101_Nick_Jr..png'),
  },
  {
    type: 'Kids',
    name: 'Nickelodeon',
    url: '/wiki/Nickelodeon_(India)',
    id: 102,
    image: require('../assets/channels/102_Nickelodeon.png'),
  },
  {
    type: 'Kids',
    name: 'Nickelodeon Sonic',
    url: '/wiki/Sonic_(TV_Channel)',
    id: 103,
    image: require('../assets/channels/103_Nickelodeon_Sonic.png'),
  },
  {
    type: 'Kids',
    name: 'Pogo TV',
    url: '/wiki/Pogo_TV',
    id: 104,
    image: require('../assets/channels/104_Pogo_TV.png'),
  },
  {
    type: 'Kids',
    name: 'Sony Yay',
    url: '/wiki/Sony_YAY!',
    id: 105,
    image: require('../assets/channels/105_Sony_Yay.png'),
  },
  {
    type: 'Kids',
    name: 'Animax',
    url: '/wiki/Animax_(India)',
    id: 106,
    image: require('../assets/channels/106_Animax.png'),
  },
  {
    type: 'Kids',
    name: 'Marvel HQ',
    url: '/wiki/Marvel_HQ_(India)',
    id: 107,
    image: require('../assets/channels/107_Marvel_HQ.png'),
  },
  {
    type: 'Kids',
    name: 'Disney XD',
    url: '/wiki/Disney_XD_India',
    id: 108,
    image: require('../assets/channels/108_Disney_XD.png'),
  },
  {
    type: 'Kids',
    name: 'Jetix',
    url: '/wiki/Jetix',
    id: 109,
    image: require('../assets/channels/109_Jetix.png'),
  },
  {
    type: 'Kids',
    name: 'Spacetoon',
    url: '/wiki/Spacetoon_India',
    id: 110,
    image: require('../assets/channels/110_Spacetoon.png'),
  },
  {
    type: 'Kids',
    name: 'Teen Nick',
    url: '/wiki/Teen_Nick_India',
    id: 111,
    image: require('../assets/channels/111_Teen_Nick.png'),
  },
  {
    type: 'Kids',
    name: 'Toon Disney',
    url: '/wiki/Toon_Disney',
    id: 112,
    image: require('../assets/channels/112_Toon_Disney.png'),
  },
  {
    type: 'Kids',
    name: 'Toonami',
    url: '/wiki/Toonami_(India)',
    id: 113,
    image: require('../assets/channels/113_Toonami.png'),
  },
  {
    type: 'Kids',
    name: 'ZeeQ',
    url: '/wiki/ZeeQ',
    id: 114,
    image: require('../assets/channels/114_ZeeQ.png'),
  },
  {
    type: 'Infotainment',
    name: 'Animal Planet',
    url: '/wiki/Animal_Planet',
    id: 115,
    image: require('../assets/channels/115_Animal_Planet.png'),
  },
  {
    type: 'Infotainment',
    name: 'DD Kisan',
    url: '/wiki/DD_Kisan',
    id: 116,
    image: require('../assets/channels/116_DD_Kisan.png'),
  },
  {
    type: 'Infotainment',
    name: 'Discovery',
    url: '/wiki/Discovery_Channel_(Indian_TV_channel)',
    id: 117,
    image: require('../assets/channels/117_Discovery.png'),
  },
  {
    type: 'Infotainment',
    name: 'Discovery Science',
    url: '/wiki/Discovery_Science_(Indian_TV_channel)',
    id: 118,
    image: require('../assets/channels/118_Discovery_Science.png'),
  },
  {
    type: 'Infotainment',
    name: 'Discovery Turbo',
    url: '/wiki/Discovery_Turbo',
    id: 119,
    image: require('../assets/channels/119_Discovery_Turbo.png'),
  },
  {
    type: 'Infotainment',
    name: 'Epic',
    url: '/wiki/EPIC_(TV_channel)',
    id: 120,
    image: require('../assets/channels/120_Epic.png'),
  },
  {
    type: 'Infotainment',
    name: 'Fox Life',
    url: '/wiki/Fox_Life',
    id: 121,
    image: require('../assets/channels/121_Fox_Life.png'),
  },
  {
    type: 'Infotainment',
    name: 'Green TV',
    url: '/wiki/Green_TV_India',
    id: 122,
    image: require('../assets/channels/122_Green_TV.jpg'),
  },
  {
    type: 'Infotainment',
    name: 'History TV18',
    url: '/wiki/History_TV18',
    id: 123,
    image: require('../assets/channels/123_History_TV18.png'),
  },
  {
    type: 'Infotainment',
    name: 'National Geographic',
    url: '/wiki/National_Geographic_Channel_(India)',
    id: 124,
    image: require('../assets/channels/124_National_Geographic.png'),
  },
  {
    type: 'Infotainment',
    name: 'Nat Geo Wild',
    url: '/wiki/Nat_Geo_Wild',
    id: 125,
    image: require('../assets/channels/125_Nat_Geo_Wild.png'),
  },
  {
    type: 'Infotainment',
    name: 'NDTV Good Times',
    url: '/wiki/NDTV_Good_Times',
    id: 126,
    image: require('../assets/channels/126_NDTV_Good_Times.png'),
  },
  {
    type: 'Infotainment',
    name: 'Sony BBC Earth',
    url: '/wiki/Sony_BBC_Earth',
    id: 127,
    image: require('../assets/channels/127_Sony_BBC_Earth.png'),
  },
  {
    type: 'Infotainment',
    name: 'TLC',
    url: '/wiki/TLC_(India)',
    id: 128,
    image: require('../assets/channels/128_TLC.png'),
  },
  {
    type: 'Infotainment',
    name: 'Travelxp',
    url: '/wiki/Travelxp',
    id: 129,
    image: require('../assets/channels/129_Travelxp.png'),
  },
  {
    type: 'Infotainment',
    name: 'Zee Zest',
    url: '/wiki/Zee_Zest',
    id: 130,
    image: require('../assets/channels/130_Zee_Zest.jpeg'),
  },
  {
    type: 'Infotainment',
    name: 'Fyi TV18',
    url: '/wiki/FYI_(Southeast_Asian_TV_channel)',
    id: 131,
    image: require('../assets/channels/131_Fyi_TV18.png'),
  },
  {
    type: 'Infotainment',
    name: 'Living Foodz',
    url: '/wiki/Living_Foodz',
    id: 132,
    image: require('../assets/channels/132_Living_Foodz.jpeg'),
  },
  {
    type: 'Infotainment',
    name: 'Living Travelz',
    url: '/wiki/Living_Travelz',
    id: 133,
    image: require('../assets/channels/133_Living_Travelz.png'),
  },
  {
    type: 'Infotainment',
    name: 'Nat Geo People',
    url: '/wiki/Nat_Geo_People',
    id: 134,
    image: require('../assets/channels/134_Nat_Geo_People.png'),
  },
  {
    type: 'News',
    name: 'A1 TV',
    url: '/wiki/A1_TV_channel_(India)',
    id: 135,
    image: require('../assets/channels/135_A1_TV.png'),
  },
  {
    type: 'News',
    name: 'Aaj Tak',
    url: '/wiki/Aaj_Tak',
    id: 136,
    image: require('../assets/channels/136_Aaj_Tak.png'),
  },
  {
    type: 'News',
    name: 'ABP News',
    url: '/wiki/ABP_News',
    id: 137,
    image: require('../assets/channels/137_ABP_News.png'),
  },
  {
    type: 'News',
    name: 'Aryan TV',
    url: '/wiki/Aryan_TV',
    id: 138,
    image: require('../assets/channels/138_Aryan_TV.png'),
  },
  {
    type: 'News',
    name: 'Azad News',
    url: '/wiki/Azad_News',
    id: 139,
    image: require('../assets/channels/139_Azad_News.png'),
  },
  {
    type: 'News',
    name: 'Bharat Samachar',
    url: '/wiki/Bharat_Samachar',
    id: 140,
    image: require('../assets/channels/140_Bharat_Samachar.jpg'),
  },
  {
    type: 'News',
    name: 'CNBC Awaaz',
    url: '/wiki/CNBC_Awaaz',
    id: 141,
    image: require('../assets/channels/141_CNBC_Awaaz.png'),
  },
  {
    type: 'News',
    name: 'DD News',
    url: '/wiki/DD_News',
    id: 142,
    image: require('../assets/channels/142_DD_News.png'),
  },
  {
    type: 'News',
    name: 'Good News Today',
    url: '/wiki/Aajtak_Tez#Good_News_Today',
    id: 143,
    image: require('../assets/channels/143_Good_News_Today.png'),
  },
  {
    type: 'News',
    name: 'IBC24',
    url: '/wiki/IBC24',
    id: 144,
    image: require('../assets/channels/144_IBC24.png'),
  },
  {
    type: 'News',
    name: 'India News',
    url: '/wiki/India_News',
    id: 145,
    image: require('../assets/channels/145_India_News.png'),
  },
  {
    type: 'News',
    name: 'India TV',
    url: '/wiki/India_TV',
    id: 146,
    image: require('../assets/channels/146_India_TV.png'),
  },
  {
    type: 'News',
    name: 'Jan TV',
    url: '/wiki/Jan_TV',
    id: 147,
    image: require('../assets/channels/147_Jan_TV.png'),
  },
  {
    type: 'News',
    name: 'Janta TV',
    url: '/wiki/Janta_TV',
    id: 148,
    image: require('../assets/channels/148_Janta_TV.png'),
  },
  {
    type: 'News',
    name: 'Khabar Bharati',
    url: '/wiki/Khabar_Bharati',
    id: 149,
    image: require('../assets/channels/149_Khabar_Bharati.png'),
  },
  {
    type: 'News',
    name: 'Live India',
    url: '/wiki/Live_India',
    id: 150,
    image: require('../assets/channels/150_Live_India.jpg'),
  },
  {
    type: 'News',
    name: 'Naxatra News Hindi',
    url: '/wiki/Naxatra_News_Hindi',
    id: 151,
    image: require('../assets/channels/151_Naxatra_News_Hindi.png'),
  },
  {
    type: 'News',
    name: 'National Dastak',
    url: '/wiki/National_Dastak',
    id: 152,
    image: require('../assets/channels/152_National_Dastak.jpg'),
  },
  {
    type: 'News',
    name: 'National Voice',
    url: '/wiki/National_Voice',
    id: 153,
    image: require('../assets/channels/153_National_Voice.jpg'),
  },
  {
    type: 'News',
    name: 'NDTV India',
    url: '/wiki/NDTV_India',
    id: 154,
    image: require('../assets/channels/154_NDTV_India.png'),
  },
  {
    type: 'News',
    name: 'News 24',
    url: '/wiki/News_24_(India)',
    id: 155,
    image: require('../assets/channels/155_News_24.png'),
  },
  {
    type: 'News',
    name: 'News Nation',
    url: '/wiki/News_Nation',
    id: 156,
    image: require('../assets/channels/156_News_Nation.jpg'),
  },
  {
    type: 'News',
    name: 'News Express',
    url: '/wiki/News_Express',
    id: 157,
    image: require('../assets/channels/157_News_Express.png'),
  },
  {
    type: 'News',
    name: 'News World India',
    url: '/wiki/News_World_India',
    id: 158,
    image: require('../assets/channels/158_News_World_India.jpg'),
  },
  {
    type: 'News',
    name: 'News18 India',
    url: '/wiki/News18_India',
    id: 159,
    image: require('../assets/channels/159_News18_India.png'),
  },
  {
    type: 'News',
    name: 'OK India',
    url: '/wiki/OK_India',
    id: 160,
    image: require('../assets/channels/160_OK_India.png'),
  },
  {
    type: 'News',
    name: 'R9 TV',
    url: '/wiki/R9_TV',
    id: 161,
    image: require('../assets/channels/161_R9_TV.png'),
  },
  {
    type: 'News',
    name: 'Republic Bharat',
    url: '/wiki/Republic_Bharat',
    id: 162,
    image: require('../assets/channels/162_Republic_Bharat.png'),
  },
  {
    type: 'News',
    name: 'Sadhna News',
    url: '/wiki/Sadhna_News',
    id: 163,
    image: require('../assets/channels/163_Sadhna_News.png'),
  },
  {
    type: 'News',
    name: 'Sahara Samay',
    url: '/wiki/Sahara_Samay',
    id: 164,
    image: require('../assets/channels/164_Sahara_Samay.png'),
  },
  {
    type: 'News',
    name: 'Sansad TV',
    url: '/wiki/Sansad_TV',
    id: 165,
    image: require('../assets/channels/165_Sansad_TV.png'),
  },
  {
    type: 'News',
    name: 'Sudarshan News',
    url: '/wiki/Sudarshan_News',
    id: 166,
    image: require('../assets/channels/166_Sudarshan_News.png'),
  },
  {
    type: 'News',
    name: 'Surya Samachar',
    url: '/wiki/Surya_Samachar',
    id: 167,
    image: require('../assets/channels/167_Surya_Samachar.png'),
  },
  {
    type: 'News',
    name: 'Total TV',
    url: '/wiki/Total_TV_(India)',
    id: 168,
    image: require('../assets/channels/168_Total_TV.png'),
  },
  {
    type: 'News',
    name: 'TV 24',
    url: '/wiki/TV24_(India)',
    id: 169,
    image: require('../assets/channels/169_TV_24.png'),
  },
  {
    type: 'News',
    name: 'Zee Business',
    url: '/wiki/Zee_Business',
    id: 170,
    image: require('../assets/channels/170_Zee_Business.png'),
  },
  {
    type: 'News',
    name: 'Zee Hindustan',
    url: '/wiki/Zee_Hindustan',
    id: 171,
    image: require('../assets/channels/171_Zee_Hindustan.png'),
  },
  {
    type: 'News',
    name: 'Zee News',
    url: '/wiki/Zee_News',
    id: 172,
    image: require('../assets/channels/172_Zee_News.png'),
  },
  {
    type: 'News',
    name: 'ABP Ganga',
    url: '/wiki/ABP_Ganga',
    id: 173,
    image: require('../assets/channels/173_ABP_Ganga.png'),
  },
  {
    type: 'News',
    name: 'First India News Rajasthan',
    url: '/wiki/First_India_News_Rajasthan',
    id: 174,
    image: require('../assets/channels/174_First_India_News_Rajasthan.png'),
  },
  {
    type: 'News',
    name: 'India News Bihar Jharkhand',
    url: '/wiki/ITV_Network_(India)',
    id: 175,
    image: require('../assets/channels/175_India_News_Bihar_Jharkhand.png'),
  },
  {
    type: 'News',
    name: 'India News Haryana',
    url: '/wiki/ITV_Network_(India)',
    id: 176,
    image: require('../assets/channels/176_India_News_Haryana.png'),
  },
  {
    type: 'News',
    name: 'India News Madhya Pradesh Chhattisgarh',
    url: '/wiki/ITV_Network_(India)',
    id: 177,
    image: require('../assets/channels/177_India_News_Madhya_Pradesh_Chhattisgarh.png'),
  },
  {
    type: 'News',
    name: 'India News Rajasthan',
    url: '/wiki/ITV_Network_(India)',
    id: 178,
    image: require('../assets/channels/178_India_News_Rajasthan.png'),
  },
  {
    type: 'News',
    name: 'India News Uttar Pradesh Uttarakhand',
    url: '/wiki/ITV_Network_(India)',
    id: 179,
    image: require('../assets/channels/179_India_News_Uttar_Pradesh_Uttarakhand.png'),
  },
  {
    type: 'News',
    name: 'News18 Bihar Jharkhand',
    url: '/wiki/News18_Bihar-Jharkhand',
    id: 180,
    image: require('../assets/channels/180_News18_Bihar_Jharkhand.png'),
  },
  {
    type: 'News',
    name: 'News18 Madhya Pradesh Chhattisgarh',
    url: '/wiki/News18_India',
    id: 181,
    image: require('../assets/channels/181_News18_Madhya_Pradesh_Chhattisgarh.png'),
  },
  {
    type: 'News',
    name: 'News18 Rajasthan',
    url: '/wiki/News18_Rajasthan',
    id: 182,
    image: require('../assets/channels/182_News18_Rajasthan.png'),
  },
  {
    type: 'News',
    name: 'News18 Uttar Pradesh Uttarakhand',
    url: '/wiki/News18_Uttar_Pradesh_Uttarakhand',
    id: 183,
    image: require('../assets/channels/183_News18_Uttar_Pradesh_Uttarakhand.png'),
  },
  {
    type: 'News',
    name: 'Zee Bihar Jharkhand',
    url: '/wiki/Zee_News#Outlets',
    id: 184,
    image: require('../assets/channels/184_Zee_Bihar_Jharkhand.png'),
  },
  {
    type: 'News',
    name: 'Zee Madhya Pradesh Chhattisgarh',
    url: '/wiki/Zee_News#Outlets',
    id: 185,
    image: require('../assets/channels/185_Zee_Madhya_Pradesh_Chhattisgarh.png'),
  },
  {
    type: 'News',
    name: 'Zee Rajasthan',
    url: '/wiki/Zee_News#Outlets',
    id: 186,
    image: require('../assets/channels/186_Zee_Rajasthan.png'),
  },
  {
    type: 'News',
    name: 'Zee Uttar Pradesh Uttarakhand',
    url: '/wiki/Zee_News#Outlets',
    id: 187,
    image: require('../assets/channels/187_Zee_Uttar_Pradesh_Uttarakhand.png'),
  },
  {
    type: 'News',
    name: 'Aajtak Tez',
    url: '/wiki/Aajtak_Tez',
    id: 188,
    image: require('../assets/channels/188_Aajtak_Tez.png'),
  },
  {
    type: 'News',
    name: 'Dilli Aaj Tak',
    url: '/wiki/Dilli_Aaj_Tak',
    id: 189,
    image: require('../assets/channels/189_Dilli_Aaj_Tak.png'),
  },
  {
    type: 'News',
    name: 'Lok Sabha TV',
    url: '/wiki/Lok_Sabha_TV',
    id: 190,
    image: require('../assets/channels/190_Lok_Sabha_TV.jpg'),
  },
  {
    type: 'Religious',
    name: 'Aastha Bhajan TV',
    url: '/wiki/Aastha_Bhajan_TV',
    id: 191,
    image: require('../assets/channels/191_Aastha_Bhajan_TV.png'),
  },
  {
    type: 'Religious',
    name: 'Aastha TV',
    url: '/wiki/Aastha_TV',
    id: 192,
    image: require('../assets/channels/192_Aastha_TV.png'),
  },
  {
    type: 'Religious',
    name: 'Arihant TV',
    url: '/wiki/Arihant_TV',
    id: 193,
    image: require('../assets/channels/193_Arihant_TV.png'),
  },
  {
    type: 'Religious',
    name: 'Darshan 24',
    url: '/wiki/Darshan_24',
    id: 194,
    image: require('../assets/channels/194_Darshan_24.png'),
  },
  {
    type: 'Religious',
    name: 'Divya',
    url: '/wiki/Channel_Divya',
    id: 195,
    image: require('../assets/channels/195_Divya.jpg'),
  },
  {
    type: 'Religious',
    name: 'Hare Krsna TV',
    url: '/wiki/Hare_Krsna_TV',
    id: 196,
    image: require('../assets/channels/196_Hare_Krsna_TV.jpg'),
  },
  {
    type: 'Religious',
    name: 'Lord Buddha TV',
    url: '/wiki/Lord_Buddha_TV',
    id: 197,
    image: require('../assets/channels/197_Lord_Buddha_TV.png'),
  },
  {
    type: 'Religious',
    name: 'Mahavira TV',
    url: '/wiki/Mahavira_TV',
    id: 198,
    image: require('../assets/channels/198_Mahavira_TV.png'),
  },
  {
    type: 'Religious',
    name: 'Mangal Kalash',
    url: '/wiki/Mangal_Kalash',
    id: 199,
    image: require('../assets/channels/199_Mangal_Kalash.png'),
  },
  {
    type: 'Religious',
    name: 'Katyayani',
    url: '/wiki/Katyayani',
    id: 200,
    //image: require('../assets/channels/200_Katyayani.JPG'),
  },
  {
    type: 'Religious',
    name: 'Sadhna TV',
    url: '/wiki/Sadhna_TV',
    id: 201,
    image: require('../assets/channels/201_Sadhna_TV.jpg'),
  },
  {
    type: 'Religious',
    name: 'Sanatan TV',
    url: '/wiki/Sanatan_TV',
    id: 202,
    image: require('../assets/channels/202_Sanatan_TV.png'),
  },
  {
    type: 'Religious',
    name: 'Sanskar TV',
    url: '/wiki/Sanskar_TV',
    id: 203,
    image: require('../assets/channels/203_Sanskar_TV.png'),
  },
  {
    type: 'Religious',
    name: 'Satsang TV',
    url: '/wiki/Satsang_TV',
    id: 204,
    image: require('../assets/channels/204_Satsang_TV.png'),
  },
  {
    type: 'Religious',
    name: 'Soham TV',
    url: '/wiki/Soham_TV',
    id: 205,
    image: require('../assets/channels/205_Soham_TV.png'),
  },
  {
    type: 'Religious',
    name: 'Tulsi TV',
    url: '/wiki/Tulsi_TV',
    id: 206,
    image: require('../assets/channels/206_Tulsi_TV.png'),
  },
  {
    type: 'Religious',
    name: 'Vrinda Tv',
    url: '/wiki/Vrinda_Tv',
    id: 207,
    image: require('../assets/channels/207_Vrinda_Tv.png'),
  },
  {
    type: 'Religious',
    name: 'Vrindavan TV',
    url: '/wiki/Vrindavan_TV',
    id: 208,
    image: require('../assets/channels/208_Vrindavan_TV.png'),
  },
  {
    type: 'Sports',
    name: '1Sports',
    url: '/wiki/1Sports',
    id: 209,
    image: require('../assets/channels/209_1Sports.png'),
  },
  {
    type: 'Sports',
    name: 'DD Sports',
    url: '/wiki/DD_Sports',
    id: 210,
    image: require('../assets/channels/210_DD_Sports.png'),
  },
  {
    type: 'Sports',
    name: 'Eurosport',
    url: '/wiki/Eurosport_(Indian_TV_channel)',
    id: 211,
    image: require('../assets/channels/211_Eurosport.png'),
  },
  {
    type: 'Sports',
    name: 'Star Sports',
    url: '/wiki/Star_Sports_(Indian_TV_network)',
    id: 212,
    image: require('../assets/channels/212_Star_Sports.png'),
  },
  {
    type: 'Sports',
    name: 'Sony Six',
    url: '/wiki/Sony_Six',
    id: 213,
    image: require('../assets/channels/213_Sony_Six.png'),
  },
  {
    type: 'Sports',
    name: 'Sony Ten',
    url: '/wiki/Sony_Ten',
    id: 214,
    image: require('../assets/channels/214_Sony_Ten.png'),
  },
  {
    type: 'Sports',
    name: 'ESPN India',
    url: '/wiki/ESPN_India',
    id: 215,
    image: require('../assets/channels/215_ESPN_India.png'),
  },
  {
    type: 'Sports',
    name: 'NEO Cricket',
    url: '/wiki/NEO_Cricket',
    id: 216,
    image: require('../assets/channels/216_NEO_Cricket.jpg'),
  },
  {
    type: 'Sports',
    name: 'NEO Sports',
    url: '/wiki/NEO_Sports',
    id: 217,
    image: require('../assets/channels/217_NEO_Sports.jpg'),
  },
  {
    type: 'Sports',
    name: 'Zee Sports',
    url: '/wiki/Zee_Sports',
    id: 218,
    image: require('../assets/channels/218_Zee_Sports.png'),
  },
];
