import { SignalWifiOff } from "@mui/icons-material";

import { Col, Image, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ButtonRoomStyles } from "../config/style";
import React from "react";
import { useLongPress, LongPressDetectEvents } from "use-long-press";
import './style.css'
export default function AnimatedWrapper({ on, online, image, name, onPress, onLongPress, state, barShown }) {
  const theme = useSelector((state) => state.theme);
  
  const callback = React.useCallback(() => {
    onLongPress({data:'ddddddd'});
  }, [onLongPress]);

  const bind = useLongPress(callback, {
    onStart: (event, meta) => {},
    onFinish: (event, meta) => {},
    onCancel: (event, meta) => {},
    filterEvents: (event) => true, // All events can potentially trigger long press
    threshold: 300,
    captureEvent: true,
    cancelOnMovement: false,
    detect: LongPressDetectEvents.BOTH,
  });
  
  const { buttonPercent, buttonMargin } = ButtonRoomStyles;
  return (
    <>
      <Col
        {...bind("")}
        xs={6}
        style={{
          width: `${buttonPercent}vw`,
          height: `${buttonPercent}vw`,
          position:'relative',
          maxHeight:'200px',
          maxWidth:'200px',
          borderWidth: 2,
          borderColor: "black",
          margin: `${buttonMargin}vw ${buttonMargin}vw`,
          borderRadius: "15%",
          color: "white",
          padding: "16px 13px 6px 20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          background: !on
            ? "white"
            : `linear-gradient(to bottom right, ${theme.gradient[0]}, ${theme.gradient[1]})`,
          boxShadow: !on
            ? `rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px`
            : undefined,
        }}
        onClick={onPress}
       
      >
        <Row>
          <Col>
            <Image src={image} height={`45vw`} width={`45vw`} />
          </Col>
          {online === false && (
            <Col>
              <SignalWifiOff
                className="wifiSign"
                style={{
                  color: on ? "white" : "grey",
                
                }}
                fontSize={"inherit"}
              />
            </Col>
          )}
        </Row>
        <Row style={{ marginBottom: "15px" }}>
          <Col>
            <span style={{ color: !on ? "grey" : "white" }}>{name}</span>
          </Col>
        </Row>
        {
         barShown && online && on &&   <Col style={{
            position:'absolute',
            height:`75%`,
            right:`1.5rem`,
            display:'flex',
            alignItems:'flex-end',
            width:'9px',
            background:'rgba(255, 255, 255, 0.3)',
            borderRadius:'5px'
          }}>
            <Col style={{
            height:`${state}%`,
            width:'5px',
            opacity:'0.9',
            background:'#fff',
            borderRadius:'5px'
          }}></Col>
          </Col>
        }
      
      </Col>
    </>
  );
}

// const mapStateToProps = (state) => ({
//   theme: state.theme,
// });
// export default connect(mapStateToProps)(AnimatedWrapper);
