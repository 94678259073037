import { OutletActions } from "../actionTypes";

const initialState = {
	outlet: {},
	loginEntry: {}
};

const outlet = (state = initialState, action) => {
	const {type, payload} = action;
	switch(type) {
		case OutletActions.LOAD:
			return payload;
		default:
			return state;
	}
}

export default outlet;
