export const RemoteTypeMap = {
  TV: 0,
  AC: 1,
  Speakers: 2,
  SetTopBox: 3,
  Projector: 4,
  Custom: 6,
};


export const RemoteButtons = {
  NONE: {
    value: -1,
    label: '',
    image: require('../assets/ir/plus.png'),
    tintColor: 'grey',
  },
  OTHER: {value: 0, label: 'Other', image: null, tintColor: undefined},
  alexa: {
    value: 1,
    label: 'Alexa',
    image: require('../assets/ir/alexa.png'),
    tintColor: undefined,
  },
  blue: {
    value: 2,
    label: 'Blue',
    image: require('../assets/ir/blue.png'),
    tintColor: undefined,
  },
  circle: {
    value: 3,
    label: 'Circle',
    image: require('../assets/ir/circle.png'),
    tintColor: 'white',
  },
  down: {
    value: 4,
    label: 'Down',
    image: require('../assets/ir/down.png'),
    tintColor: 'white',
  },
  eight: {
    value: 5,
    label: 'Eight',
    image: require('../assets/ir/eight.png'),
    tintColor: 'white',
  },
  fan: {
    value: 6,
    label: 'Fan',
    image: require('../assets/ir/fan.png'),
    tintColor: 'white',
  },
  fastforward: {
    value: 7,
    label: 'Fast Forward',
    image: require('../assets/ir/fastforward.png'),
    tintColor: 'white',
  },
  five: {
    value: 8,
    label: 'Five',
    image: require('../assets/ir/five.png'),
    tintColor: 'white',
  },
  four: {
    value: 9,
    label: 'Four',
    image: require('../assets/ir/four.png'),
    tintColor: 'white',
  },
  gnow: {
    value: 10,
    label: 'Google Asst.',
    image: require('../assets/ir/gnow.png'),
    tintColor: undefined,
  },
  green: {
    value: 11,
    label: 'Green',
    image: require('../assets/ir/green.png'),
    tintColor: undefined,
  },
  home: {
    value: 12,
    label: 'Home',
    image: require('../assets/ir/home.png'),
    tintColor: 'white',
  },
  hotstar: {
    value: 13,
    label: 'Hotstar',
    image: require('../assets/ir/hotstar.png'),
    tintColor: undefined,
  },
  left: {
    value: 14,
    label: 'Left',
    image: require('../assets/ir/left.png'),
    tintColor: 'white',
  },
  menu: {
    value: 15,
    label: 'Menu',
    image: require('../assets/ir/menu.png'),
    tintColor: 'white',
  },
  minus: {
    value: 16,
    label: 'Minus',
    image: require('../assets/ir/minus.png'),
    tintColor: 'white',
  },
  mute: {
    value: 17,
    label: 'Mute',
    image: require('../assets/ir/mute.png'),
    tintColor: 'white',
  },
  netflix: {
    value: 18,
    label: 'Netflix',
    image: require('../assets/ir/netflix.png'),
    tintColor: undefined,
  },
  nine: {
    value: 19,
    label: 'Nine',
    image: require('../assets/ir/nine.png'),
    tintColor: 'white',
  },
  one: {
    value: 20,
    label: 'One',
    image: require('../assets/ir/one.png'),
    tintColor: 'white',
  },
  playpause: {
    value: 21,
    label: 'Play Pause',
    image: require('../assets/ir/playpause.png'),
    tintColor: 'white',
  },
  plus: {
    value: 22,
    label: 'Plus',
    image: require('../assets/ir/plus.png'),
    tintColor: 'white',
  },
  power: {
    value: 23,
    label: 'Power',
    image: require('../assets/ir/power.png'),
    tintColor: 'white',
  },
  power_on: {
    value: 24,
    label: 'Power On',
    image: require('../assets/ir/power.png'),
    tintColor: 'white',
  },
  power_off: {
    value: 25,
    label: 'Power Off',
    image: require('../assets/ir/power.png'),
    tintColor: 'white',
  },
  prime: {
    value: 26,
    label: 'Prime',
    image: require('../assets/ir/prime.png'),
    tintColor: undefined,
  },
  red: {
    value: 27,
    label: 'Red',
    image: require('../assets/ir/red.png'),
    tintColor: undefined,
  },
  rewind: {
    value: 28,
    label: 'Rewind',
    image: require('../assets/ir/rewind.png'),
    tintColor: 'white',
  },
  right: {
    value: 29,
    label: 'Right',
    image: require('../assets/ir/right.png'),
    tintColor: 'white',
  },
  settings: {
    value: 30,
    label: 'Settings',
    image: require('../assets/ir/settings.png'),
    tintColor: 'white',
  },
  seven: {
    value: 31,
    label: 'Seven',
    image: require('../assets/ir/seven.png'),
    tintColor: 'white',
  },
  six: {
    value: 32,
    label: 'Six',
    image: require('../assets/ir/six.png'),
    tintColor: 'white',
  },
  source: {
    value: 33,
    label: 'Source',
    image: require('../assets/ir/source.png'),
    tintColor: 'white',
  },
  swing: {
    value: 34,
    label: 'Swing',
    image: require('../assets/ir/swing.png'),
    tintColor: 'white',
  },
  three: {
    value: 35,
    label: 'Three',
    image: require('../assets/ir/three.png'),
    tintColor: 'white',
  },
  turbo: {
    value: 36,
    label: 'Turbo',
    image: require('../assets/ir/turbo.png'),
    tintColor: 'white',
  },
  two: {
    value: 37,
    label: 'Two',
    image: require('../assets/ir/two.png'),
    tintColor: 'white',
  },
  up: {
    value: 38,
    label: 'Up',
    image: require('../assets/ir/up.png'),
    tintColor: 'white',
  },
  yellow: {
    value: 39,
    label: 'Yellow',
    image: require('../assets/ir/yellow.png'),
    tintColor: undefined,
  },
  youtube: {
    value: 40,
    label: 'YouTube',
    image: require('../assets/ir/youtube.png'),
    tintColor: undefined,
  },
  zero: {
    value: 41,
    label: 'Zero',
    image: require('../assets/ir/zero.png'),
    tintColor: 'white',
  },
  channel_plus: {
    value: 42,
    label: 'Channel +',
    image: require('../assets/ir/plus.png'),
    tintColor: 'white',
  },
  volume_plus: {
    value: 43,
    label: 'Volume +',
    image: require('../assets/ir/plus.png'),
    tintColor: 'white',
  },
  channel_minus: {
    value: 44,
    label: 'Channel -',
    image: require('../assets/ir/minus.png'),
    tintColor: 'white',
  },
  volume_minus: {
    value: 45,
    label: 'Volume -',
    image: require('../assets/ir/minus.png'),
    tintColor: 'white',
  },
  center: {
    value: 46,
    label: 'Center',
    image: require('../assets/ir/circle.png'),
    tintColor: 'white',
  },
  sixteen: {
    value: 47,
    label: 'Sixteen',
    image: require('../assets/ir/16.png'),
    tintColor: 'white',
  },
  seventeen: {
    value: 48,
    label: 'Seventeen',
    image: require('../assets/ir/17.png'),
    tintColor: 'white',
  },
  eighteen: {
    value: 49,
    label: 'Eighteen',
    image: require('../assets/ir/18.png'),
    tintColor: 'white',
  },
  nineteen: {
    value: 50,
    label: 'Nineteen',
    image: require('../assets/ir/19.png'),
    tintColor: 'white',
  },
  twenty: {
    value: 51,
    label: 'Twenty',
    image: require('../assets/ir/20.png'),
    tintColor: 'white',
  },
  twenty_one: {
    value: 52,
    label: 'Twenty One',
    image: require('../assets/ir/21.png'),
    tintColor: 'white',
  },
  twenty_two: {
    value: 53,
    label: 'Twenty Two',
    image: require('../assets/ir/22.png'),
    tintColor: 'white',
  },
  twenty_three: {
    value: 54,
    label: 'Twenty Three',
    image: require('../assets/ir/23.png'),
    tintColor: 'white',
  },
  twenty_four: {
    value: 55,
    label: 'Twenty Four',
    image: require('../assets/ir/24.png'),
    tintColor: 'white',
  },
  twenty_five: {
    value: 56,
    label: 'Twenty Five',
    image: require('../assets/ir/25.png'),
    tintColor: 'white',
  },
  twenty_six: {
    value: 57,
    label: 'Twenty Six',
    image: require('../assets/ir/26.png'),
    tintColor: 'white',
  },
  twenty_seven: {
    value: 58,
    label: 'Twenty Seven',
    image: require('../assets/ir/27.png'),
    tintColor: 'white',
  },
  twenty_eight: {
    value: 59,
    label: 'Twenty Eight',
    image: require('../assets/ir/28.png'),
    tintColor: 'white',
  },
  twenty_nine: {
    value: 60,
    label: 'Twenty Nine',
    image: require('../assets/ir/29.png'),
    tintColor: 'white',
  },
  thirty: {
    value: 61,
    label: 'Thirty',
    image: require('../assets/ir/30.png'),
    tintColor: 'white',
  },
  mode: {
    value: 62,
    label: 'Mode',
    image: require('../assets/ir/source.png'),
    tintColor: 'white',
  },
  back: {
    value: 63,
    label: 'Back',
    tintColor: 'white',
    image: require('../assets/ir/back.png'),
  },
  channel: {value: 64, label: 'Channel', tintColor: 'white', image: ''},
};

export const TVRemoteButtons = {
  Power: RemoteButtons.power,
  Home: RemoteButtons.home,
  Source: RemoteButtons.source,
  'Channel +': RemoteButtons.channel_plus,
  'Channel -': RemoteButtons.channel_minus,
  'Volume +': RemoteButtons.volume_plus,
  'Volume -': RemoteButtons.volume_minus,
  Mute: RemoteButtons.mute,
  Up: RemoteButtons.up,
  Right: RemoteButtons.right,
  Down: RemoteButtons.down,
  Left: RemoteButtons.left,
  Center: RemoteButtons.center,
};

export const CustomRemoteButtons = {
  "Power On": RemoteButtons.power_on,
  "Power Off": RemoteButtons.power_off,
};

export const RemoteTypeImageMap = {
  [RemoteTypeMap.TV]: {
    on: require("../assets/ir/tv_on.png"),
    off: require("../assets/ir/tv_off.png"),
    buttons: TVRemoteButtons,
  },
  [RemoteTypeMap.AC]: {
    on: require("../assets/ir/ac_on.png"),
    off: require("../assets/ir/ac_off.png"),
  },
  [RemoteTypeMap.Speakers]: {
    on: require("../assets/ir/speaker_on.png"),
    off: require("../assets/ir/speaker_off.png"),
  },
  [RemoteTypeMap.SetTopBox]: {
    on: require("../assets/ir/set_top_box_on.png"),
    off: require("../assets/ir/set_top_box_off.png"),
  },
  [RemoteTypeMap.Projector]: {
    on: require("../assets/ir/projector_on.png"),
    off: require("../assets/ir/projector_off.png"),
  },
  [RemoteTypeMap.Custom]: {
    on: require("../assets/ir/remote_on.png"),
    off: require("../assets/ir/remote_off.png"),
    buttons: CustomRemoteButtons,
  },
};

/**
 * Generates the transmit command from the command details to send to nova to operate
 * @param {{decode_type: Number, rawLength: Number, rawData: String, value: Number, nbits: Number, repeat: Number, id}} command the command details
 */
export const generateNovaCommand = (
  command = {
    decode_type: -1,
    rawLength: 0,
    rawData: "",
    value: 0,
    nbits: 0,
    repeat: 0,
  },
  command_id = 0
) => {
  if (isNaN(command_id)) {
    command_id = 0;
  }
  const { decode_type } = command;
  let transmitCode = `${command_id},${decode_type}`;
  if (decode_type === -1) {
    const { rawData, rawLength } = command;
    transmitCode = `${transmitCode},${rawLength},${rawData}`;
  } else {
    const { value, nbits, repeat } = command;
    transmitCode = `${transmitCode},${value},${nbits},${repeat}`;
  }
  return transmitCode;
};
