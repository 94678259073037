import { Component } from "react";
import { offButtonStyle } from "../../config/style";

class CircleController extends Component {
  render() {
    const {
      upImage,
      downImage,
      leftImage,
      rightImage,
      centerImage,
      onUpClicked,
      onDownClicked,
      onLeftClicked,
      onRightClicked,
      onCenterClicked,
    } = this.props;
    return (
      <div
        id={"navigateCircle"}
        onClick={this.circleClicked}
        style={{
          height: "25vh",
          width: "25vh",
          borderRadius: "50%",
          backgroundColor: "white",
          ...offButtonStyle,
        }}
      >
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onUpClicked();
          }}
        >
          <img
            src={upImage}
            style={{ height: "10vw", width: "10vw", margin: "3.5vw" }}
            alt={'up'}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onLeftClicked();
            }}
          >
            <img
              src={leftImage}
              style={{
                height: "10vw",
                width: "10vw",
                margin: "3.5vw",
              }}
              alt={'left'}
            />
          </div>
          <div>
            <div
              style={{
                height: "9.5vh",
                width: "9.5vh",
                borderRadius: "50%",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onCenterClicked();
              }}
            >
              <img
                src={centerImage}
                style={{
                  height: "7vh",
                  width: "7vh",
                  margin: "1.25vh",
                }}
                alt={'center'}
              />
            </div>
          </div>
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onRightClicked();
            }}
          >
            <img
              src={rightImage}
              style={{
                height: "10vw",
                width: "10vw",
                margin: "3.5vw",
              }}
              alt={'right'}
            />
          </div>
        </div>
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDownClicked();
          }}
        >
          <img
            src={downImage}
            style={{ height: "10vw", width: "10vw", margin: "3.5vw" }}
            alt={'down'}
          />
        </div>
      </div>
    );
  }

  circleClicked = (e) => {
    const { onUpClicked, onRightClicked, onDownClicked, onLeftClicked } =
      this.props;
    let x = e.nativeEvent.offsetX;
    let y = e.nativeEvent.offsetY;
    let diagonal1 = 1;
    let diagonal2 = 3;

    if (x > y) {
      diagonal1 = 1;
    } else {
      diagonal1 = 2;
    }
    if (x + y > e.target.offsetWidth) {
      diagonal2 = 4;
    } else {
      diagonal2 = 3;
    }
    if (diagonal1 === 1 && diagonal2 === 3) {
      onUpClicked();
    } else if (diagonal1 === 1 && diagonal2 === 4) {
      onRightClicked();
    } else if (diagonal1 === 2 && diagonal2 === 4) {
      onDownClicked();
    } else if (diagonal1 === 2 && diagonal2 === 3) {
      onLeftClicked();
    }
  };
}


export default CircleController;