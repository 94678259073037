import React, { useEffect } from "react";
import { fetchHouseDatav2 } from "./function";
import "./Analystic.css";
import HouseAnalystic from "./HouseAnalystic";
import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PullToRefresh from "react-simple-pull-to-refresh";

function Analystic() {
  const history = useNavigate();
  useEffect(() => {
    fetchHouseDatav2();
  }, []);
  const handleRefesh = async () => {
    await fetchHouseDatav2().then((res) => {
      return true;
    });
  };

  return (
    <PullToRefresh onRefresh={handleRefesh} backgroundColor="#f3f3f3">
      <div
        style={{
          backgroundColor: "#f3f3f3",
          paddingTop: "30px",
          minHeight: "100vh",
          paddingBottom: "30px",
        }}
        className="desktop"
      >
        <Toaster />
        <div className="headerBack">
          <div onClick={() => history("/")}>
            <ArrowBackIcon style={{ fontSize: "32px" }} />
          </div>
          <div className="topHeader">Analytics</div>
        </div>
        <HouseAnalystic />
      </div>
    </PullToRefresh>
  );
}

export default Analystic;
