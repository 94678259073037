import React from 'react'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DemoGraph from './DemoGraph.js';

function Bill({ bill, yesterdayBillDifference, barData, height }) {
    return (
      <div
        className='boxContainer boxStyleAndroid activeTab billBoxContainer'
      >
        <div>
          <div 
             className='boxContainerTitle'
          >Your Bill</div>
          <div className='boxContainerSubTitle'>₹ {bill.toFixed(2)}</div>
          <div className='billBox'>
          {
            yesterdayBillDifference < 0 ? <ArrowDropUpIcon className='upDownIcon upDownIconMargin'/> : <ArrowDropDownIcon className='upIcon upDownIconMargin'/>
          }
            {/* <Icon
              type="Entypo"
              name={yesterdayBillDifference < 0?'triangle-up':'triangle-down'} 
              className={`${yesterdayBillDifference < 0 ?"upDownIcon" : "upIcon"} upDownIconMargin`}
            /> */}
            <div className={`${yesterdayBillDifference < 0 ? "upDownIcon" : "upIcon"}`}>₹ {(Math.abs(yesterdayBillDifference)!==Math.abs(bill))?Math.abs(yesterdayBillDifference).toFixed(2):0}</div>
          </div>
        </div>
        <div className='billBarContainer'>
          <DemoGraph barData={barData} height={true}/>
        </div>
      </div>
    )
  }

export default Bill