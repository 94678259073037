import React from "react";
import "../Analystic.css";
import { useSelector } from "react-redux";
import BarChart from "../BarChart";
import Bill from "../Bill";
import MostUsedAppliance from "../MostUsedAppliance";
import MostActiveUser from "../MostActiveUser";
import CustomDonut from "../CustomDonut";
import { useLocation, useNavigate } from "react-router-dom";
import AppliancesWiseBreakDown from "./AppliancesWiseBreakDown";
import HouseHealth from "../HouseHealth";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Grid } from "@mui/material";

export default function RoomAnalyticsV2(props) {
  const history = useNavigate();
  let analyticData = useSelector((state) => state.barData);
  let location = useLocation();

  const { barData, roomOnTime, roomName } = location?.state;
  return (
    <div style={{ backgroundColor: "#f3f3f3" }}>
      <div
        style={{
          paddingTop: "30px",
          minHeight: "100vh",
          paddingBottom: "30px",
        }}
        className="desktop"
      >
        <div className="scrollContent">
          <div className="headerBack">
            <div onClick={() => history("/analystic")}>
              <ArrowBackIcon style={{ fontSize: "32px" }} />
            </div>
            <div className="topHeader">{roomName}</div>
          </div>

          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <div className="boxContainer  activeTab boxStyleAndroid">
                <BarChart
                  barData={barData}
                  HouseOnTime={roomOnTime.toFixed(0)}
                  TimeDifferenceYesterday={
                    analyticData.TimeDifferenceYesterday[roomName] !== undefined
                      ? analyticData.TimeDifferenceYesterday[roomName] /
                        analyticData.TimeDifferenceYesterday[
                          `TotalDevices${roomName}`
                        ]
                      : 0
                  }
                />
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Bill
                    barData={barData}
                    bill={
                      analyticData.bill[roomName] !== undefined
                        ? analyticData.bill[roomName]
                        : 0
                    }
                    yesterdayBillDifference={
                      analyticData.BillDifferenceYesterday[roomName] !==
                      undefined
                        ? analyticData.BillDifferenceYesterday[roomName]
                        : 0
                    }
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <MostUsedAppliance name={roomName} />
                </Grid>
                <Grid item md={6} xs={6}>
                  <HouseHealth
                    type={"room"}
                    roomNames={roomName}
                    name={roomName}
                    data={analyticData}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={0}>
            <Grid item md={6}>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <MostActiveUser type={roomName} />
                </Grid>
                <Grid item md={12} xs={12}>
                  <CustomDonut
                    name={roomName}
                    pieData={analyticData?.pieDatas}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <AppliancesWiseBreakDown roomName={roomName} />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
