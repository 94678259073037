import axios from "axios";
import { weburl } from "../../config/globals";

export const outletPaymentLink = (
  details = { email: "", phone: "", city: "", name: "", outletId: "" }
) => {
  return axios
    .post(`${weburl}/v3/temporarySubscription/outlet`, details)
    .then(
      (response) => {
        const { success, message, data } = response.data;
        return {
          success,
          message,
          data,
        };
      },
      (error) => {
        const message = error?.response?.data?.message || String(error);
        return {
          success: false,
          data: { error },
          message,
        };
      }
    )
    .catch((error) => {
      return {
        success: false,
        data: {
          error,
        },
        message: String(error),
      };
    });
};
