import { ConnectedDeviceActions } from "../actionTypes";


const updateDevices = (
  payload = {
    syncs: [],
    novas: [],
  }
) => ({
  type: ConnectedDeviceActions.UPDATE_ALL,
  payload,
});

const syncConnected = (payload = "") => ({
  type: ConnectedDeviceActions.SYNC_CONNECTED,
  payload,
});


const syncDisconnected = (payload = "") => ({
  type: ConnectedDeviceActions.SYNC_DISCONNECTED,
  payload,
});

export const syncMqttConnected = (payload = "") => ({
  type: ConnectedDeviceActions.MQTT_SYNC_CONNECTED,
  payload,
});


export const syncMqttDisconnected = (payload = "") => ({
  type: ConnectedDeviceActions.MQTT_SYNC_DISCONNECTED,
  payload,
});

const novaConnected = (payload = "") => ({
  type: ConnectedDeviceActions.NOVA_CONNECTED,
  payload,
});

const novaDisconnected = (payload = "") => ({
  type: ConnectedDeviceActions.NOVA_DISCONNECTED,
  payload,
});

export const updateSyncConnected = (data) => (dispatch) => {

  const [deviceId, status] = data.split("-");
  if (status === "online") {
   dispatch(syncConnected(deviceId));
  } else {
  dispatch(syncDisconnected(deviceId));
  }
};

export const updateSyncMqttConnected = (data) => (dispatch) => {
  const [deviceId, status] = data.split("-");

  if (status === "online") {
    dispatch(syncMqttConnected(deviceId));
  } else {
    dispatch(syncMqttDisconnected(deviceId));
  }
};

export const updateNovaConnected = (data) => (dispatch) => {
  const [deviceId, status] = data.split("-");
  if (status === "online") {
    dispatch(novaConnected(deviceId));
  } else {
    dispatch(novaDisconnected(deviceId));
  }
};

export const updateConnectedDevices = (data) => (dispatch) => {
  let details = JSON.parse(data);
  let syncs = details.devices;
  let novas = details.novas;
  dispatch(
    updateDevices({
      syncs,
      novas,
    })
  );
};
