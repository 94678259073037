import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { fetchHouseRoom } from "../redux/actions/house";
import { useDispatch, useSelector } from "react-redux";
import RoomTab from "../components/RoomTab";
import { io } from "socket.io-client";
import { Amplify, PubSub } from "aws-amplify";
import { AWSIoTProvider } from "@aws-amplify/pubsub";
import { AmplifyConfig, PubSubConfig } from "../config/aws";
import { HouseIds, LoginTypes, wssurl } from "../config/globals";
import {
  updateConnectedDevices,
  updateNovaConnected,
  updateSyncConnected,
  updateSyncMqttConnected,
} from "../redux/actions/connectedDevices";
import { SocketEvents } from "../config/socketEvents";
import {
  novaPowerUpdate,
  syncSyncStates,
  updateSyncStates,
} from "../redux/actions/socketUpdates";
import { ThemeActions, UserActions } from "../redux/actionTypes";
import FanSpeedControler from "../components/FanSpeedControler";
import Spinners from "../config/Spinners";

function Hostler() {
  const { houseId, roomId } = useParams();

  const [loading,setLoading] = useState(false)
  const location = useLocation();
  const dispatch = useDispatch();
  const rooms = useSelector(state => state.rooms)
  const house = useSelector(state => state.house)
  const [socket, setSocket] = useState(null);
  const [iotProvider, setiotProvider] = useState(null);
  const [selectedSwitch,setSelectedSwitch]  = useState({})
  const [subscriptions, setsubscriptions] = useState([]);

  const emitSocketEvent = (name, payload) => {
    if (socket && socket.connected) {
      socket.emit(name, payload);
    }
  };
  useEffect(() => {
    dispatch({ type: UserActions.LOGOUT });
    if ([HouseIds.hostler, HouseIds.hostlers].includes(houseId)) {
      dispatch({type: UserActions.UPDATE_LOGIN_TYPE, payload: {loginType: LoginTypes.hostler}});
    }
    
    let data = queryString.parse(location.search);
    if (Object.keys(data).length === 0) {
      return;
    }
   
    if (data.primaryColor) {
      let primaryColor = `#${data.primaryColor}`;
      let gradient = data.gradient ? data.gradient.split(',').map(c => `#${c}`) : [data.primaryColor, data.primaryColor];
      dispatch({
        type: ThemeActions.LOAD,
        payload: {
          primaryColor,
          gradient,
        }
      })
    }
    if (data.checkin !== undefined && data.checkout !== undefined) {
      let start = new Date(data.checkin);
      let end = new Date(data.checkout);
      let today = new Date();
      if (
        start.getTime() < today.getTime() &&
        end.getTime() >= today.getTime()
      ) {
      } else {
        return;
      }
    } else {
      alert("Details not found");
      return;
    }
    if (houseId !== undefined) {
      setLoading(true)
      fetchHouseRoom([houseId], roomId).then(res=>setLoading(false));
      handleConnectAndAddDevice(houseId)
      if (socket == null) {
        let socket = io(wssurl, {
          reconnect: true,
          secure: true,
          query: {
            node_mcu_id: "auto1001",
            device_type: "phone",
            house_access_code: houseId,
            user: "demo",
            source: `webapp_hostler`,
          },
          transports: ["websocket"],
        });
        socket.on("connect", () => {
          console.log("[websocket] Connected");
          socket.houseId = houseId;
          socket.user = "demo";
          socket.emit(SocketEvents.HOUSE_CONNECTION_UPDATES, {
            houseAccessCode: houseId,
          });
        });
        socket.on("disconnect", () => {
          console.log("[websocket] disconnect");
        });
        socket.on(SocketEvents.SYNC_CONN_UPDATE, (res)=>dispatch(updateSyncConnected(res)));
        socket.on(SocketEvents.NOVA_CONN_UPDATE, (res)=>dispatch(updateNovaConnected(res)));
        socket.on(
          SocketEvents.HOUSE_CONNECTION_UPDATES,
          (res)=>dispatch(updateConnectedDevices(res))
        );
        socket.on(SocketEvents.SYNC_MESSAGE, (res)=>dispatch(updateSyncStates(res)));
        socket.on(SocketEvents.SYNC_SYNC_STATES, (res)=>dispatch(syncSyncStates(res)));
        socket.on(SocketEvents.NOVA_POWER_UPDATE, (res)=>dispatch(novaPowerUpdate(res)));
        socket.connect();
        setSocket(socket);
      }
    }
  }, [houseId]);
  useEffect(()=>{
   handleConnectAndAddDevice(houseId)
  },[rooms,house,iotProvider])
 const mqttPublish = (topic, payload) => {
    PubSub.publish(topic, payload)
      .then(() => {})
      .catch(() => {});
  };
 const subscribeToDeviceEvents = () => {
    let topics = [];

    for (const room of rooms.list) {
      for (const device of room.devices) {
        const { deviceId } = device;
        topics.push(
          ...[
            `e/conn/${deviceId}`,
            `e/sync/${deviceId}`,
            `message/${deviceId}`,
            `$aws/events/presence/connected/${deviceId}`,
            `$aws/events/presence/disconnected/${deviceId}`,
          ]
        );
      }
      // for (const device of room.energiSync) {
      //   const { deviceId } = device;
      //   topics.push(
      //     ...[
      //       `$aws/events/presence/connected/${deviceId}`,
      //       `$aws/events/presence/disconnected/${deviceId}`,
      //     ]
      //   );
      // }
    }

    const s = PubSub.subscribe(topics).subscribe({
      
      next: (data) => {
        let topic = String(
          data.value[Object.getOwnPropertySymbols(data.value)[0]]
        );
        if (topic.startsWith("$aws")) {
          // its a connect disconnect log
          const splits = topic.split("/");
          let event = splits[splits.length - 2];
          let deviceId = splits[splits.length - 1];
          if (event === "connected") {
            // this.props.mqttConnected(deviceId);
            // console.log(deviceId, "con");
           dispatch(updateSyncMqttConnected(`${deviceId}-online`))
          } else if (event === "disconnected") {
            // this.props.mqttDisconnected(deviceId);
            dispatch(updateSyncMqttConnected(`${deviceId}-offline`))
            // console.log(deviceId, "dis");
          }
        }

      },
      error: (error) => {},
    });
    setsubscriptions(
      preState => {
        return [ ...preState, s ];
      }
    );
  };
 const resetMQTTConnection = () => {
    PubSub.removePluggable("AWSIotProvider");
   setiotProvider(null);
  };
 const publishGetHouseDevices = (houseid) => {
    mqttPublish(`app/gethousedevices/${houseid}`, {});
  };

 const handleConnectAndAddDevice = (houseid) => {
 
    if (iotProvider!==null) {
      publishGetHouseDevices(houseid);
       return;
    }
 
    Amplify.configure(AmplifyConfig);
    Amplify.register(PubSub);
    resetMQTTConnection();
   let ioProvider = new AWSIoTProvider(PubSubConfig);
   setiotProvider(ioProvider)
    PubSub.addPluggable(ioProvider);
    const s = PubSub.subscribe(
      `housedevices/${houseid}`,
    ).subscribe(
      {
        next: data => {
        // console.log("currently vconnected devices", data.value);
     
         // this.syncStatesOfRoom(true);
    
           for(let id of data.value){
            dispatch(updateSyncMqttConnected(`${id.deviceId}-online`))
           }
        },
        error: error => { },
        complete: a => {
        },
      },
      error => { },
      () => {
      },
    );
    // console.log(s,"ddddd");
  
    setsubscriptions(
      preState => {
        return [ ...preState, s ];
      }
    );
    subscribeToDeviceEvents();
    publishGetHouseDevices(houseid);
  
  };
  const disconnectMQTT = () => {
    if (iotProvider) {
      for (const sub of subscriptions) {
        sub.unsubscribe();
      }
      setsubscriptions([]);
      PubSub.removePluggable('AWSIoTProvider');
     setiotProvider(null);
    }
  };
  const mqttControl = (
    deviceId,
    switchId,
    command,
    controllerType,
    controllerId,
    controllerDetails = '{}',
  ) => {
  
      let id = new Date().getTime().toString().slice(5, 13);
      const payload = {
        deviceId,
        switchId,
        command,
        id,
        controllerType,
        controllerId,
        controllerDetails,
      };
      
      mqttPublish(`control/${deviceId}`, `${switchId},${command},${id}`);
      mqttPublish(`command/${deviceId}`, payload);
      // this.props.localStateUpdate(deviceId, switchId, command);
  };
 const handleSelectedSwitch=(data)=>{
     setSelectedSwitch(data)
  }
  return (
    <div>
             { Object.keys(selectedSwitch).length!==0 && <FanSpeedControler selectedSwitch={selectedSwitch} control={mqttControl} handleSelectedSwitch={handleSelectedSwitch}/>}
            {loading && <Spinners/>}
      <RoomTab
        selectedRoom={roomId}
        emitSocketEvent={emitSocketEvent}
        emitMqtEvent={mqttControl}
        handleSelectedSwitch={handleSelectedSwitch}
        label={"102"}
      />
    </div>
  );
}

export default Hostler;
