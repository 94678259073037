import store from "../redux/source";

export const AmplifyConfig = {
	aws_project_region: "ap-south-1",
  aws_cognito_region: "ap-south-1", // (required) - Region where Amazon Cognito project was created
  aws_user_pools_id: "ap-south-1_qZtowoQ5j", // (optional) -  Amazon Cognito User Pool ID
  aws_user_pools_web_client_id: "6bfn7oio6s1vvuhkg2hem7pb19", // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
  aws_cognito_identity_pool_id:
    "ap-south-1:147ce4c2-8c6c-4431-ae09-2423cef38fed", // (optional) - Amazon Cognito Identity Pool ID
  aws_mandatory_sign_in: "disable", //
};
let user= store.getState().user;
export const PubSubConfig = {
	aws_pubsub_region: "ap-south-1",
  aws_pubsub_endpoint: "wss://a1pv71f2h6guqz-ats.iot.ap-south-1.amazonaws.com/mqtt",
  clientId: `U.${user.profile._id}_${new Date().getTime()}`,
}