import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./Analystic.css";

function MostActiveUser({ type, switchId, deviceId }) {
  let analyticData = useSelector((state) => state.barData);
  let [mostActiveUser, setMostActiveUser] = useState({
    user: "-------",
    command: 0,
  });

  useEffect(() => {
    if (analyticData.toggle.length === 0) return;
    let totalUserToggle = {};
    let res = [];
    if (type === "House") {
      res = analyticData.toggle;
    } else if (type === "appliance") {
      res = analyticData.toggle.filter(
        (c) => c.deviceId === deviceId && c.switchId === switchId
      );
    } else {
      res = analyticData.toggle.filter((e) => e.roomName === type);
    }
    if (res.length === 0) return;
    for (let t of res) {
      let ke = Object.keys(t);

      if (totalUserToggle[ke[0]] === undefined) {
        totalUserToggle[ke[0]] = t[ke[0]];
      }
      totalUserToggle[ke[0]] += t[ke[0]];
    }
    let max = 0;
    let user = "-------";

    for (let t of Object.keys(totalUserToggle)) {
      if (totalUserToggle[t] > max) {
        max = totalUserToggle[t];
        user = t;
      }
    }

    let name = user.split("(")[0].split(" ");

    setMostActiveUser({ user: name[1], command: max });
  }, [analyticData, deviceId, switchId, type]);
  return (
    <div className="boxContainer activeTab boxStyleAndroid">
      <div className="boxContainerTitle">Most active user</div>
      <div className="boxContainerSubTitle">{mostActiveUser.user}</div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: 20,
          marginTop: 3,
        }}
      >
        <div className="upIcon">{mostActiveUser.command} Commands sent</div>
      </div>
    </div>
  );
}

export default MostActiveUser;
