import React, { useEffect, useState } from "react";
import "./Analystic.css";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RemoveIcon from "@mui/icons-material/Remove";
let totalDayTime = 86400000;
function HouseHealth({ type, data, name }) {
  let [average, setAverage] = useState(0);
  let [diff, setDiff] = useState(0);
  useEffect(() => {
    if (Object.keys(data).length === 0) return;
    var currentTime = new Date();
    // Set the start time to 12:00 AM of the same day
    var startTime = new Date(currentTime);
    startTime.setHours(0, 0, 0, 0);
    var elapsedTime = currentTime - startTime;

    if (
      data.wifiOnTime === undefined ||
      data.WifiTimeDifferenceYesterday === undefined
    )
      return;
    let val =
      (data.wifiOnTime[name] /
        (data.totalDevices[`TotalDevices${name}`] * elapsedTime)) *
      100;
    setAverage(isNaN(val) ? 0 : val);
    let totalWifiOntimeYestrday =
      data.WifiTimeDifferenceYesterday[name] + data.wifiOnTime[name];
    let valYesterday =
      (totalWifiOntimeYestrday /
        (data.totalDevices[`TotalDevices${name}`] * totalDayTime)) *
      100;

    setDiff(valYesterday - val);
  }, [data, name]);

  return (
    <div
      className="boxContainer activeTab boxStyleAndroid smallBox"
      style={{ height: "170px" }}
    >
      <div className="boxContainerTitle">
        Smart {type === "house" ? "home" : type} health
      </div>
      <div className="boxContainerSubTitle">{average.toFixed(0)}%</div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: "20px",
          marginTop: "3px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "20px",
            marginTop: "3px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "20px",
              marginTop: "3px",
            }}
          >
            {Math.abs(diff) !== average ? (
              <>
                {data?.WifiTimeDifferenceYesterday !== undefined &&
                data?.WifiTimeDifferenceYesterday[name] > 0 ? (
                  <ArrowDropDownIcon className="upDownIcon upDownIconMargin" />
                ) : (
                  <ArrowDropUpIcon className="upIcon upDownIconMargin" />
                )}
              </>
            ) : (
              <RemoveIcon
                className="upDownIconMargin"
                style={{
                  color: "#113CD3",
                  fontSize: "19px",
                  width: "9px",
                  marginTop: "1px",
                  marginRight: "2px",
                }}
              />
            )}

            <div
              className={
                data?.WifiTimeDifferenceYesterday !== undefined &&
                data?.WifiTimeDifferenceYesterday[name] < 0
                  ? "upIcon"
                  : "upDownIcon"
              }
              style={Math.abs(diff) === average ? { color: "#113CD3" } : {}}
            >
              {Math.abs(diff) !== average ? Math.abs(diff).toFixed(0) : 0}%
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "10px",
          borderRadius: "3px",
          backgroundColor: "#F4F5F6",
          overflow: "hidden",
          marginTop: "70px",
        }}
      >
        <div
          style={{
            width: `${average}%`,
            height: "10px",
            borderRadius: "3px",
            backgroundColor: "#357CEB",
          }}
        ></div>
      </div>
    </div>
  );
}

export default HouseHealth;
