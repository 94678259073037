import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchHouse } from "../redux/actions/house";
import Box from "@mui/material/Box";
import StyledTab from "../components/StyledTab";
import RoomTab from "../components/RoomTab";
import HomeHeader from "../components/HomeHeader";
import StyledTabs from "../components/StyledTabs";
import { SocketEvents } from "../config/socketEvents";
import {
  updateConnectedDevices,
  updateNovaConnected,
  updateSyncConnected,
  updateSyncMqttConnected
} from "../redux/actions/connectedDevices";
import { io } from "socket.io-client";

import { Amplify, PubSub } from "aws-amplify";
import { AWSIoTProvider } from "@aws-amplify/pubsub";
import { LoginTypes, wssurl } from "../config/globals";
import {
  novaPowerUpdate,
  syncSyncStates,
  updateSyncStates,
} from "../redux/actions/socketUpdates";
import SwipeableViews from "react-swipeable-views";
import PayModel from "../components/PayModel";
import { canAccessPage } from "../util/outlet";
import "./style.css";
import { AmplifyConfig, PubSubConfig } from "../config/aws";
import FanSpeedControler from "../components/FanSpeedControler";
import Spinners from "../config/Spinners";

// Amplify.Logger.LOG_LEVEL = 'VERBOSE'
class Home extends Component {
  state = {
    selectedRoom: 0,
    selectedSwitch: {},
    loadding: false
  };
  subscriptions = [];
  socket = null;
  iotProvider;
  componentDidMount() {
    this.refresh();

    // this.handleConnectAndAddDevice();
  }
  mqttPublish = (topic, payload) => {
    PubSub.publish(topic, payload)
      .then(() => { })
      .catch(() => { });
  };
  refresh = () => {
    const { house, user } = this.props;

    if (!user || !user.profile) return;
    if (
      user.loggedIn &&
      house._id !== user.profile.selectedHouse &&
      user.loginType !== LoginTypes.sdk
    ) {
      this.setState({ loadding: true })
      this.props.fetchHouse(user.profile.selectedHouse, user.email)
      this.setState({ loadding: false });

    }
    if (house._id) {
      this.handleConnectAndAddDevice(house._id);
    }
    if (house._id && house._id === user.profile.selectedHouse && !this.socket) {
      let socket = io(wssurl, {
        reconnect: true,
        secure: true,
        query: {
          node_mcu_id: "auto1001",
          device_type: "phone",
          house_access_code: house._id,
          user: user.email,
          source: `webapp_${user.loginType}`,
        },
        transports: ["websocket"],
      });
      socket.on("connect", () => {
        // console.log("[websocket] Connected");
        socket.houseId = this.props.house._id;
        socket.user = this.props.user.email;
        socket.emit(SocketEvents.HOUSE_CONNECTION_UPDATES, {
          houseAccessCode: this.props.house._id,
        });
      });
      socket.on("disconnect", () => {
        console.log("[websocket] disconnect");
      });
      socket.on(SocketEvents.SYNC_CONN_UPDATE, this.props.updateSyncConnected);
      socket.on(SocketEvents.NOVA_CONN_UPDATE, this.props.updateNovaConnected);
      socket.on(
        SocketEvents.HOUSE_CONNECTION_UPDATES,
        this.props.updateConnectedDevices
      );
      socket.on(SocketEvents.SYNC_MESSAGE, this.props.updateSyncStates);
      socket.on(SocketEvents.SYNC_SYNC_STATES, this.props.syncSyncStates);
      socket.on(SocketEvents.NOVA_POWER_UPDATE, this.props.novaPowerUpdate);
      socket.connect();
      this.socket = socket;
    }
  };
  componentDidUpdate() {
    this.refresh();

  }
  shouldComponentUpdate = (nextProps, nextState) => {

    if (nextProps.house._id !== this.props.house._id) {

      this.handleConnectAndAddDevice(nextProps.house._id)
      return true
    }
    if (nextState.selectedRoom !== this.state.selectedRoom ||
      nextState.selectedSwitch !== this.state.selectedSwitch ||
      nextState.loadding !== this.state.loadding
    ) {
      return true
    }

  }
  componentWillUnmount() {
    if (this.socket && this.socket.connected) {
      this.socket.disconnect();
    }
    this.disconnectMQTT()
    document.body.style.overflow = "hidden";
  }
  subscribeToDeviceEvents = () => {
    let topics = [];

    for (const room of this.props.rooms.list) {
      for (const device of room.devices) {
        const { deviceId } = device;
        topics.push(
          ...[
            `e/conn/${deviceId}`,
            `e/sync/${deviceId}`,
            `message/${deviceId}`,
            `$aws/events/presence/connected/${deviceId}`,
            `$aws/events/presence/disconnected/${deviceId}`,
          ]
        );
      }
      for (const device of room.energiSync) {
        const { deviceId } = device;
        topics.push(
          ...[
            `$aws/events/presence/connected/${deviceId}`,
            `$aws/events/presence/disconnected/${deviceId}`,
          ]
        );
      }
    }
    const s = PubSub.subscribe(topics).subscribe({

      next: (data) => {
        let topic = String(
          data.value[Object.getOwnPropertySymbols(data.value)[0]]
        );
        if (topic.startsWith("$aws")) {
          // its a connect disconnect log
          const splits = topic.split("/");
          let event = splits[splits.length - 2];
          let deviceId = splits[splits.length - 1];
          if (event === "connected") {
            // this.props.mqttConnected(deviceId);
            // console.log(deviceId, "con");
            this.props.updateSyncMqttConnected(`${deviceId}-online`)
          } else if (event === "disconnected") {
            // this.props.mqttDisconnected(deviceId);
            this.props.updateSyncMqttConnected(`${deviceId}-offline`)
            // console.log(deviceId, "dis");
          }
        }

      },
      error: (error) => { },
    });
    this.subscriptions.push(s);
  };
  resetMQTTConnection = () => {
    PubSub.removePluggable("AWSIotProvider");
    this.iotProvider = null;
  };
  publishGetHouseDevices = (houseid) => {
    this.mqttPublish(`app/gethousedevices/${houseid}`, {});
  };

  handleConnectAndAddDevice = (houseid) => {

    if (this.iotProvider) {
      this.publishGetHouseDevices(houseid);
      return;
    }
    Amplify.configure(AmplifyConfig);
    Amplify.register(PubSub);
    this.resetMQTTConnection();
    // console.log(PubSubConfig,'PubSubConfig');
    this.iotProvider = new AWSIoTProvider(PubSubConfig);
    PubSub.addPluggable(this.iotProvider);
    const s = PubSub.subscribe(
      `housedevices/${houseid}`,
    ).subscribe(
      {
        next: data => {
          // console.log("currently vconnected devices", data.value);
          //  this.props.loadMQTTConnected(data.value.map(d => d.deviceId));
          // this.syncStatesOfRoom(true);
          for (let id of data.value) {
            this.props.updateSyncMqttConnected(`${id.deviceId}-online`)
          }
        },
        error: error => { },
        complete: a => {
        },
      },
      error => { },
      () => {
      },
    );
    // console.log(s,"ddddd");
    this.subscriptions.push(s)
    this.subscribeToDeviceEvents();
    this.publishGetHouseDevices();

  };
  disconnectMQTT = () => {
    if (this.iotProvider) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
      this.subscriptions = [];
      PubSub.removePluggable('AWSIoTProvider');
      this.iotProvider = null;
    }
  };

  render() {
    const { roomNames } = this.props;
    const { selectedRoom, selectedSwitch } = this.state;
    const { loginEntry, user } = this.props;
    let buttonsBlocked = false;
    if (
      user.loginType === LoginTypes.Outlet &&
      !canAccessPage(loginEntry?.date, 5 * 60 * 1000)
    ) {
      buttonsBlocked = true;
    }
    if (
      user.loginType === LoginTypes.pepper &&
      !canAccessPage(loginEntry?.date, 15 * 60 * 1000)
    ) {
      buttonsBlocked = true;
    }

    return (
      <div>
        {Object.keys(selectedSwitch).length !== 0 && <FanSpeedControler selectedSwitch={selectedSwitch} control={this.mqttControl} handleSelectedSwitch={this.handleSelectedSwitch} />}
        <HomeHeader refresh={this.refresh} />
        {this.state.loadding && <Spinners />}
        <Box
          sx={[
            {
              width: "100%",
            },
            buttonsBlocked
              ? {
                maxHeight: "100vh",
                overflow: "hidden",
                pointerEvents: "none",
              }
              : {},
          ]}
        >
          <Box>
            <StyledTabs
              value={selectedRoom}
              onChange={this.handleChangeRoom}
              aria-label="styled tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              {Object.keys(roomNames).map((roomId, index) => {
                return <StyledTab value={index} index={index} label={roomNames[roomId]} />;
              })}
            </StyledTabs>
          </Box>
          <Box>
            <SwipeableViews
              index={selectedRoom}
              // value={selectedRoom}
              enableMouseEvents
              onChangeIndex={(index) => {

                this.setState({ selectedRoom: index });
              }}
            >
              {Object.keys(roomNames).map((roomId) => {
                return (
                  <RoomTab
                    selectedRoom={roomId}
                    emitSocketEvent={this.emitSocketEvent}
                    emitMqtEvent={this.mqttControl}
                    label={roomNames[roomId]}
                    handleSelectedSwitch={this.handleSelectedSwitch}

                  />
                );
              })}
            </SwipeableViews>
          </Box>
        </Box>
        <PayModel />
      </div>
    );
  }

  emitSocketEvent = (name, payload) => {
    if (this.socket && this.socket.connected) {
      this.socket.emit(name, payload);
    }
  };

  mqttControl = (
    deviceId,
    switchId,
    command,
    controllerType,
    controllerId,
    controllerDetails = '{}',
  ) => {

    let id = new Date().getTime().toString().slice(5, 13);
    const payload = {
      deviceId,
      switchId,
      command,
      id,
      controllerType,
      controllerId,
      controllerDetails,
    };

    this.mqttPublish(`control/${deviceId}`, `${switchId},${command},${id}`);
    this.mqttPublish(`command/${deviceId}`, payload);
    // this.props.localStateUpdate(deviceId, switchId, command);
  };

  handleSelectedSwitch = (data) => {
    this.setState({ selectedSwitch: data })
  }

  handleChangeRoom = (e, newRoom) => {

    this.setState({ selectedRoom: newRoom });
  };
}

const mapStateToProps = (state) => ({
  user: state.user,
  house: state.house,
  rooms: state.rooms,
  roomNames: state.rooms.names,
  loginEntry: state.outlet.loginEntry,
});

export default connect(mapStateToProps, {
  fetchHouse,
  updateConnectedDevices,
  updateNovaConnected,
  updateSyncConnected,
  updateSyncMqttConnected,
  updateSyncStates,
  syncSyncStates,
  novaPowerUpdate,
})(Home);
