import React from "react";
import { styled } from '@mui/material/styles';
import { Tab } from "@mui/material";


const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
	({ theme }) => ({
		textTransform: 'none',
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: theme.typography.pxToRem(15),
		maxHeight:"50vh",
		color: 'grey',
		'&.Mui-selected': {
			color: 'black',
			fontWeight: theme.typography.fontWeightBold,
			fontSize: theme.typography.pxToRem(16),
		},
		'&.Mui-focusVisible': {
			backgroundColor: 'black',
		},
	}),
);

export default StyledTab;