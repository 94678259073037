import { channels } from "../config/channels";
import { RemoteButtons, RemoteTypeImageMap } from "../config/nova";

export const getImageOfCustomRemoteButton = (type = 0, code = {}) => {
  if (type === RemoteButtons.channel.value) {
    for (const channel of channels) {
      if (channel.id === code.channelType) {
        return {
          image: channel.image,
          tintColor: undefined,
        };
      }
    }
    return {
      image: channels[0].image,
      tintColor: undefined,
    };
  } else {
    for (let button of Object.values(RemoteButtons)) {
      if (button.value === type) {
        return {image: button.image, tintColor: button.tintColor};
      }
    }
    return {
      image: RemoteButtons.OTHER.image,
      tintColor: RemoteButtons.OTHER.tintColor,
    };
  }
};

export const getButtonsFromRemote = (remote) => {
	const RemoteTypeButtons = RemoteTypeImageMap[remote.remoteType].buttons || [];
	let RemoteTypeIds = Object.values(RemoteTypeButtons).map(b => b.value);
	let buttons = {};
	for (let buttonType of Object.values(RemoteTypeButtons)) {
		let buttonIndex = remote.buttons.findIndex(
			b => b.type === buttonType.value,
		);
		if (buttonIndex !== -1) {
			let button = remote.buttons[buttonIndex];
			buttons[buttonType.value] = {
				id: button.id,
				name: button.name,
				type: button.type,
				image: buttonType.image,
				code: button.code,
				learned: true,
			};
		} else {
			buttons[buttonType.value] = {
				id: 0,
				name: buttonType.name,
				type: buttonType.value,
				image: buttonType.image,
				code: null,
				learned: false,
			};
		}
	}
	let custom_button_groups = [];
	let current_group = [];
	let custombuttons = remote.buttons.filter(
		b => !RemoteTypeIds.includes(b.type),
	);
	for (let button of custombuttons) {
		if (current_group.length < 3) {
			current_group.push({
				type: button.type,
				name: button.name,
				code: button.code,
				_id: button._id,
				id: button.id,
				learned: true,
				...getImageOfCustomRemoteButton(button.type, button.code),
			});
		}
		if (current_group.length === 3) {
			custom_button_groups.push(current_group);
			current_group = [];
		}
	}
	if (current_group.length > 0) {
		custom_button_groups.push(current_group);
	}
	return {custom_button_groups, buttons};
};