import { SocketUpdateActions } from "../actionTypes"

export const syncSyncStates = (data) => dispatch => {
	dispatch({
		type: SocketUpdateActions.SYNC_STATES_SYNC,
		payload: data,
	})
}

export const updateSyncStates = (data) => dispatch => {
	dispatch({
		type: SocketUpdateActions.SYNC_STATE_UPDATE,
		payload: data,
	})
}

export const novaPowerUpdate = (data) => dispatch => {
	dispatch({
		type: SocketUpdateActions.NOVA_POWER_UPDATE,
		payload: data,
	})
}