import { Component } from "react";
import { connect } from "react-redux";
import { SocketEvents } from "../config/socketEvents";
import { DeviceTypeImageMap } from "../config/typeMaps";
import { updateSyncStates } from "../redux/actions/socketUpdates";
import AnimatedWrapper from "./AnimatedWrapper";
import axios from "axios";
import FanSpeedControler from "./FanSpeedControler";

class SyncButton extends Component {

  render() {
    const { appliance, connectedSyncs ,connectedSyncMqtts,connectedDevices} = this.props;
    

    const on = Number(appliance.state) !== 0;
    // const online = connectedSyncs.includes(appliance.deviceId) || user.loginType === LoginTypes.Tim || user.loginType === LoginTypes.sdk || user.loginType === LoginTypes.hostler || user.loginType === LoginTypes.pepper || user.loginType === LoginTypes.vistara;
    const online = connectedSyncs?.includes(appliance.deviceId) || connectedSyncMqtts?.includes(appliance.deviceId)
    return (
      <>
      
        <AnimatedWrapper
          on={on}
          name={appliance.switchName}
          image={
            on
              ? DeviceTypeImageMap[appliance.deviceType]?.on
              : DeviceTypeImageMap[appliance.deviceType]?.off
          }
          online={online}
          onPress={this.handleButtonClick}
          onLongPress={this.handleLongPress}
          state={appliance.state}
          barShown={[0,20].includes(appliance.deviceType) && appliance.dimmable}
        />
      
      </>
    );
  }

  handleLongPress = () => {
    const { connectedSyncs, appliance ,connectedSyncMqtts} = this.props;
    const online = connectedSyncs?.includes(appliance.deviceId) || connectedSyncMqtts?.includes(appliance.deviceId)
    const on = Number(this.props.appliance.state) !== 0;
    if([0,20].includes(this.props.appliance.deviceType) && this.props.appliance.dimmable && on && online )
    this.props.handleSelectedSwitch(this.props.appliance)
  }
  callUpdate = (deviceId, switchId, command, email) => {
    var c = 100
    if (String(command) === "true") {
      c = 0
    }
    axios
      .post(
        `https://a3.alistetechnologies.com/v3/device/control`,
        {
          deviceId,
          switchId,
          command: c,
          controllerType: "Taj Portal",
          controllerId: email,
          controllerDetails: {}
        },
      )
      .then(res => {
        return
      })
      .catch(error => {
        return
      });

  };
  handleButtonClick = () => {
    const { connectedSyncs, appliance ,connectedSyncMqtts} = this.props;
    const online = connectedSyncs?.includes(appliance.deviceId) || connectedSyncMqtts?.includes(appliance.deviceId)

    if (!online ) {
      return
    }
    let command = Number(appliance.state) === 0 ? 100 : 0;
    let { email, profile } = this.props.user;
    let username = profile.name
      ? profile.name
      : `${profile.first_name} ${profile.last_name}`;
    let message = {
      deviceId: appliance.deviceId,
      switchId: appliance.switchId,
      command,
      device_type: "phone",
      user: email,
      username,
      id: String(new Date().getTime()).slice(5, 13),
    };
    let newMessage = {
      deviceId: appliance.deviceId,
      switchId: appliance.switchId,
      command,
      id:String(new Date().getTime()).slice(5, 13),
      controllerType:'web-app',
      controllerId:`${username}(${email})`,
    }
    
    this.props.updateSyncStates({
      deviceId: appliance.deviceId,
      switchId: appliance.switchId,
      state: command,
    });
    // this.callUpdate(appliance.deviceId, appliance.switchId, command === 0 ? "true" : "false", email)
    // this.props.emitSocketEvent(SocketEvents.SYNC_MESSAGE, message);
    this.props.emitMqtEvent(newMessage.deviceId,newMessage.switchId,newMessage.command,newMessage.controllerType,newMessage.controllerId)
  };
}

const mapStateToProps = (state) => ({
  connectedSyncs: state.connectedDevices.syncs,
  connectedSyncMqtts: state.connectedDevices.mqtt,
  connectedDevices:state.connectedDevices,
  user: state.user,

});
export default connect(mapStateToProps, { updateSyncStates })(SyncButton);
