import { analytic } from "../actionTypes"


export const saveTodaySessions = (data)=>{
    return {
        type:analytic.SAVE_TODAY_SESSIONS,
        payload:data
    }
}

export const saveTodayBarDataHouseAndRoom = (data)=>{
 
    return {
        type: analytic.SAVE_TODAY_BAR_DATA_HOUSE_ROOM,
        payload:data
    }
}