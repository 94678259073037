import React from "react";

function DemoGraph({ barData, height = false }) {
  const styles = {
    mainContainer: {
      display: "flex",
      flexDirection: "row",
      marginTop: height ? "0px" : "15px",
      justifyContent: "space-between",
      width: "100%",
    },
    singleBackgroundContainer: {
      display: "flex",
      height: "60px",
      width: "10px",
      flexDirection: "column-reverse",
      backgroundColor: "#F4F5F6",
      borderRadius: "3px",
    },
    singleInnerContainer: {
      height: "30px",
      backgroundColor: "#357CEB",
      borderRadius: "3px",
    },
  };

  return (
    <div style={{ ...styles.mainContainer }}>
      <div style={{ ...styles.singleBackgroundContainer }}>
        <div
          style={{
            ...styles.singleInnerContainer,
            height:
              barData !== undefined && barData.length > 0
                ? `${17 * barData[1].value}px`
                : "0px",
          }}
        ></div>
      </div>
      <div style={styles.singleBackgroundContainer}>
        <div
          style={{
            ...styles.singleInnerContainer,
            height:
              barData !== undefined && barData.length > 0
                ? 17 * barData[2].value
                : "0px",
          }}
        ></div>
      </div>
      <div style={styles.singleBackgroundContainer}>
        <div
          style={{
            ...styles.singleInnerContainer,
            height:
              barData !== undefined && barData.length > 0
                ? 17 * barData[3].value
                : "0px",
          }}
        ></div>
      </div>
      <div style={styles.singleBackgroundContainer}>
        <div
          style={{
            ...styles.singleInnerContainer,
            height:
              barData !== undefined && barData.length > 0
                ? 17 * barData[4].value
                : "0px",
          }}
        ></div>
      </div>
      <div style={styles.singleBackgroundContainer}>
        <div
          style={{
            ...styles.singleInnerContainer,
            height:
              barData !== undefined && barData.length > 0
                ? 17 * barData[5].value
                : "0px",
          }}
        ></div>
      </div>
      <div style={styles.singleBackgroundContainer}>
        <div
          style={{
            ...styles.singleInnerContainer,
            height:
              barData !== undefined && barData.length > 0
                ? 17 * barData[6].value
                : "0px",
          }}
        ></div>
      </div>
    </div>
  );
}

export default DemoGraph;
