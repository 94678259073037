import React from 'react'
import '../Analystic.css'
import CustomDonut from '../CustomDonut';
import MostActiveUser from '../MostActiveUser';
import { useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// import SetUpBillPreduction from '../SetUpBillPreduction';

import { useLocation, useNavigate } from 'react-router-dom';
import BarChart from '../BarChart';
import Bill from '../Bill';
import HouseHealth from '../HouseHealth';
import CommandList from './CommandList';
import { Grid } from '@mui/material';

export default function ApplianceAnalysticV2(props) {
  const history = useNavigate()
  let analyticData = useSelector(state => state.barData)
  let location = useLocation()
  const { barData, applinceOnTime, switchId, deviceId, applinaceName } = location?.state
  return (
    <div style={{ backgroundColor: "#f3f3f3"}}>
    <div style={{  paddingTop: '30px', minHeight: '100vh', paddingBottom: '30px' }} className='desktop'>
      <div >
        <div className='headerBack'>
          <div s onClick={() => history(-1)}><ArrowBackIcon style={{ fontSize: '32px' }} /></div>
          <div className='topHeader' style={{ marginTop: 0 }}>{applinaceName}</div>
        </div>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <div className='boxContainer  activeTab boxStyleAndroid'
            >
              <BarChart barData={barData} HouseOnTime={applinceOnTime.toFixed(2)} TimeDifferenceYesterday={analyticData.TimeDifferenceYesterday[`${deviceId}$${switchId}`] !== undefined ? analyticData.TimeDifferenceYesterday[`${deviceId}$${switchId}`] : 0} />
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12} >
                <Bill
                  barData={barData}
                  bill={analyticData.bill[`${deviceId}$${switchId}`] !== undefined ? analyticData.bill[`${deviceId}$${switchId}`] : 0}
                  yesterdayBillDifference={analyticData.BillDifferenceYesterday[`${deviceId}$${switchId}`] !== undefined ? analyticData.BillDifferenceYesterday[`${deviceId}$${switchId}`] : 0}
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <HouseHealth type={"switch"} name={`${deviceId}$${switchId}`} data={analyticData} />
              </Grid>

            </Grid>

          </Grid>
        </Grid>

        <Grid container spacing={2} mt={0}>
          <Grid item md={6} >
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <MostActiveUser type={"appliance"} switchId={switchId} deviceId={deviceId} />

              </Grid>
              <Grid item md={12} xs={12}>
                <CustomDonut name={"appliance"} switchId={switchId} deviceId={deviceId} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <CommandList switchId={switchId} deviceId={deviceId} />
          </Grid>
        </Grid>



      </div>
    </div>
    </div>
  )
}
