import React, { useState } from 'react'
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material";
import './FanSpeed.css'
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { updateSyncStates } from '../redux/actions/socketUpdates';

const marks = [
 
    {
      value: 25,
    },
    {
      value: 50,
    },
    {
      value: 75,
    },
  
  ];

  const Separator = styled("div")(
    ({ theme }) => `
    height: ${theme.spacing(6)};
    width:100;
    backgroub:#000000 !important
  `
  );
function FanSpeedControler({selectedSwitch,control,handleSelectedSwitch}) {
  const {email, profile} = useSelector(state=>state.user)
  const dispatch = useDispatch()
  const [value,setValue] = useState(Number(selectedSwitch.state))
  const handleFanSpeed = () =>{
    let username = profile.name
    ? profile.name
    : `${profile.first_name} ${profile.last_name}`;
    let newMessage = {
      deviceId: selectedSwitch.deviceId,
      switchId: selectedSwitch.switchId,
      command:value,
      id:String(new Date().getTime()).slice(5, 13),
      controllerType:'web-app',
      controllerId:`${username}(${email})`,
    }
    dispatch(updateSyncStates({
      deviceId: selectedSwitch.deviceId,
      switchId: selectedSwitch.switchId,
      state: value,
    }
    ))
    control(newMessage.deviceId,newMessage.switchId,newMessage.command,newMessage.controllerType,newMessage.controllerId)
    handleSelectedSwitch({})
   
  }
  return (
    <div style={{
         background:'#000000',
         position:'fixed',
         height:'100%',
         top:0,
         left:0,
         width:'100%',
         zIndex:9999999,
         display:'flex',
         justifyContent:"center",
         alignItems:'center',
        
      }}>
        <div style={{
            height:'400px',
            display: "flex",
            flexDirection: "column",
        }}>
          <div style={{
            textAlign: "center",
            fontSize: "larger",
            fontWeight: 500,
            marginBottom: "10px",
            color:'white'
          }}>{value}%</div>
   <Slider
        aria-label="Temperature"
        orientation="vertical"
        track="inverted"
        aria-labelledby="track-inverted-slider"
        // getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        onChange={(e)=>setValue(e.target.value)}
        defaultValue={Number(selectedSwitch.state)}
        marks={marks}
        step={25}
        min={0}
        max={100}
        sx={{ width: 100 }}
      />
      <Separator />
      <Button 
        variant="secondary"
        style={{borderRadius:'12px'}} 
        onClick={()=>handleFanSpeed()}
      >Done</Button>
      </div>
    </div>
  )
}

export default FanSpeedControler