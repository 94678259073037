import React from "react";
import "../Analystic.css";

function LogRow(props) {
  const { medium, state, time, header } = props;
  return (
    <div className="logRowContainer">
      <div className={`logRowMedium ${header === true && "bold"}`}>
        {medium}
      </div>
      <div className={`logRowState ${header === true && "bold"}`}>{state}</div>
      <div className={`logRowTime ${header === true && "bold"}`}>{time}</div>
    </div>
  );
}

export default LogRow;
