import React from "react";
import { useSelector } from "react-redux";
import BarChart from "./BarChart";
import Bill from "./Bill";
import MostUsedRoom from "./MostUsedRoom";
import MostUsedAppliance from "./MostUsedAppliance";
import MostActiveUser from "./MostActiveUser";
import CustomDonut from "./CustomDonut";
import RoomBreakDown from "./RoomBreakDown";
import HouseHealth from "./HouseHealth";
import { Grid } from "@mui/material";

function HouseAnalystic() {
  let analyticData = useSelector((state) => state.barData);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <div className="boxContainer  activeTab boxStyleAndroid">
            <BarChart
              barData={analyticData?.barData?.House}
              HouseOnTime={analyticData?.HouseOnTime}
              TimeDifferenceYesterday={
                analyticData.TimeDifferenceYesterday.House !== undefined
                  ? analyticData.TimeDifferenceYesterday.House /
                    analyticData.TimeDifferenceYesterday.TotalDevicesHouse
                  : 0
              }
            />
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Bill
                bill={
                  analyticData.bill.House !== undefined
                    ? analyticData.bill.House
                    : 0
                }
                yesterdayBillDifference={
                  analyticData.BillDifferenceYesterday.House !== undefined
                    ? analyticData.BillDifferenceYesterday.House
                    : 0
                }
                barData={analyticData?.barData?.House}
                height={true}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <MostUsedRoom
                mostUsedRoom={analyticData?.mostUsedRoom}
                barData={analyticData?.barData}
                totalRoomHouseTime={analyticData?.totalRoomHouseTime}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <MostUsedAppliance name={"House"} />
            </Grid>
            <Grid item md={6} xs={6}>
              <HouseHealth type={"house"} name={"House"} data={analyticData} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={0}>
        <Grid item md={6}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <MostActiveUser type={"House"} />
            </Grid>
            <Grid item md={12} xs={12}>
              <CustomDonut name={"House"} pieData={analyticData?.pieDatas} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <RoomBreakDown
            totalRoomHouseTime={analyticData?.totalRoomHouseTime}
            barData={analyticData?.barData}
          />
        </Grid>
      </Grid>

      {/* <MostActiveUser type={"House"} />
      <CustomDonut name={"House"} pieData={analyticData?.pieDatas} />
      <RoomBreakDown totalRoomHouseTime={analyticData?.totalRoomHouseTime} barData={analyticData?.barData} /> */}
    </div>
  );
}

export default HouseAnalystic;
