const buttonPercent = 40;
const rowMargin = (100 - buttonPercent * 2) / 6;
const buttonMargin = (100 - buttonPercent * 2) / 6;
const imagePercent = 5;

export const offButtonStyle = {
	backgroundColor: 'white',
	boxShadow: `rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px`
};
export const ButtonRoomStyles = {
	buttonPercent,
	rowMargin,
	buttonMargin,
	imagePercent,	
}

