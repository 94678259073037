import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

export default function PieChart({ size, data }) {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (data.length === 0) return;
    let seriesData = [];
    let label = [];
    for (let i of data) {
      seriesData.push(Number(i.text));
      label.push(i.commandName);
    }

    setSeries(seriesData);
    setOptions({
      labels: label,
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontSize: "22px",
              },
              value: {
                fontSize: "16px",
                formatter: function (val) {
                  return `${val}%`; // You can customize this based on your needs
                },
              },
            },
          },
        },
      },
      legend: {
        show: false,
      },
    });
  }, [data]);
  return <ReactApexChart options={options} series={series} type="donut" />;
}
