import { analytic } from "../actionTypes";


export const saveTodaySessionsReducer = (state=[],action)=>{
    const {type, payload} = action;
    switch(type){
        case analytic.SAVE_TODAY_SESSIONS:
            return payload
        default :
            return state
    }
}

export const saveTodayBarDataHouseAndRoomReducer = (state={
    HouseOnTime:"0",
    barData: {},
    mostUsedRoom: {},
    totalRoomHouseTime: {} ,
    pieDatas:[],
    command:[],
    toggle:[],
    bill:{},
    BillDifferenceYesterday:{},
    TimeDifferenceYesterday:{},
    wifiOnTime:{},
    WifiTimeDifferenceYesterday:{},
    totalDevices:{}
},action)=>{
    const {type, payload} = action;
    switch(type){
        case analytic.SAVE_TODAY_BAR_DATA_HOUSE_ROOM:
            return payload
        default :
            return state
    }
}