const findTime = (time, id, slot) => {
    let s = new Date(time.startTime).getHours();
    let e = new Date(time.endTime).getHours();
  
    if (s === e) {
      slot[Math.floor(s / 3)].time += time.endTime - time.startTime;
  
      if (!slot[Math.floor(s / 3)].devices.includes(id)) {
        slot[Math.floor(s / 3)].devices.push(id);
      }
    } else {
      for (let i = s; i <= e; i++) {
        let slotNumber = Math.floor(i / 3);
        if (i !== e) {
          if (i === s) {
            let newStart = new Date(time.startTime);
            let endTime = new Date(time.startTime).setHours(i + 1, 0, 0, 0);
            slot[slotNumber].time += endTime - newStart;
  
            if (!slot[slotNumber].devices.includes(id)) {
              slot[slotNumber].devices.push(id);
            }
          } else {
            let newStart = new Date(time.startTime).setHours(i, 0, 0, 0);
            let endTime = new Date(time.startTime).setHours(i + 1, 0, 0, 0);
            slot[slotNumber].time += endTime - newStart;
            if (!slot[slotNumber].devices.includes(id)) {
              slot[slotNumber].devices.push(id);
            }
          }
        }
        if (i === e) {
          let newStart = new Date(time.startTime).setHours(e, 0, 0, 0);
          slot[slotNumber].time += time.endTime - newStart;
          if (!slot[slotNumber].devices.includes(id)) {
            slot[slotNumber].devices.push(id);
          }
        }
      }
    }
  };
  
  export const barDatas = (barData, slot) => {
    for (let h of Object.keys(slot)) {
      if (slot[h].time !== 0) {
        barData.push({
          value: slot[h].time / slot[h].devices.length / (1000 * 60 * 60),
          label: slot[h].label,
          frontColor: '#177AD5',
        });
      } else {
        barData.push({value: 0, label: slot[h].label});
      }
    }
  };
  
  export const appliancesTime = sessions => {
    let slot = {
      0: {
        time: 0,
        devices: [],
        label: '12 AM',
      },
      1: {
        time: 0,
        devices: [],
        label: '3 AM',
      },
      2: {
        time: 0,
        devices: [],
        label: '6 AM',
      },
      3: {
        time: 0,
        devices: [],
        label: '9 AM',
      },
      4: {
        time: 0,
        devices: [],
        label: '12 PM',
      },
      5: {
        time: 0,
        devices: [],
        label: '3 PM',
      },
      6: {
        time: 0,
        devices: [],
        label: '6 PM',
      },
      7: {
        time: 0,
        devices: [],
        label: '9 PM',
      },
    };
    let barData = [];
    for (let s of sessions) {
      findTime(s, `${s.deviceId}$${s.switchId}`, slot);
    }
    barDatas(barData, slot);
    return barData;
  };
  