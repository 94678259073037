import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { TVRemoteButtons } from "../../config/nova";
import { getButtonsFromRemote } from "../../util/nova";
import Header from "../Header";
import CircleController from "./CircleController";
import LongUpDownController from "./LongUpDownController";
import RemoteButton from "./RemoteButton";

class TVRemote extends Component {
  render() {
    const { closeModal, remote, powerClicked, sendCode } = this.props;
    const { custom_button_groups, buttons } = getButtonsFromRemote(remote);
    return (
      <div>
        <ReactModal
          isOpen={this.props.showModal}
          style={{
            overlay: {
              width: "100%",
              padding: "2vh 0px 0px 0px",
              margin: "0px",
              backgroundColor: "black",
            },
            content: {
              position: "relative",
              inset: "0px",
              margin: "0px",
              padding: "0px",
              height: "100%",
              minWidth: "100%",
              backgroundColor: "#f2f2f2",
              borderRadius: "8vw 8vw 0 0",
              overflow: "scroll",
              overflowX: 'hidden',
            },
          }}
        >
          <div
            style={{
              padding: "15px 15px 5px",
              position: "sticky",
              top: 0,
              left: 0,
              backgroundColor: "white",
            }}
          >
            <Header
              onDone={closeModal}
              onBack={closeModal}
              title={remote.name}
            />
          </div>
          <div style={{ backgroundColor: "#f2f2f2", paddingTop: "2vh" }}>
            <Row
              style={{
                justifyContent: "space-around",
                margin: "2vh 0vw",
              }}
              className={"g-0"}
            >
              <Col>
                <center>
                  <RemoteButton
                    onPress={() => {
                      powerClicked();
                    }}
                    image={TVRemoteButtons.Power.image}
                    name={TVRemoteButtons.Power.label}
                    tintColor={TVRemoteButtons.Power.tintColor}
                  />
                </center>
              </Col>
              <Col>
                <center>
                  <RemoteButton
                    onPress={() => {
                      sendCode(buttons[TVRemoteButtons.Home.value]);
                    }}
                    image={TVRemoteButtons.Home.image}
                    name={TVRemoteButtons.Home.label}
                    tintColor={TVRemoteButtons.Home.tintColor}
                  />
                </center>
              </Col>
              <Col>
                <center>
                  <RemoteButton
                    onPress={() => {
                      sendCode(buttons[TVRemoteButtons.Source.value]);
                    }}
                    image={TVRemoteButtons.Source.image}
                    name={TVRemoteButtons.Source.label}
                    tintColor={TVRemoteButtons.Source.tintColor}
                  />
                </center>
              </Col>
            </Row>
            {custom_button_groups.slice(0, 1).map((group, index) => (
              <Row
                style={{
                  justifyContent: "space-around",
                  margin: "2vh 0vw",
                }}
                className={"g-0"}
              >
                {group.map((button) => (
                  <Col>
                    <center>
                      <RemoteButton
                        image={button.image}
                        key={button._id}
                        onPress={() => {
                          sendCode(button);
                        }}
                        name={button.name}
                        tintColor={button.tintColor}
                      />
                    </center>
                  </Col>
                ))}
              </Row>
            ))}
            {custom_button_groups.slice(1, 2).map((group, index) => (
              <Row
                style={{
                  justifyContent: "space-around",
                  margin: "2vh 0vw",
                }}
                className={"g-0"}
              >
                {group.map((button) => (
                  <Col>
                    <center>
                      <RemoteButton
                        image={button.image}
                        key={button._id}
                        onPress={() => {
                          sendCode(button);
                        }}
                        name={button.name}
                        tintColor={button.tintColor}
                      />
                    </center>
                  </Col>
                ))}
              </Row>
            ))}
            <Row
              style={{
                justifyContent: "space-around",
                margin: "2vh 0vw",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              className={"g-0"}
            >
              <Col>
                <center>
                  <LongUpDownController
                    upImage={TVRemoteButtons["Channel +"].image}
                    downImage={TVRemoteButtons["Channel -"].image}
                    onUpClicked={() => {
                      sendCode(buttons[TVRemoteButtons["Channel +"].value]);
                    }}
                    onDownClicked={() => {
                      sendCode(buttons[TVRemoteButtons["Channel -"].value]);
                    }}
                    label={"Ch"}
                  />
                </center>
              </Col>
              <Col>
                <center>
                  <RemoteButton
                    image={TVRemoteButtons.Mute.image}
                    onPress={() => {
                      sendCode(buttons[TVRemoteButtons.Mute.value]);
                    }}
                    name={TVRemoteButtons.Mute.label}
                    tintColor={TVRemoteButtons.Mute.tintColor}
                  />
                </center>
              </Col>
              <Col>
                <center>
                  <LongUpDownController
                    upImage={TVRemoteButtons["Volume +"].image}
                    downImage={TVRemoteButtons["Volume -"].image}
                    onUpClicked={() => {
                      sendCode(buttons[TVRemoteButtons["Volume +"].value]);
                    }}
                    onDownClicked={() => {
                      sendCode(buttons[TVRemoteButtons["Volume -"].value]);
                    }}
                    label={"Vol"}
                  />
                </center>
              </Col>
            </Row>
            <Row>
              <center>
                <CircleController
                  upImage={TVRemoteButtons.Up.image}
                  leftImage={TVRemoteButtons.Left.image}
                  downImage={TVRemoteButtons.Down.image}
                  rightImage={TVRemoteButtons.Right.image}
                  centerImage={TVRemoteButtons.Center.image}
                  onUpClicked={() => {
                    sendCode(buttons[TVRemoteButtons.Up.value]);
                  }}
                  onDownClicked={() => {
                    sendCode(buttons[TVRemoteButtons.Down.value]);
                  }}
                  onLeftClicked={() => {
                    sendCode(buttons[TVRemoteButtons.Left.value]);
                  }}
                  onRightClicked={() => {
                    sendCode(buttons[TVRemoteButtons.Right.value]);
                  }}
                  onCenterClicked={() => {
                    sendCode(buttons[TVRemoteButtons.Center.value]);
                  }}
                />
              </center>
            </Row>
            {custom_button_groups.slice(2).map((group, index) => (
              <Row
                style={{
                  justifyContent: "space-around",
                  margin: "2vh 0vw",
                }}
                className={"g-0"}
              >
                {group.map((button) => (
                  <Col>
                    <center>
                      <RemoteButton
                        image={button.image}
                        key={button._id}
                        onPress={() => {
                          sendCode(button);
                        }}
                        name={button.name}
                        tintColor={button.tintColor}
                      />
                    </center>
                  </Col>
                ))}
              </Row>
            ))}
          </div>
        </ReactModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  primaryColor: state.theme.primaryColor,
});
export default connect(mapStateToProps)(TVRemote);
