import React from 'react'
import {HashLoader} from 'react-spinners'

function Spinners() {
  return (
    <div style={{zIndex:22}} className='w-100 d-flex h-100 position-fixed top-0 left-0 justify-content-center align-items-center'>
        <HashLoader color="#9EAAD7" />
    </div>
  )
}

export default Spinners