import axios from "axios";
import { HouseIds, LoginTypes, cloudUrl } from "../../config/globals";
import { OutletActions, UserActions } from "../actionTypes";

export const loadUser = (
  payload = {
    email: "",
    profile: {},
    loginType: LoginTypes.Taj,
  }
) => ({
  type: UserActions.LOAD,
  payload,
});
export const loadLinkUser = (payload)=>{
  return{
    type: UserActions.LOAD_LINK,
    payload
  }
}

const loadOutlet = (payload = { outlet: {}, loginEntry: {} }) => ({
  type: OutletActions.LOAD,
  payload,
});

export const logoutUser = () => (dispatch) => {
  dispatch({
    type: UserActions.LOGOUT,
  });
};

export const loginOutletUser =
  (details = {outletId: 'O001', name: 'Shreyansh', mobile: '7990847898', email: 'shreyanshmacro@gmail.com', city: 'Bilimora'}) => (dispatch) => {
    return new Promise(async (resolve) => {
      const result = await axios
        .post(`${cloudUrl}/v3/outlets/login`, {...details,source:details.outletId})
        .then((response) => {
          const { success, message, data } = response.data;
          if (success) {
            dispatch(
              loadUser({
                email: data.user.email,
                profile: data.user,
                loginType: LoginTypes.Outlet,
              })
            );
            dispatch(
              loadOutlet({
                outlet: data.outlet,
                loginEntry: data.loginEntry,
              })
            );
          }
          return {
            success,
            message,
            data,
          };
        })
        .catch((error) => {
          return {
            success: false,
            data: {
              error,
            },
            message: String(error),
          };
        });
      resolve(result);
    });
  };

export const loginTajUser = (name, mobile, checkoutDate) => (dispatch) => {
  return new Promise(async (resolve) => {
    const result = await axios
      .post(`${cloudUrl}/v2/user/registerTajUser`, {
        name,
        mobile,
        checkoutDate,
        source:HouseIds.taj
      })
      .then((response) => {
        const { success, message, data } = response.data;
        if (success) {
          dispatch(
            loadUser({
              email: data.user.email,
              profile: data.user,
              loginType: LoginTypes.Taj,
            })
          );
        }
        return {
          success,
          message,
          data,
        };
      })
      .catch((error) => {
        return {
          success: false,
          data: {
            error,
          },
          message: String(error),
        };
      });
    resolve(result);
  });
};
export const loginZiggtaUser = (name, mobile, checkoutDate) => (dispatch) => {
  return new Promise(async (resolve) => {
    const result = await axios
      .post(`${cloudUrl}/v2/user/registerZiggtaUser`, {
        name,
        mobile,
        checkoutDate,
        source:HouseIds.ziggtaHouse
      })
      .then((response) => {
        const { success, message, data } = response.data;
        if (success) {
          dispatch(
            loadUser({
              email: data.user.email,
              profile: data.user,
              loginType: LoginTypes.Ziggta,
            })
          );
        }
        return {
          success,
          message,
          data,
        };
      })
      .catch((error) => {
        return {
          success: false,
          data: {
            error,
          },
          message: String(error),
        };
      });
    resolve(result);
  });
};
export const loginWebAppUser = (name, mobile, checkoutDate,houseId,type="") => (dispatch) => {
  return new Promise(async (resolve) => {
    const result = await axios
      .post(`${cloudUrl}/v2/user/registerWebAppUsers`, {
        name,
        mobile,
        checkoutDate,
        houseId,
        source:houseId
      })
      .then((response) => {
        const { success, message, data } = response.data;
        if (success) {
          dispatch(
            loadUser({
              email: data.user.email,
              profile: data.user,
              loginType: type===""?LoginTypes.hostler:type,
            })
          );
          if (type === LoginTypes.pepper) {
            dispatch(
              loadOutlet({
                outlet: "pepperfy",
                loginEntry: {
                 date: new Date()
                },
              })
            );
          }
        }
        return {
          success,
          message,
          data,
        };
      })
      .catch((error) => {
        return {
          success: false,
          data: {
            error,
          },
          message: String(error),
        };
      });
    resolve(result);
  });
};
export const loginWebAppUserRoom = (name, mobile, checkoutDate,houseId,roomId,type="") => (dispatch) => {
  return new Promise(async (resolve) => {
    const result = await axios.post(`https://subscriptioncloud.alistetechnologies.com/v2/user/registerUserWithRoomAccess`, {
        name,
        mobile,
        checkoutDate,
        houseId,
        roomId,
        source:houseId
      })
      .then((response) => {
      
        const { success, message, data } = response.data;
        if (success) {
       
          dispatch(
            loadUser({
              email: data.user.email,
              profile: data.user,
              loginType: LoginTypes.hostler,
            })
          );
        
        }
        return {
          success,
          message,
          data,
        };
      })
      .catch((error) => {
        return {
          success: false,
          data: {
            error,
          },
          message: String(error),
        };
      });
    resolve(result);
  });
};
