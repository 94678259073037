import { ArrowBackIosNewOutlined } from "@mui/icons-material";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

const Header = ({ onBack, onDone, title, doneLabel }) => {
  const primaryColor = useSelector((state) => state.theme.primaryColor);
  return (
    <Row className={"g-0"} style={{backgroundColor: 'white'}}>
      <Col xs={2} onClick={onBack}>
        <ArrowBackIosNewOutlined htmlColor={primaryColor} />
      </Col>
      <Col xs={8}>
        <center>
          <h4> {title} </h4>
        </center>
      </Col>
      <Col xs={2} onClick={onDone}>
        <span style={{ color: primaryColor }}>
          {doneLabel !== undefined ? doneLabel : "Done"}
        </span>
      </Col>
    </Row>
  );
};

export default Header;
