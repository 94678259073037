import { ConnectedDeviceActions, UserActions } from "../actionTypes";

let initialState = {
  syncs: [],
  novas: [],
  mqtt:[],
};

const connectedDevices = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ConnectedDeviceActions.UPDATE_ALL:
      return {
        ...payload,mqtt:state.mqtt
      };
    case ConnectedDeviceActions.SYNC_CONNECTED:
      return {
        ...state,
        syncs: state.syncs.includes(payload)
          ? state.syncs
          : state.syncs.concat(payload),
      };
    case ConnectedDeviceActions.SYNC_DISCONNECTED:
      return {
        ...state,
        syncs: state.syncs.filter((d) => d !== payload),
      };
      case ConnectedDeviceActions.MQTT_SYNC_CONNECTED:
        return {
          ...state,
          mqtt: state.mqtt.includes(payload)
            ? state.mqtt
            : state.mqtt.concat(payload),
        };
      case ConnectedDeviceActions.MQTT_SYNC_DISCONNECTED:
        return {
          ...state,
          mqtt: state.mqtt.filter((d) => d !== payload),
        };
  
    case ConnectedDeviceActions.NOVA_CONNECTED:
      return {
        ...state,
        novas: state.novas.includes(payload)
          ? state.novas
          : state.novas.concat(payload),
      };
    case ConnectedDeviceActions.NOVA_DISCONNECTED:
      return {
        ...state,
        novas: state.novas.filter((d) => d !== payload),
      };

    case UserActions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default connectedDevices;
